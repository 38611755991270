import React, {useState, useEffect, useCallback} from 'react';
import { Server, ServerResponse } from '../../utils/Server';
import Icon, { IconType } from "../Icon/Icon";
import { IFormGroup } from './FormTypes';
import { v4 as uid } from "uuid";
import PacketBuilder from './PacketBuilder';
import StoreX from '../../redux/oldStore';
import { toast } from 'react-toastify';

interface IGroupManagerProps {

}

const GroupManager = (props:IGroupManagerProps) =>{
    const [packets, setPackets]  = useState<IFormGroup[]>([]);
    const [selectedPacket, setSelectedPacket] = useState<IFormGroup>();

    useEffect(()=>{
        StoreX.instance.server.postApi<ServerResponse<IFormGroup[]>>(`../GenericForms/Packets`, {})
        .then(x=>{
            if(x.Success){
                setPackets(x.Value);
            }else {
                toast.error(`Error getting your packets: ${x.Message}`);
            }
        });
    },[]);

    const mapFormPackets = ()=>{
        if(!packets || packets.length === 0){
            return <>Currently you don't have any packets defined. Click on <strong><Icon type={IconType.plus}/> New Packet</strong> to define a new packet.</>;
        }

        return <>
        <div className="packet-tile-container">
            {packets.map((x, i)=>{
                return <div key={`packet-key-${i}`} className="packet-tile">
                    <div className="flex-between">
                    <h4>{x.Name}</h4>
                    <button type="button" className="btn btn-default" onClick={()=>{setSelectedPacket(x)}}>
                        <Icon type={IconType.edit} /> Edit
                    </button>
                    </div>
                    <div><Icon type={IconType.filePdf}/> Forms: {x.Forms.length}</div>
                    <div className="packet-body">
                        {x.Description && <div dangerouslySetInnerHTML={{__html:x.Description}} />}
                        </div>

                    
                </div>
            })}
            </div>
        </>

    };

    const addNewPacket = ()=>{
        setSelectedPacket({Name:'', Description:'',PublicId:uid(),Forms:[]});
    };
    const packetUpdated = (packet:IFormGroup) =>{
        setSelectedPacket(packet);
        let ps = packets;
        let found = false;
        for(let i = 0; i < ps.length; i++){
            if(ps[i].PublicId === packet.PublicId){
                ps[i]= packet;
                found = true;
                break;
            }
        }
        if(!found) ps.push(packet);

        setPackets(ps);
    };

    return (<>
    <div className="col-sm-12">
        {!selectedPacket && <div>
            <div className="flex-between">
                <h3>Forms Manager</h3>
                <div>
                    <button type="button" className="btn btn-secondary" onClick={addNewPacket}>
                        <Icon type={IconType.plus} /> New Packet
                    </button>
                </div>                
            </div>
            Below are listed your form packets, these are packets of forms 
            you can have your participants fill out.
            <hr/>

            <div>
                {mapFormPackets()}
            </div>
        </div>}
        {selectedPacket && <div>
            <div className="flex-between">
                <h4>Edit Packet</h4>
                    <button type="button" className="btn btn-default" onClick={()=>{setSelectedPacket(undefined)}}>
                        <Icon type={IconType.close} /> close
                    </button>
            </div>
            <div>
                <PacketBuilder 
                packet={selectedPacket} 
                update={packetUpdated} 
                close={()=>{setSelectedPacket(undefined)}}                 
                />
            </div>
            </div>}
        </div>
    </>);
};

export default GroupManager;