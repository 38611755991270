import React, { useState, useEffect } from 'react'
import { store } from "../../../redux/oldStore.ts";
import { toast } from 'react-toastify';


const JudgesView = (props) => {
  const [fullInfo, setFullInfo] = useState({});
  const [judges, setJudges] = useState([]);
  const [filteredJudges, setFilteredJudges] = useState([])
  const [searchVal, setSearchVal] = useState('')


  const handleJudgeUpdate = (judge) => {
    let reqObj = { judgeId: judge.personId, isVeteran: !judge.veteran };
    store.server.postApi('../AcaDeca/UpdateJudge', reqObj)
      .then(res => {
        console.log(res);
        setJudges(judges.map(j => j.personId === judge.personId ? { ...j, veteran: !j.veteran } : j));
        setFilteredJudges(filteredJudges.map(j => j.personId === judge.personId ? { ...j, veteran: !j.veteran } : j));
      })
      .catch(err => {
        console.error(err);
        toast.warning('unable to update judge');
      });
  };

  useEffect(() => {
    store.server.postApi('../AcaDeca/Judges', {})
      .then(res => {
        console.log(res);
        setFullInfo(res.Value);
        setJudges(res.Value.judges);
        setFilteredJudges(res.Value.judges);
      })
      .catch(err => {
        console.log(err);
        toast.warning('something went wrong getting info');
      });
  }, []);

  useEffect(() => {
    setFilteredJudges(judges.filter(judge => judge.firstName.toLowerCase().includes(searchVal.toLowerCase()) || judge.lastName.toLowerCase().includes(searchVal.toLowerCase())))
  }, [searchVal])





  const mappedJudges = filteredJudges.map((judge, i) => {
    return (
      <tr key={judge.personId}>
        <td>{judge.firstName} {judge.lastName}</td>
        <td>{judge.email}</td>
        <td>{judge.veteran ? <><i className="far fa-user-crown"></i></> : ''}</td>
        <td className="text-right">
          <button
            className={`btn btn-xs ${judge.veteran ? 'btn-default' : 'btn-info'} `}
            onClick={() => handleJudgeUpdate(judge)}
          >
            {!judge.veteran ? <span><i className="fa fa-crown"></i>promote</span> : <span><i className="fa fa-user"></i>demote</span>}
          </button>
        </td>
      </tr>
    )
  });


  return (
    <div className='bumper-l'>
      <div className="flex-between">

        <h3>Judges</h3>
        <div className="form-input">
        <label htmlFor="search-by-name"><i className="far fa-search"></i></label>
        <input
          type='text'
          name='search-by-name'
          id='search-by-name'
          placeholder='search judges by name'
          onChange={(e) => setSearchVal(e.target.value)}

        />
        </div>
      </div>
      <div
        className='bumper-l w-100'

      >

        <table
          className='judge-table'
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {mappedJudges}
          </tbody>

        </table>
      </div>

    </div>
  )
}

export default JudgesView