import React, { useEffect, useState } from 'react';
import { store } from '../../../../redux/oldStore';
import _ from 'lodash';
import { NewModal } from './NewModal';
import Modal from '../../../Modal/Modal';
import AddOrEditQuickComment from './AddOrEditQuickComment';
import { QueryStuff } from '../../../QuestionCreation/QuestionCreator';
import queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';


export interface JudgingQuickComment {
  Id: number;
  FairId: number;
  CreateAtUtc: string;
  RemovedAtUtc: string | null;
  ParentGroup: string;
  Comment: string;
  Sort: number;
  Active: boolean;
  ParentGroupSub: string;
}

export type QuickCommentLevel = 'Category' | 'SubCategory' | 'Comment'

export interface SubCategoryOptions {
  sub: string;
  parent: string;
}


const QuickComments = (props: RouteComponentProps) => {
  const [quickComments, setQuickComments] = useState<JudgingQuickComment[]>([]);
  const [sortedQuickComments, setSortedQuickComments] = useState<JudgingQuickComment[]>([]);
  const [selectedComment, setSelectedComment] = useState<JudgingQuickComment | undefined>();
  const [commentLevel, setCommentLevel] = useState<QuickCommentLevel>('Category');
  const [addOrEditModalOpen, setAddOrEditModalOpen] = useState<boolean>(false);

  const [availableCategories, setAvailableCategories] = useState<string[]>([])
  const [availableSubCategories, setAvailableSubCategories] = useState<SubCategoryOptions[]>([])

  // const [commentsAsObject, setCommentsAsObject] = useState<any>({})
  // const [objectifiedQuickComments, setObjectifiedQuickComments] = useState<any>({});

  useEffect(() => {
    refreshQuickComments();
  }, [props.location.search]);

  const getQueryStuff = () => {
    const queryValues: QueryStuff = queryString.parse(props.location.search);
    return queryValues;
}

  const refreshQuickComments = () => {
    store.server.postApi('../OnlineJudgingSetup/GetAllQuickComments', {RubricId: getQueryStuff().rubricId}).then((res: any) => {
      setQuickComments(res.Value);
    })
  }

  useEffect(() => {
    let sortedArr = _.orderBy(quickComments, ['ParentGroup', 'ParentGroupSub', 'Comment'], ['asc', 'desc', 'asc'])
    setSortedQuickComments(sortedArr)
    let availCat = _.uniq(sortedArr.map(x => x.ParentGroup))
    let availSubCat = _.uniqBy(sortedArr.map(x => {
        return {
          sub: x.ParentGroupSub,
          parent: x.ParentGroup
      }
    }), 'sub')
    setAvailableCategories(availCat);
    setAvailableSubCategories(availSubCat);

  }, [quickComments])


  const handleAddCategory = () => {
    setCommentLevel('Category');
    setAddOrEditModalOpen(true);
  }
  const handleAddSubCategory = () => {
    setCommentLevel('SubCategory');
    setAddOrEditModalOpen(true);
  }
  const handleAddComment = () => {
    setCommentLevel('Comment');
    setAddOrEditModalOpen(true);

  }

  const handleEditCommentClick = (quickComment: JudgingQuickComment, quickCommentLevel: QuickCommentLevel) => {
    console.log('clicked edit on', quickComment, quickCommentLevel)
    setSelectedComment(quickComment);
    setCommentLevel(quickCommentLevel);
    setAddOrEditModalOpen(true);



  }

  const mappedQuickComments = sortedQuickComments.map((x, i) => {
    let commentLevel: QuickCommentLevel = 'Category';
    if (x.ParentGroupSub !== null) commentLevel = 'SubCategory';
    if (x.Comment !== '---') commentLevel = 'Comment';
    return (
      <tr key={`${x.Id}-${i}`}>
        <td>{(x.ParentGroupSub || (x.Comment !== '---')) ? null : x.ParentGroup}</td>
        <td>{(x.Comment !== '---' && x.ParentGroupSub === null) ? '---' : (x.Comment === '---' ? x.ParentGroupSub : null)}</td>
        <td>{x.Comment === '---' ? null : x.Comment}</td>
        <td>{x.Sort}</td>
        <td onClick={() => handleEditCommentClick(x, commentLevel)}><i className='far fa-edit' /></td>

      </tr>
    )
  })

  return (
    <div id='quick-comments'>
      <div>
        <h2>Quick Comments</h2>
        <p>
          Quick comments allow your judges to quickly select areas for improvement and areas of strength. If desired a judge can also expand on this comment.
        </p>
      </div>
      <div>
        <div>
          <h2><i className='fa fa-commenting-o' /> Comments</h2>
          <div>
            <button onClick={handleAddCategory} className='btn btn-default btn-s'><i className='fa fa-plus' /> Add Category</button>
            <button onClick={handleAddSubCategory} className='btn btn-default btn-s'><i className='fa fa-plus' /> Add Sub Category</button>
            <button onClick={handleAddComment} className='btn btn-default btn-s'><i className='fa fa-plus' /> Add Comment</button>
          </div>
        </div>
        <table className='table table-striped'>
          <tbody>

            <tr>
              <th>Category</th>
              <th>Sub-Category</th>
              <th>Comment</th>
              <th>Sort</th>
              <th>Edit</th>
            </tr>
            {mappedQuickComments}
          </tbody>

        </table>
      </div>
      {addOrEditModalOpen
        &&
        <Modal
          title={`Quick Comment - ${commentLevel}`}
          setModalOpen={setAddOrEditModalOpen}
        >
          <AddOrEditQuickComment
            selectedComment={selectedComment}
            setSelectedComment={setSelectedComment}
            setModalOpen={setAddOrEditModalOpen}
            commentLevel={commentLevel}
            availableCategories={availableCategories}
            availableSubCategories={availableSubCategories}
            refreshQuickComments={refreshQuickComments}

          />

        </Modal>

      }
    </div>
  )
}


export default withRouter(QuickComments);