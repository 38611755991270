import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import StoreX from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";
import { v4 as uid } from "uuid";

import Icon, { IconType } from "../Icon/Icon";
import Editor from "./Editor";

import { IFieldInfo, IFormDetail, IFormGroup, IFormSupportingDocument, IFormWizardRule } from "./FormTypes";
import FileUpload from "./FileUpload";
import FieldsManager from "./FieldsManager";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Selectable } from "../../utils/CommonTypes/CommonTypes";
import FormsWizardConditionsManager from "./FormsWizardConditionsManager";
import FormsSupportingDocumentManager from "./FormsSupportingDocumentManager";
import PdfViewer from "../PdfViewer/PdfViewer";

interface IFormBuilderProps {
  form: IFormDetail;
  close:Function;
  update:Function;
  packet:IFormGroup;
  signers: Selectable[];
}

const FormBuilder = (props: IFormBuilderProps) => {
  const [form, setForm] = useState<IFormDetail>(props.form);
  const [signers, setSigners] = useState<Selectable[]>(props.signers ?? []);


  
  const save = (close?: boolean) => {
    let p = form;
    saveIt(p, close);
  };

  const saveIt = (formToSave, close?:boolean) =>{ 
    StoreX.instance.server
    .postApi<ServerResponse<IFormDetail>>(`../GenericForms/SaveForm`,formToSave)
    .then((x) => {
      if (x.Success) {
        setForm(x.Value);
        props.update(form, signers);
        if(close === true) setTimeout(props.close, 500);
      } else {
        toast.error(`Error saving Form. ${x.Message}`, {
          autoClose: false,
        });
      }
    });
  };

  const uploadFile = (files) =>{
    let request = form;
    if(!request.Name) request.Name = files[0].name;

    StoreX.instance.server.postApi<ServerResponse<IFormDetail>>(`../GenericForms/UploadFileForForm`, request, files)
    .then(x=>{
      if (x.Success) {
          setForm(x.Value);
          props.update(form, signers);
        } else {
          toast.error(`Error uploading file for form. ${x.Message}`, {
            autoClose: false,
          });
        }
    });
};

const removeFile = (files) =>{
    let request = form;

    StoreX.instance.server.postApi<ServerResponse<IFormDetail>>(`../GenericForms/RemoveFileFromForm`, request, files)
    .then(x=>{
      if (x.Success) {
          setForm(x.Value);
          props.update(form, signers);
        } else {
          toast.error(`Error removing file for form. ${x.Message}`, {
            autoClose: false,
          });
        }
    });
};
const uploadFileNew = (files) =>{
    let request = form;
    if(!request.Name) request.Name = files[0].name;

    StoreX.instance.server.postApi<ServerResponse<IFormDetail>>(`../GenericForms/UploadNewFileForForm`, request, files)
    .then(x=>{
      if (x.Success) {
          setForm(x.Value);
          props.update(form, signers);
        } else {
          toast.error(`Error uploading file for form. ${x.Message}`, {
            autoClose: false,
          });
        }
    });
};

const removeFileNew = (files) =>{
    let request = form;

    StoreX.instance.server.postApi<ServerResponse<IFormDetail>>(`../GenericForms/RemoveNewFileFromForm`, request, files)
    .then(x=>{
      if (x.Success) {
          setForm(x.Value);
          props.update(form, signers);
        } else {
          toast.error(`Error removing file for form. ${x.Message}`, {
            autoClose: false,
          });
        }
    });
};

  const saveAndClose = () => {
    save(true);
  };

  const updateFields = (fields:IFieldInfo[], signers2:Selectable[])=>{
      saveIt({...form, Fields:fields},false);
      setSigners([...signers2??[]]);
  };

  const updateAndSaveRules = (rules:IFormWizardRule[])=>{
    let f = {...form, Rules:rules};
    saveIt(f, false);
  };

  const updateAndSaveSupportingDocuments = (docs:IFormSupportingDocument[])=>{
    let f = {...form, SupportingDocuments:docs};
    saveIt(f, false);
  };



  return (
    <>
      <Tabs>
          <TabList>
              <Tab>Form</Tab>
              <Tab>Fields</Tab>
              <Tab>Use Conditions</Tab>
              <Tab>Supporting Documents</Tab>
          </TabList>
          <TabPanel>
              
      <h4>Form Builder</h4>
      Fill out the following form to start creating the set of custom forms you
      need to use and collect.
      <hr />
              <div className="form-horizontal">
              <div className="form-group">
          <label htmlFor="FormName" className="control-label col-sm-4">
            Form Name
          </label>
          <div className="col-sm-8">
            <input
              className="form-control"
              id="FormName"
              type="text"
              value={form.Name}
              onChange={(e) => {
                setForm({ ...form, Name: e.target.value });
              }}
              maxLength={250}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-8 col-sm-offset-4">
            <input type='checkbox'
            id="form-file-per-owner"
            checked={form.FilePerOwner}
            onChange={(e)=>{setForm({...form, FilePerOwner:e.target.checked})}}
            />

            <label className="control-label" htmlFor="form-file-per-owner">
              File per owner
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-8 col-sm-offset-4">
            <input type='checkbox'
            id="form-file-wizard"
            checked={form.UsedBasedOnWizard}
            onChange={(e)=>{setForm({...form, UsedBasedOnWizard:e.target.checked})}}
            />

            <label className="control-label" htmlFor="form-file-wizard">
              If needed is based on wizard.
            </label>
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-sm-4">Description</label>
          <div className="col-sm-8">
            <Editor
              options={"simple"}
              value={form.Description}
              changed={(v) => {
                setForm({ ...form, Description: v });
              }}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4">File</label>
          <div className="col-sm-8">
            <FileUpload 
            fileKey={form.FilePath ? form.PublicId : null}
            fileType={"file-template"} 
            exts={[".pdf"]} 
            uploadFile={uploadFile} 
            removeFile={removeFile}
            formNotImage={true}
            formShowPreview={false}
            />
          </div>
        </div>
{form?.FilePath && <div className="form-group">
          <label className="control-label col-sm-4">New File</label>
          <div className="col-sm-8">
            <FileUpload 
            fileKey={(form.NewFilePath ? form.PublicId+'?new=1': null)}
            fileType={"file-template"} 
            exts={[".pdf"]} 
            uploadFile={uploadFileNew} 
            removeFile={removeFileNew}
            formNotImage={true}
            formShowPreview={false}
            />
          </div>
        </div>}
              </div>
          </TabPanel>
          <TabPanel>

          <h4>Form Builder Fields</h4>
      Below is a list of fieds for this form, once you have pulled fields from the form it is recommened that you do not do that again as it will remove your current fields. If you don't have any fields listed, you can try and pull fields from your upload file. If that is not an option have you uploaded a file?
      <hr />
          <div className="form-group">
          <label className="control-label col-sm-4">Fields</label>
          <div className="col-sm-8">
            <FieldsManager 
            fields={form.Fields}
            fieldsUpdated={updateFields} 
            filePath={form.FilePath}
            formId={form.PublicId}
            signers={signers}
            form={form}
            />
          </div>
        </div>
          </TabPanel>
          <TabPanel>
            <FormsWizardConditionsManager packet={props.packet} form={form} updateAndSave={updateAndSaveRules} />
          </TabPanel>
          <TabPanel>
            <FormsSupportingDocumentManager packet={props.packet} form={form} updateAndSave={updateAndSaveRules} />
          </TabPanel>
      </Tabs>
      <div className="form-horizontal">
<hr/>

        <div className="form-group">
          <div className="col-sm-12 flex-between">
              <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={saveAndClose}
            >
              <Icon type={IconType.save} /> Save & Close Form
            </button>
            <button type="button" className="btn btn-default" onClick={()=>{save(false);}}>
              <Icon type={IconType.save} /> Save
            </button>
            </div>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => {
                props.close();
              }}
            >
              <Icon type={IconType.close} /> Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormBuilder;
