import React, {useState, useEffect} from 'react'
import Load from './Load'
import { store } from '../../../redux/oldStore'
import { toast } from 'react-toastify'

const Scores = (props) => {
  const [ showLoading, setShowLoading ] = useState(false)
  const [scoresInfo, setScoresInfo] = useState({})


  useEffect(() => {
    store.server.postApi('../AcaDeca/Scores', {})
      .then(res => {
        console.log(res);
        setScoresInfo(res.Value);
      })
      .catch(err => {
        console.error(err);
        toast.warning("yeah, that didn't work, dawg.")
      })
  }, []);

  const downloadScores = (type)=>{
    let isDev = window.location.host.indexOf("localhost:") > -1;
    let url;
    let host = isDev ? 'http://localhost:8081' : window.location.origin;
    switch(type){
      case "speech":
        url = `${host}/acadeca/scores/speech`;
      break;
      case "interview":
        url = `${host}/acadeca/scores/interview`;
      break;
      default:
        break;
    }

    if(url){
      toast.info(`Hold on we're getting those scores.`);
      window.open(url);
    }
    else {
      toast.error(`It's not my day, something went wrong!`);
    }

  };

  return(
    <div>
      <div
        className='header bumper-l'
      >
        <h3>Click to Download Scores</h3>
      </div>
      <div
        className='bumper-l download-scores'
      >
        <div onClick={()=>{downloadScores('speech');}}>Speech Scores <i className="fad fa-cloud-download-alt    text-info"></i></div>
        <div onClick={()=>{downloadScores('interview');}}>Interview Scores <i className="fad fa-cloud-download-alt text-info"></i></div>
      </div>
    </div>
  )
}

export default Scores