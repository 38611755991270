import { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
  closeModalHookDef,
  fileType,
  project,
  updateFileHookDef,
  uploadedFile,
  uploadFileHookDef,
  fileRemovedHookDef,
  button,
} from "./paperworkTypes";
import StoreX, { store } from "../../redux/oldStore";
import FileUploadComponent from "./FileUploadComponent";
import Modal from "./Modal";
import FileSignComponent from "./FileSignComponent";
import {
  buildButtons,
  fileStatusType,
  getFileStatusString,
  getFileTypeName,
} from "./paperworkReviewUtilities";
import { toast } from "react-toastify";
import Icon, { IconType } from "../Icon/Icon";
import { ModalBody } from "reactstrap";
import { IProjectComment } from "../../ObjectTypes/projectTypes";
import { ServerResponse } from "../../utils/Server";
import { IUser } from "../../redux/types";
import { IState, ReduxMap } from "../../redux/redux";

interface fileViewComponentProps extends IState {
  fileX: uploadedFile;
  project: project;
  filetypes: fileType[];
  closeModalHook: closeModalHookDef;
  fileUpdateHook: updateFileHookDef;
  fileUploadHook: uploadFileHookDef;
  fileRemovedHook: fileRemovedHookDef;
};

type fileViewComponentState = {
  fileReviewUpdate: fileReviewUpdate;
  errors: any[];
  showUploadFileModal: boolean;
  showSigningModal: boolean;
};

export type fileReviewUpdate = {
  fileId: number;
  fileStatus: fileStatusType | any; //todo:  find out what this filestatus should be and fix it.
  shouldSendEmail: boolean;
  shouldCopyTeacher: boolean;
  shouldCopyParent: boolean;
  comments?: string;
};

const FileViewComponent: FunctionComponent<fileViewComponentProps> = (
  props: fileViewComponentProps
) => {
  //#region set initial state
  const defaultState: fileViewComponentState = {
    fileReviewUpdate: {
      fileId: props.fileX.id,
      fileStatus: props.fileX.fileStatus, //TODO:  should this be of type fileStatusType??
      shouldSendEmail: false,
      shouldCopyTeacher: false,
      shouldCopyParent: false,
      comments: props.fileX.notes || "", //todo:  comments do not seem to be loading.
    },
    errors: [],
    showUploadFileModal: false,
    showSigningModal: false,
  };

  const [myState, setMyState] = useState(defaultState);
  const [reviewState, setReviewState] = useState<string>("");
  const [file, setFile] = useState<uploadedFile>(
    props.project.UploadedFiles.find((x) => x.id === props.fileX.id) ??
      props.fileX
  );
  const [uploadedFiles, setUploadedFiles] = useState<uploadedFile[]>(
    props.project.UploadedFiles
  );
  const [changeFile, setChangeFile] = useState<boolean>(false);
  const [showComments, setShowComments] = useState<boolean>(false);
  const [comments, setComments] = useState<IProjectComment[]>([]);
  const [filesComments, setFilesComments] = useState<IProjectComment[]>([]);
  const [showFileComments, setShowFileComments] = useState<boolean>(true);

  useEffect(() => {
    LoadComments();
  }, []);

  useEffect(()=>{
    console.log(file);
    if(file){
    setFilesComments([...comments?.filter(x=>x.LinkId == file.fileType) ?? []]);
    

    } else {
      setFilesComments([...comments]);
    }
  }, [comments, file])

  const LoadComments = () => {
    store.server
      .getApi<ServerResponse<IProjectComment[]>>(
        `../Project/Comments/${props.project.ProjectKey}`
      )
      .then((x) => {
        if (x.Success) {
          setComments(x.Value);
        }
      });
  };

  const onSelectFileStatus = (fileStatusType: fileStatusType) => {
    myState.fileReviewUpdate.fileStatus = fileStatusType;
    setReviewState(fileStatusType + "");
  };

  const checkForm = () => {
    myState.errors = [];

    if (!myState.fileReviewUpdate.fileStatus) {
      setMyState((s) => ({
        ...s,
        errors: s.errors.concat("File status required."),
      }));
    }
    if (!myState.errors.length) {
      return true;
    }
    return false;
  };

  const uploadedFileChange = () => {
    let url = file.url;
    if (url.indexOf("?") > -1) url += "&1up";
    else url += "?1up";
    setFile({ ...file, url });

    setShowUploadFileModal(false);
  };

  const setShowUploadFileModal = (value: boolean) => {
    setMyState((s) => ({
      ...s,
      showUploadFileModal: value,
    }));
  };

  const setShowSigningModal = (value: boolean) => {
    setMyState((s) => ({
      ...s,
      showSigningModal: value,
    }));
  };

  const launchDocumentSigning = () => {
    setMyState((s) => ({
      ...myState,
      showSigningModal: true,
    }));
  };

  const submitFileReview = async () => {
    if (reviewState.length === 0) {
      toast.warning("You must change the file status to submit your review.");
      return;
    }

    const success = (data: uploadedFile) => {
      props.fileUpdateHook(data);
      props.closeModalHook();
    };
    const handleError = (error: any) => {
      toast.error(error);
      console.error(error);
    };
    myState.fileReviewUpdate.fileId = file.id;

    if (checkForm()) {
      try {
        const data =
          await store.server?.postApiWithServerResponse<uploadedFile>(
            "../PaperworkReview/UpdateFileReview",
            myState.fileReviewUpdate
          );
        if (data?.Success) {
          toast.info("File status updated");
          success(data.Value);
        } else {
          handleError(data?.Message);
        }
      } catch (error) {
        handleError(error);
      }
    }
  };

  const removeFile = async () => {
    if (!window.confirm(`Are you sure you want to delete this file?`)) {
      return;
    }

    const success = (data: uploadedFile) => {
      props.fileRemovedHook(file);
      props.closeModalHook();
    };
    const handleError = (error: any) => {
      console.error(error);
    };
    myState.fileReviewUpdate.fileId = file.id;

    if (checkForm()) {
      try {
        const data =
          await store.server?.postApiWithServerResponse<uploadedFile>(
            "../PaperworkReview/RemoveFileUpload",
            myState.fileReviewUpdate
          );
        if (data?.Success) {
          toast.info("File removed.");
          success(data.Value);
        } else {
          handleError(data?.Message);
        }
      } catch (error) {
        handleError(error);
      }
    }
  };

  const close = () => {
    props.closeModalHook();
  };

  const updateComments = (comments: string) => {
    setMyState((s) => ({
      ...s,
      fileReviewUpdate: {
        ...s.fileReviewUpdate,
        comments: comments,
      },
    }));
  };

  const updateShouldCopyParent = (value: boolean) => {
    setMyState((s) => ({
      ...s,
      fileReviewUpdate: {
        ...s.fileReviewUpdate,
        shouldCopyParent: value,
      },
    }));
  };

  const updateShouldCopyTeacher = (value: boolean) => {
    setMyState((s) => ({
      ...s,
      fileReviewUpdate: {
        ...s.fileReviewUpdate,
        shouldCopyTeacher: value,
      },
    }));
  };

  const updateShouldSendEmail = (value: boolean) => {
    setMyState((s) => ({
      ...s,
      fileReviewUpdate: {
        ...s.fileReviewUpdate,
        shouldSendEmail: value,
      },
    }));
  };

  //#endregion

  //#region memos/computed values

  const selectedFileType = useMemo(() => {
    return getFileTypeName(file, props.filetypes);
  }, [file, props.filetypes]);

  const boundFile = useMemo(() => {
    return props.project.UploadedFiles.filter((f) => file.id === f.id)[0];
  }, [props.project.UploadedFiles, file.id]);

  const lastUpdatedDate = useMemo(() => {
    return boundFile.lastUpdatedDate?.toLocaleString()
      ? boundFile.lastUpdatedDate.toLocaleString()
      : "Unknown";
  }, [boundFile]);

  const lastReviewedDate = useMemo(() => {
    return boundFile.lastReviewedDate?.toLocaleString()
      ? boundFile.lastReviewedDate.toLocaleString()
      : "Not yet reviewed";
  }, [boundFile]);

  const closeFileUploadModalHook: closeModalHookDef = () => {
    setShowUploadFileModal(false);
    uploadedFileChange();
  };

  const closeSigningModalHook: closeModalHookDef = () => {
    setShowSigningModal(false);
    uploadedFileChange();
  };

  const SelectNewFile = (button: button) => {
    let f = props.project.UploadedFiles.find((x) => x.id === button.fileId);
    if (f) setFile(f);
    setChangeFile(false);
  };

  //#endregion

  return (
    <>
      <div className="">
        <div style={{ position: "fixed", top: 0, right: 0, zIndex: 50 }}>
          <button type="button" className="close" onClick={close}>
            <span style={{ fontSize: "35px" }}>&times;</span>
          </button>
        </div>
      </div>
    <div className="file-inspection">
        <div className="file-inspection-header">
          <div className="flex-space-between">
            <div className="text-left review-file-admin-panel">
              <h4>{props.project.ProjectId}</h4>
              <div>
                <strong>{props.project.Title}</strong>
              </div>
              {props.project.Category && (
                <div>{props.project.Category.Name}</div>
              )}

              {props.project.School && <div className="small">School: {props.project.School}</div>}
            </div>
            {changeFile ? (
              <Modal
                closeModalHook={() => {
                  setChangeFile(false);
                }}
                header={<>Uploaded Files</>}
                body={
                  <div className="file-tite-bar">
                    {buildButtons(
                      uploadedFiles,
                      props.filetypes,
                      props.project.FileTypeRequirementOverrides,
                      true
                    ).map((b, i) => {
                        if(!b.fileId) return <></>;
                      return (
                        <div
                          key={`file-${i}-item`}
                          onClick={() => {
                            SelectNewFile(b);
                          }}
                          className={`file-tile ${
                            b.fileId == file.id ? "active" : ""
                          } ${b.required ? "required" : ""}`}
                        >
                          {b.fileName}
                        </div>
                      );
                    })}
                  </div>
                }
                footer={<></>}
              ></Modal>
            ) : (
              <div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setShowComments(true);
                }}
              >
                <Icon type={IconType.comments} /> View Comments
              </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setChangeFile(true);
                  }}
                >
                  <Icon type={IconType.filePdf} /> Change File
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="file-inspection-body">
            <div className="review-file-admin-panel">
                <div>
                  <h4>File Review {selectedFileType}</h4>
                  {myState.errors.length > 0 && (
                    <div className="alert alert-danger">
                      <b></b>
                    </div>
                  )}
                  <div className="">
                    <span style={{ fontSize: "largest" }}>
                      File: <strong>{selectedFileType}</strong>
                    </span>
                    <br />
                    Last Reviewed: <strong>{lastReviewedDate}</strong>
                    <br />
                    Last Updated: <strong>{lastUpdatedDate}</strong>
                    <br />
                  </div>
                  <div>
                    {/* {props.project.TeamMembers.map((person, index) => (
                                <div key={index}><i>{person.firstName}  {person.lastName}</i></div>
                            ))} */}
                    <div>
                      Current Status{" "}
                      <strong>{getFileStatusString(file.fileStatus)}</strong>
                    </div>
                  </div>
                  <div className="form-horizontal">
                    <div className="form-group">
                      <div className="col-md-12">
                        <label className="control-label" htmlFor="fileComments">
                          Comments:
                        </label>
                        <textarea
                          id="fileComments"
                          value={myState.fileReviewUpdate.comments}
                          className="form-control"
                          rows={10}
                          onChange={(evt) => {
                            updateComments(evt.target.value);
                          }}
                        ></textarea>
                      </div>
                    </div>
                    <div id="ContentPlaceHolder1_AdminFileReview1_ReviewFileInputs">
                      <div className="form-group">
                        <div className="col-md-12 label-inline">
                          <div>
                            <input
                              type="checkbox"
                              id="AFRSendEmail"
                              checked={myState.fileReviewUpdate.shouldSendEmail}
                              onChange={(evt) => {
                                updateShouldSendEmail(evt.target.checked);
                              }}
                            ></input>
                            <label htmlFor="AFRSendEmail">
                              Send email on status change
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="AFRCopyTeacher"
                              checked={
                                myState.fileReviewUpdate.shouldCopyTeacher
                              }
                              onChange={(evt) => {
                                updateShouldCopyTeacher(evt.target.checked);
                              }}
                            ></input>
                            <label htmlFor="AFRCopyTeacher">
                              Copy student's teacher
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="AFRCopyParent"
                              checked={
                                myState.fileReviewUpdate.shouldCopyParent
                              }
                              onChange={(evt) => {
                                updateShouldCopyParent(evt.target.checked);
                              }}
                            ></input>
                            <label htmlFor="AFRCopyParent">
                              Copy student's parent*
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="flex">
                            <select className="form-control grow dropdown-status-menu" value={reviewState} onChange={(e)=>{onSelectFileStatus(+e.target.value)}}>
                              <option value={''}>Set Status</option>
                                <option value={fileStatusType.pending+''}>Pending</option>
                              <option value={fileStatusType.accepted+''}>Accepted</option>
                                <option value={fileStatusType.needsWork+''}>Needs Work</option>
                            </select>
                          {/*End single button Dropdown */}
                          <button
                            type="button"
                            className="btn btn-info submit-review-btn"
                            onClick={submitFileReview}
                            style={{marginRight:0}}
                          >
                            Submit Review
                          </button>
                        </div>
                        <div className="head-room">
                          <button
                            type="button"
                            className="btn btn-success sign-form-btn btn-block"
                            onClick={launchDocumentSigning}
                          >
                            <Icon type={IconType.sign} />{" "}
                            Sign Form
                          </button>
                        </div>
                        <br /> * Parent's email must be present in registration
                        file.
                        <div className="head-room">
                          <button
                            type="button"
                            aria-hidden="true"
                            className="btn btn-secondary close-paperwork-btn"
                            onClick={close}
                          >
                            <Icon type={IconType.close} />
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn btn-default"
                            onClick={() => {
                              setShowUploadFileModal(true);
                            }}
                          >
                            <Icon type={IconType.upload} /> Replace
                          </button>
                          <button
                            type="button"
                            aria-hidden="true"
                            className="btn btn-default"
                            onClick={removeFile}
                          >
                            <span className="text-danger">
                              <Icon type={IconType.remove} /> Remove
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className="flex-grow file-view-container">
              <embed
                src={file.url + "#toolbar=0&navpanes=0&view=FitH"}
                width="100%"
                height="100%"
                type="application/pdf"
              ></embed>
            </div>
        </div>
      </div>

      {/* Use the modal component, pass in the prop */}
      {myState.showUploadFileModal && (
        <Modal
          header={<h3>Replace File</h3>}
          body={
            <FileUploadComponent
              closeModalHook={closeFileUploadModalHook}
              fileTypes={props.filetypes}
              project={props.project}
              selectedFileTypeId={file.fileType}
              fileId={file.id}
              fileUploadedHook={props.fileUploadHook}
            />
          }
          footer={<>&nbsp;</>}
          closeModalHook={closeFileUploadModalHook}
        />
      )}

      {myState.showSigningModal && props.User && (
        <Modal
          closeModalHook={closeSigningModalHook}
          header={<span>Sign Document</span>}
          body={
            <FileSignComponent
              file={file}
              fileTypes={props.filetypes}
              project={props.project}
              closeModalHook={closeSigningModalHook}
              fileUploadedHook={props.fileUploadHook}
              user={props.User}
            />
          }
          footer={<>&nbsp;</>}
        />
      )}

      {showComments && (
        <Modal
          closeModalHook={() => {
            setShowComments(false);
          }}
          header={
            <div className="flex-between">
              <h4>Projects Comments</h4>
              <button
                type="button"
                className="btn btn-default"
                onClick={LoadComments}
              >
                <Icon type={IconType.redo} /> Refresh
              </button>
            </div>
          }
          body={
            <div className="comments-container">
            {!showFileComments && <>
              {comments.length === 0 && <div>
                  <h3>No Comments</h3>
                  Sorry no comments have been left at this time.
                  </div>}
              {comments.map((c,i)=>{
                  return <div className="comment" key={`comment-id-${c.Id}`}>
                      <div className="comment-header">{c.Header}</div>
                      <div className="comment-by"><span>{c.FromName}</span><span>{c.At}</span></div>
                      <div className="comment-message">{c.Comment}</div>
                  </div>
              })}
              </>}
              {showFileComments && <>
                {filesComments.length === 0 && <div>
                    <h3>No Comments</h3>
                    Sorry no comments have been left at this time.
                    </div>}
                {filesComments.map((c,i)=>{
                    return <div className="comment" key={`comment-id-${c.Id}`}>
                        <div className="comment-header">{c.Header}</div>
                        <div className="comment-by"><span>{c.FromName}</span><span>{c.At}</span></div>
                        <div className="comment-message">{c.Comment}</div>
                    </div>
                })}
                </>}
            </div>
          }
          footer={<>
            <button type="button" className="btn btn-default" onClick={()=>{setShowFileComments(!showFileComments)}} >
              { showFileComments
                ?
                <><Icon type={IconType.eye} /> Show All Comments</> 
              :
              <><Icon type={IconType.eyeNo} /> Show only this files comments</> }
            </button>
          </>}
        ></Modal>
      )}
    </>
  );
};
export default ReduxMap(FileViewComponent);
