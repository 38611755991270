import {useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import StoreX from '../../redux/oldStore';
import Icon, { IconType } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import { IFormDetail, IFormGroup, IFormWizardRule } from './FormTypes';
import Select from "react-select";

interface IFormsWizardConditionsManagerProps{
    packet:IFormGroup,
    form:IFormDetail,
    updateAndSave:Function,
}

const FormsWizardConditionsManager = (props:IFormsWizardConditionsManagerProps)=>{
    const [rules, setRules] = useState<IFormWizardRule[]>(props.form.Rules ?? []);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [rule, setRule] = useState<IFormWizardRule>();
    const [questionDic, setQuestionDic] = useState<any>({});

    useEffect(()=>{
        let dic = {};
        props.packet.WizardQuestions?.forEach(x=>{
            dic[x.PublicId] = x;
        });
        setQuestionDic(dic);
    },[]);


    const EditRule = (rule?:IFormWizardRule) =>{
        let max = 1;
        rules.forEach(x=>{
            max = Math.max(max, x.Order + 1);
        })
        let q:IFormWizardRule = rule ?? {Order:max,PublicId:StoreX.NewGuid(),Questions:[]};

        setRule(q);
        setShowEditModal(true);
    };

    const RemoveRule = () =>{
        if(window.confirm(`Are you sure you want to remove this rule, this cannot be undone?`)){
            let q = rules.filter(q=>q.PublicId !== rule?.PublicId);
            props.updateAndSave(q);
            setRules([...q]);
            setRule(undefined);
            setShowEditModal(false);
            toast.success(`Rule removed!`);
        }
    };
    const SaveQuestion = () =>{

        if(rule?.Questions.length === 0){
            toast.warn(`So, what are the questions?`);
            return;
        }

            let qs = rules;
            let found = false;

            for(let i =0; i < qs.length; i++){
                if(qs[i].PublicId == rule?.PublicId){
                    found = true;
                    qs[i] = {...rule};
                    break;
                }
            }

            if(!found) qs.push(rule);

            qs = qs.sort((a, b)=> a.Order - b.Order);

            props.updateAndSave([...qs]);
            setRules([...qs]);
            toast.success(`Rule saved!`);
    };

    return (
        <>
        <div>
            <h4>Rules Wizard</h4>
            Build the conditions for which this form is needed as part of this packet. They are just yes no questions at this point. 
            <hr/>

            <div>
                <div className="flex-between">
                    <h3>Rules</h3>
                    <button type="button" onClick={()=>{EditRule()}} className="btn btn-secondary">
                        <Icon type={IconType.plus}/> New Rule
                    </button>
                </div>
                <div className="wizard-question-container">
                    {rules.map((x,i)=>{
                        return (<div key={`wiz-question-${i}`} className='qizard-question-tile'>
                            <div>
                                <span className='text-muted'>{x.Order} - </span>
                                {x.OnTrueRequire ? <span className="text-success"><Icon type={IconType.on}/> Required</span>
                                : <span className="text-danger"><Icon type={IconType.off}/> hidden</span>}
                                <div>If the following is true:</div>
                                {x.Questions?.map((q,iq)=>{
                                    let qu = questionDic[q];
                                    if(qu)
                                    return (<li key={`qu-${i}-${iq}`}>{qu.Question}</li>);
                                    else return null;
                                })}
                                </div>
                                <span className='click' onClick={()=>{EditRule(x);}} title='Click to edit question'>
                                    <Icon type={IconType.edit}  />
                                </span>
                            </div>);
                    })}
                </div>
            </div>

        </div>


        {showEditModal && <Modal setModalOpen={setShowEditModal} title={'Edit Rule'} size='l'>
            <div className='form-horizontal'>
                <div className='form-group'>
                    <label htmlFor='wiz-que-order' className='col-sm-4 control-label'>Order</label>
                    <div className='col-sm-2'>
                        <input type='number' max={10000} min={0} step={1} className='form-control'
                         id='wiz-que-order'
                         value={rule?.Order}
                         onChange={(e)=>{if(rule)setRule({...rule, Order:+e.target.value});}}
                         />
                    </div>
                </div>
                <div className='form-group'>
                    <div className='col-sm-8 col-sm-offset-4'>
                        <input type='checkbox' 
                         id='wiz-que-turns-on'
                         checked={rule?.OnTrueRequire}
                         onChange={(e)=>{if(rule)setRule({...rule, OnTrueRequire:e.target.checked});}}
                         />
                    <label htmlFor='wiz-que-turns-on' className='control-label'>Makes Required (checked)</label>
                    </div>
                </div>
                <div className='form-group'>
                    <label htmlFor='wiz-rule-questions' className='col-sm-4 control-label'>Questions</label>
                    <div className='col-sm-8'>                        
                        <Select
                            inputId="wiz-rule-questions"
                            onChange={(x)=>{
                                if(rule)setRule({...rule, Questions:x?.map(y=>{return y.value;}) ?? []});
                            }}
                            value={rule?.Questions?.map(r=>{return {value:r,label:questionDic[r]?.Question}})?? []}
                            options={props.packet.WizardQuestions?.map(x=>{
                                return {      value: x.PublicId,
                                    label: x.Question,}
                            })}
                            className=""
                            isMulti
                            isClearable={true}
                            menuPlacement={'auto'}
                        />
                    </div>
                </div>
                <br/>
                <div className='alert alert-info'>
                    <Icon type={IconType.warning}/>
                    Select all the questions, that when answered yes or true make this form {rule?.OnTrueRequire ? <>required</>:<>hidden</>}.</div>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className='form-group'>
                    <div className='col-sm-8 col-sm-offset-4'>
                        <button type='button' className='btn btn-secondary' title='Click to save' onClick={SaveQuestion}>
                            <Icon type={IconType.edit} /> Save
                        </button>
                        <button type='button' className='btn btn-default' title='Click to remove question' onClick={RemoveRule}>
                            <Icon type={IconType.trashO} /> Remove
                        </button>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        </Modal>}
        </>
    );

};

export default FormsWizardConditionsManager;