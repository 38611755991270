import { useState, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import StoreX, { store } from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";

import Icon, { IconType } from "../Icon/Icon";
import Nominations from "../Nominations/Nominations";
import OrderHistory from "../Store/OrderHistory";
import Modal from "../Modal/Modal";
import {default as Redux} from '../../redux/store';
import { toast } from "react-toastify";
import redux from "../../redux/redux";

interface registrationCompleteProps extends RouteComponentProps<MatchParams> {}

interface MatchParams {
  userId?: string;
  projectId?: string;
  targetId?: string;
  roleName?: string;
}

interface IDoneResponse {
  Sections: IDoneSection[];
  Person: IDonePerson;
  IsTeacherInnovatorAwardsApplication:boolean;
  IsTeacherInnovatorAwardsNomination:boolean;
}

interface IDoneSection {
  Header: string;
  Body: string;
  Class: string;
  PersonInfo: boolean;
  OrderHistory: boolean;
  SpecialAwardNominations:boolean;
}

interface IDonePerson {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  grade: number;
  title: string;
  projectID: string;
  Affiliate: string;
  category: string;
  teamProject: string;
  projectKey: string;
}

const UserRegistrationComplete = (props: registrationCompleteProps) => {
  const [ready, setReady] = useState<boolean>();
  const [sections, setSections] = useState<IDoneSection[]>();
  const [person, setPerson] = useState<IDonePerson>();
  const [showNominations, setShowNominations] = useState<boolean>(false);
  

  useEffect(() => {
    store.server
      .postApi<ServerResponse<IDoneResponse>>(`../userProfile/Done`, {
        userId: props.match.params.userId,
        projectId: props.match.params.projectId,
      })
      .then((x) => {
        setSections(x.Value.Sections);
        setPerson(x.Value.Person);
        redux.Reload();
        setReady(true);
      });
  }, []);

  return (
    <>
      <div className="done-page">
        {ready && (
          <>
            {sections &&
              sections.length > 0 &&
              sections.map((x: IDoneSection, i: number) => {
                if (x.PersonInfo)
                  return (
                    <div className="done-section">
                      <h4>Account Information</h4>
                      <ul className="none">
                        {true && (
                          <li>
                            <strong>Name:</strong> {person?.firstName}{" "}
                            {person?.lastName}
                          </li>
                        )}
                        {true && (
                          <li>
                            <strong>Email:</strong> {person?.email}
                          </li>
                        )}
                        {true && (
                          <li>
                            <strong>Username:</strong> {person?.username}
                          </li>
                        )}
                        {person?.title && (
                          <li>
                            <strong>Title:</strong> {person?.title}
                          </li>
                        )}
                        {person?.category && (
                          <li>
                            <strong>Category:</strong> {person?.category}
                          </li>
                        )}
                        {person?.projectID && !StoreX.Settings?.hideProjectId && (
                          <li>
                            <strong>{StoreX.Text.LabelProjectId}:</strong>{" "}
                            {person?.projectID}
                          </li>
                        )}
                        {person?.Affiliate && (
                          <li>
                            <strong>Affiliate:</strong> {person?.Affiliate}
                          </li>
                        )}
                        {person?.teamProject && (
                          <li>
                            <strong>{StoreX.Text.LabelProjectKey}:</strong>{" "}
                            {person?.projectKey}
                          </li>
                        )}
                      </ul>
                      <hr />
                    </div>
                  );
                else if (x.OrderHistory){
                  return (<OrderHistory userId={props.match.params.userId}></OrderHistory>)
                }
                else if (x.SpecialAwardNominations){
                  return (
                    <>
                    <div>
                      <h4>Award Nominations</h4>
                      <div>Click the nominations button to view and set your award nominations. </div>
                      <button type="button" className="btn btn-secondary" onClick={()=>{setShowNominations(true);}}>
                        <Icon type={IconType.flag} /> Nominations
                      </button>
                      <br/>
                      <br/>
                    </div>
                    {showNominations && <Modal
                      title="Nominations"
                      setModalOpen={setShowNominations}                    
                      size="xl"
                    >                      
                      <Nominations projectId={props.match.params.projectId} />
                    </Modal>}
                    </>
                    )

                }
                else
                  return (
                    <div className="done-section">
                      <div className={`${x.Class}`}>
                        {x.Header && (
                          <h3
                            dangerouslySetInnerHTML={{ __html: x.Header }}
                          ></h3>
                        )}

                        {x.Body && (
                          <div
                            dangerouslySetInnerHTML={{ __html: x.Body }}
                          ></div>
                        )}
                      </div>
                    </div>
                  );
              })}
          </>
        )}
      </div>
    </>
  );
};

export default withRouter(UserRegistrationComplete);
