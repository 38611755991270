import {useState, useEffect} from 'react';
import Prompts from "../Client/AcaDeca/Prompts";
import Icon, { IconType } from '../Icon/Icon';
import { IFormGroup } from "./FormTypes";

interface IQuestionWizardProps {
    packet:IFormGroup,
    values:any,
    save:Function,
    close:Function,
}

const QuestionWizard = (props:IQuestionWizardProps)=>{
    let temp = {};
    Object.keys(props.values ?? {}).map(x=>{
        if(props.values[x] && props.values[x] === true || props.values[x] === "true"){
            temp[x] = true;
        }
        else{
            temp[x]= false;
        }
    });
    const [values, setValues] = useState<any>(temp);

    
const save=(closeMe:boolean)=>{
    let v = values;
    props.packet.WizardQuestions?.forEach(x=>{
        if(!v[x.PublicId]) v[x.PublicId] = false;
    })
    props.save(v);
    if(closeMe) props.close();
}
const close = ()=>{
    props.close();
};



    return (<>
        <div>
            <div className='flex-between'>
                <h3>{props.packet.Name} Wizard</h3>
            </div>
            Answer the questions below, so we can identify which forms are needed for your packet. Check all that apply.
            <hr/>
            <div className='form-horizontal wizard-body inline-label'>
                {props.packet.WizardQuestions.map((x,i)=>{
                    return (<div key={`wiz-q-${i}`} className='form-group'>
                        <div className='col-sm-12'>
                            <input type='checkbox' 
                            id={`wiz-q-cb-${x.PublicId}`}
                            checked={values[x.PublicId] ?? false}
                            onChange={(e)=>{
                                setValues({...values,[x.PublicId]:e.target.checked});
                            }}
                            />
                            <label htmlFor={`wiz-q-cb-${x.PublicId}`} className='control-label'>{x.Question}</label>
                             </div>
                    </div>);
                })              
                }

                <div className='form-group'>
                    <div className='col-sm-12'>
                        <button type='button' className='btn btn-secondary' title='Click to save & close.' onClick={()=>{save(true);}}>
                            <Icon type={IconType.save} /> Save & Close
                        </button>
                        <button type='button' className='btn btn-default' title='Click to save.' onClick={()=>{save(false);}}>
                            <Icon type={IconType.save} /> Save
                        </button>
                        <button type='button' className='btn btn-default' title='Click to close.' onClick={()=>{close();}}>
                            <Icon type={IconType.close} /> Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default QuestionWizard;