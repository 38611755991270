import React, { FunctionComponent } from "react";
import { CSSTransition, Transition } from "react-transition-group";
import { isCallSignatureDeclaration } from "typescript";

type modalProps = {
    header: JSX.Element;
    body: JSX.Element;
    footer: JSX.Element;
    closeModalHook: () => void;
}


const Modal: FunctionComponent<modalProps> = ({ header, body, footer, closeModalHook }: modalProps) => {
    const closeModal = () => {
        if(closeModalHook) closeModalHook();
        else {
            console.log(`closeModalHook not defined for this component.`);
        }
    }

    const closeModalSelfOnly = (event: React.MouseEvent) => {
        console.debug("closeModalSelfOnly", event.target, event.currentTarget);
        if (event.target === event.currentTarget) {
            closeModal();
        }
    }


    return (
        <div className="modal-mask">
            <div className="modal-wrapper" onClick={closeModalSelfOnly}>
                <div className="modal-container">

                    <div className="modal-header">
                        {header}
                    </div>

                    <div className="modal-body">
                        {body}
                    </div>

                    <div className="modal-footer">
                        {footer}
                        <button className="btn btn-secondary" type="button" onClick={closeModal}>
                            Close
                  </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;