import { stringify } from "query-string";
import React, { useState } from "react";
import { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { store } from "../../redux/oldStore";
import { IState, ReduxMap } from "../../redux/redux";
import {
  FullShoppingCartItem,
  InvoicePaymentRequestResponse,
  Product,
  ProductsResponse,
} from "../../utils/CommonTypes/StoreTypes";
import { ServerResponse } from "../../utils/Server";
import { formatMoney } from "../../utils/Tools";
import Icon, { IconType } from "../Icon/Icon";
import Modal from "../Modal/Modal";
import ProductInModal from "./ProductInModal";
import ShoppingCart from "./ShoppingCart";

interface StoreFrontProps extends RouteComponentProps<MatchParams>, IState {}

interface MatchParams {
  userId?: string;
  invoice?: string;
  projectId?: string;
  targetId?: string;
  roleName?: string;
}

const StoreFront = (props: StoreFrontProps) => {
  const [inventory, setInventory] = useState<Product[]>([]);
  const [fullProductsResponse, setFullProductsResponse] =
    useState<ProductsResponse>();
  const [chosenProduct, setChosenProduct] = useState<Product>();
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [shoppingCart, setShoppingCart] = useState<FullShoppingCartItem[]>([]);
  const [cartCount, setCartCount] = useState(0);
  const [couponsUsed, setCouponsUsed] = useState<any[]>([]);

  useEffect(() => {
    let storeUrl = props.match.params?.userId
      ? `../store/products/${props.match.params?.userId}`
      : `../store/products`;
    store.server
      .postApi<ServerResponse<ProductsResponse>>(storeUrl, {orderId:props.match.params.invoice})
      .then((x) => {
        if(x.Success){
          handleProductsResponse(x.Value);
        } else {
          toast.error(`${x.Message}`,{autoClose:false});
        }
      });
  }, []);

  const handleProductsResponse = (stuff: ProductsResponse) => {
    setFullProductsResponse(stuff);
    console.log(`Fee Options` ,stuff.registrationFeeOptions)
    setInventory(stuff.products);
    setCartCount(stuff.cartCount);
    let contentsOfCart = stuff.products.reduce((prev, curr) => {
      return [
        ...prev,
        ...curr.InBag.map((x) => {
          return { ...x, Cost: curr.Cost, Name: curr.Name, Id: curr.Id, Removeable: curr.Removable, Limit: curr.Limit };
        }),
      ];
    }, new Array<FullShoppingCartItem>());
    setShoppingCart(contentsOfCart);
    if(stuff.NewRecord && stuff.products.length === 0 && stuff.total === 0){
      GoToNextStepForNewUsers('No Fee', false);
    }
  };

  const handleContinue = (ignoreCart: boolean | null | undefined) => {
    if (shoppingCart && fullProductsResponse?.total !== 0 && !ignoreCart) {
      //cart not empty.
      toast.warning(
        `You must purchase the items in your cart before you may continue.`
      );
      return;
    }
    GoToNextStepForNewUsers('No Fee', false);
  };

  const GoToNextStepForNewUsers = (reason:string|undefined, makeInvoice:boolean) => {
    store.server
      .postApi<ServerResponse<InvoicePaymentRequestResponse>>(
        "../Store/ProcessPaymentAndRegister",
        { userId: props.match.params.userId, reason:reason, makeInvoice:makeInvoice }
      )
      .then((res) => {
        if (res.Success) {
          if (res.Value.NextRecordPath) {
            props.history.push(`${res.Value.NextRecordPath}/${props.location.search}`);
            return;
          } else {
            toast.error(
              `I'm not sure where to send you. ${res.Value.Message}`,
              { autoClose: false }
            );
          }
        } else {
          if (res.Value?.Message)
            toast.error(`${res.Value.Message}`, { autoClose: false });
        }
      });
  };

  const handleProductClick = (prod: Product) => {
    setChosenProduct(prod);
    setProductModalOpen(true);
  };

  const handleProductClose = (open?: boolean) => {
    setChosenProduct(undefined);
    setProductModalOpen(false);
  };

  const mappedProducts = inventory
    .filter((x) => !x.IsHidden&&(!x.InBag || x.InBag.length < x.Limit))
    .map((prod, i) => {
      return (
        <div
          key={`${prod.Id}-${i}`}
          className="click store-item hover-shadow"
          onClick={() => handleProductClick(prod)}
        >
          {prod.ImageUrl && (
            <div className="text-center">
              <img
                className="store-item-image"
                src={prod.ImageUrl}
                alt={prod.Name}
              />
            </div>
          )}
          <div className="flex-between">
            <h4>{prod.Name}</h4>
            <div>{formatMoney.format(prod.Cost)}</div>
          </div>
          <div className="ellipsis item-description">{prod.Description}</div>
        </div>
      );
    });

  return (
    <div className="col-sm-12">
      {fullProductsResponse?.headerText && <div dangerouslySetInnerHTML={{__html:fullProductsResponse.headerText}}/>}
      <div>
        {inventory && inventory.filter(x=>!x.IsHidden&&(!x.InBag || x.InBag.length < x.Limit)).length > 0 && 
          <>
            {props.Settings?.text.RegistrationStoreHeader && <div dangerouslySetInnerHTML={{__html:props.Settings.text.RegistrationStoreHeader}} />}
            <div className="flex-evenly center wrap">{mappedProducts}</div>
          </>
        }
        
        <hr />
      </div>


      {shoppingCart && shoppingCart.length > 0 && fullProductsResponse ? (
        <>
          <ShoppingCart
            cartContents={shoppingCart}
            cartCount={cartCount}
            couponsUsed={couponsUsed}
            fullProductResponse={fullProductsResponse}
            handleProductsResponse={handleProductsResponse}
            userId={props.match.params.userId ?? null}
            newUsersContinue={GoToNextStepForNewUsers}
            orderId={props.match.params.invoice}
          />
          <hr />
        </>
      ) : inventory && inventory.length > 0 ? (
        <>
          <div className="alert alert-info">
            <h4>
              <Icon type={IconType.shoppingCart} /> Cart Empty
            </h4>
            Select an item to add it to your cart.
          </div>
        </>
      ) : (
        <></>
      )}

      {fullProductsResponse?.NewRecord &&
        shoppingCart &&
        fullProductsResponse.total === 0 && (
          <>
            <button
              className="btn btn-success save-and-continue"
              onClick={() => handleContinue(false)}
            >
              <Icon type={IconType.save} /> {fullProductsResponse.continueBtnText ?? "Continue." }
            </button>
          </>
        )}
      {productModalOpen && chosenProduct && (
        <Modal title={" "} setModalOpen={handleProductClose} size="l"> 
          <ProductInModal
            product={chosenProduct}
            handleProductClose={handleProductClose}
            handleProductsResponse={handleProductsResponse}
            userId={props.match.params.userId ?? null}
            orderId={props.match.params.invoice}
          />
        </Modal>
      )}
      <br />
      <br />
      
      {fullProductsResponse?.footerText && <div dangerouslySetInnerHTML={{__html:fullProductsResponse.footerText}}/>}
    </div>
  );
};

export default ReduxMap(withRouter(StoreFront));
