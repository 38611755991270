import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore.ts";
import { toast } from "react-toastify";

const EditAssignment = (props) => {
  const {
    projects,
    judges,
    projectsByKey,
    assignments,
    round,
    selectedAssignment,
    setAssignments,
    removeAssignment,
  } = props;
  const [selectedProjectId, setSelectedProjectId] = useState(
    selectedAssignment.ProjectId
  );
  const [selectedJudgeId, setSelectedJudgeId] = useState(
    selectedAssignment.JudgeId
  );

  const [date, setDate] = useState(selectedAssignment.Date);
  const [time, setTime] = useState(selectedAssignment.Time);

  const mappedProjectOptions = projects.map((project) => {
    return (
      <option key={project.ProjectIntId} value={project.ProjectIntId}>
        {project.ProjectId} {project.Title}
      </option>
    );
  });

  const mappedJudgeOptions = judges.map((Judge) => {
    return (
      <option key={Judge.Id} value={Judge.Id}>
        {Judge.FirstName} {Judge.LastName} {Judge.Veteran && "Veteran"}
      </option>
    );
  });

  const handleSave = () => {
    let request = {
      id: selectedAssignment.Id,
      projectId: selectedProjectId,// || selectedAssignment.ProjectId,
      projectKey: projectsByKey[selectedProjectId].ProjectKey,
      judgeId: selectedJudgeId,// || selectedAssignment.JudgeId,
      roundId: round.Id,
      date,
      time,
    };
    let callback = (data) => {
      toast.success(`Assignment updated.`);
      setAssignments([...props.assignments.filter(x=>{return x.Id !== selectedAssignment.Id}), data.assignment]);

      // props.setModalOpen(false);
    };
    store.server
      .postApi("../JudgingManagement/Assign", request)
      .then((res) => {
        console.log(res);
        callback(res.Value);
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      {true && (
        <div>
          <div className="">
            <div className="form-horizontal">
              <div className="form-group">
                <label
                  className="control-label col-sm-2"
                  htmlFor="assignment-project"
                >
                  Project
                </label>
                <div className="col-sm-6">
                  <select
                    className="form-control"
                    onChange={(e) => setSelectedProjectId(e.target.value)}
                    value={selectedProjectId}
                  >
                    <option value="0">select...</option>
                    {mappedProjectOptions}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label
                  className="control-label col-sm-2"
                  htmlFor="assignment-judge"
                >
                  Judge
                </label>
                <div className="col-sm-6">
                  <select
                    className="form-control"
                    onChange={(e) => setSelectedJudgeId(e.target.value)}
                    value={selectedJudgeId}
                  >
                    <option value="0">select...</option>
                    {mappedJudgeOptions}
                  </select>
                </div>
              </div>
              {round.Values?.SchedulesInterviews && (
                <div className="form-group">
                  <label
                    className="control-label col-sm-2"
                    htmlFor="round-edit-name"
                  >
                    At
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="date"
                      placeholder='mm/dd/yyyy'
                      className="form-control wider-input-d"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="time"
                      placeholder='--:-- --'
                      className="form-control wider-input-t"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSave}
            >
              <i className="fad fa-edit"></i>Update Assignment
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default EditAssignment;
