import { toast } from 'react-toastify';
import { IBtnProps } from './CoreTypes';
import Icon, { IconType } from '../Icon/Icon';

const Button = (props: IBtnProps) => {
  const getIcon = () => {
    if (!props.type) return <></>;

    switch (props.type) {
      case 'cancel':
        return <Icon type={IconType.ban} />;
      case 'close':
        return <Icon type={IconType.close} />;
      case 'delete':
        return <Icon type={IconType.remove} />;
      case 'open':
        return <Icon type={IconType.signIn} />;
      case 'save':
        return <Icon type={IconType.save} />;
      case 'new':
        return <Icon type={IconType.new} />;
      case 'edit':
        return <Icon type={IconType.edit} />;
        case 'edit-dots':
          return <Icon type={IconType.dots} />;
      case 'refresh':
        return <Icon type={IconType.refresh} />;
        case 'login':
          return <Icon type={IconType.signIn} />;
          case 'register':
            return <Icon type={IconType.userPlus} />;
    }
    return <></>;
  };

  const getIconOnlyStyle = () => {
    let styles = ['text'];

    switch (props.type) {
      case 'cancel':
        styles.push('text-default');
        break;
      case 'close':
        styles.push('text-default');
        break;
      case 'delete':
        styles.push('text-danger');
        break;
      case 'open':
        styles.push('text-info');
        break;
      case 'save':
      case 'login':
        styles.push('text-secondary');
        break;
      case 'new':
        styles.push('text-success');
        break;
      case 'edit':
      case 'edit-dots':
      case 'register':
        styles.push('text-default');
        break;
      case 'refresh':
        styles.push('text-default');
        break;
    }

    if (props.extraSmall) styles.push('text-xs');

    if (props.addClass) styles.push(props.addClass);
    return styles.join(' ');
  };

  const getStyle = () => {
    let styles = ['btn'];

    switch (props.type) {
      case 'cancel':
        styles.push('btn-default');
        break;
      case 'close':
        styles.push('btn-default');
        break;
      case 'delete':
        styles.push('btn-danger');
        break;
      case 'open':
        styles.push('btn-info');
        break;
      case 'save':
        case 'login':
        styles.push('btn-secondary');
        break;
      case 'new':
        styles.push('btn-success');
        break;
      case 'edit':
      case 'edit-dots':
      case 'register':
        styles.push('btn-default');
      
        break;
    }

    if (props.extraSmall) styles.push('btn-xs');

    if (props.addClass) styles.push(props.addClass);
    return styles.join(' ');
  };

  const onClick = () => {
    if (!props.onClick) {
      toast.dark('do something special!');
    } else {
      props.onClick();
    }
  };

  return (
    <>
      {props.iconOnly ? (
        <span className={`icon-btn ${getIconOnlyStyle()}`} onClick={onClick}>
          {getIcon()}
          {props.text && <>{props.text}</>}
        </span>
      ) : (
        <button type={'button'} className={getStyle()} onClick={onClick}>
          {getIcon()}
          {props.text && <>{props.text}</>}
        </button>
      )}
    </>
  );
};

export default Button;
