import { useEffect, useState } from "react";
import redux, { IState } from "../../redux/redux";
import Icon, { IconType } from "../Icon/Icon";
import UserLogin from "../UserLogin/UserLogin";
import Button from "../_Core/Button";


interface IProps {
    state: IState;
    type:string;
}

interface IWidgetData {

}


const Widget = (props:IProps) =>{
    const [data, setData] = useState<IWidgetData>();
    const [profileImageFailedToLoad, setProfileImageFailedToLoad] = useState<boolean>();

    useEffect(()=>{
        init();
    },[]);

    const init = ()=>{
        console.log("Widget Init Called...", props.type);
    };

    const buildWidget = ()=>{
        switch(props.type.toUpperCase()){
            case "ACCOUNT":
                return buildAccountWidget();
            default: 
                return <div className="widget text-center">
                    <h3>Unknown Widget "{props.type}"</h3>
                </div>
        }
    };

    const buildAccountWidget = ()=>{
        if(props.state.User?.signedIn){
            let user = props.state.User;
            console.log(user);
            return <div className="widget widget-account">
                <div className="widget-header">
                    <div className="widget-profile-img">
                        {user.ProfileLink && !profileImageFailedToLoad ? <>
                            <img src={user.ProfileLink} alt="Profile Image" onError={(x)=>{
                                setProfileImageFailedToLoad(true);
                            }}/>
                        </>
                        :<>
                        <Icon type={IconType.userProfileImage} />
                        </>}
                    </div>
                    <div>
                        <h4>{user.firstName} {user.lastName}</h4>
                        {user.email}

                    </div>
                </div>
            </div>

        } else {
            //Create Account / Sign in
            return <div className="widget">
                <h3><Icon type={IconType.user} addClass="text-muted" /> Account Info</h3>
                <Button type="login" addClass="btn-block" text="Login" onClick={()=>{redux.showLogin(true)}} />
                <Button type="register" addClass="btn-block" text="Register" />
            </div>
        }
    };


    return <div className="widget-container">
        {buildWidget()}
    </div>
};

export default Widget;