import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { toast } from "react-toastify";
import { IEntryType } from "../../ObjectTypes/SetupTypes";
import StoreX, { store } from "../../redux/oldStore";
import { ProjectSummary } from "../../utils/CommonTypes/ProjectSummaryTypes";
import { ServerResponse } from "../../utils/Server";
import FormFromApiData from "../FormFromApiData/FormFromApiData";
import Icon, { IconType } from "../Icon/Icon";
import Tools from "../JudgingDashboard/Tools";
import { IState, ReduxMap } from "../../redux/redux";

interface UserProjectDisplayProps extends IState, RouteComponentProps<MatchParams> {
  AlwaysShow: boolean;
  UserId: string | undefined;
}

interface MatchParams {
  userId: string;
  targetId?: string;
  entryType?: string;
}

const UserProjectDisplay = (props: UserProjectDisplayProps) => {
  const [info, setInfo] = useState<IPersonsProjectResponse>();
  const [projects, setProjects] = useState<ProjectSummary[]>([]);
  const [chosenProject, setChosenProject] = useState<ProjectSummary>();
  const [teamKey, setTeamKey] = useState("");

  useEffect(() => {
    if (
      (props.match.params.userId && !props.match.params.targetId) ||
      props.AlwaysShow
    ) {
      getInfo();
      
    }
  }, [props.match.params]);

  const getInfo = async () => {
    store.server
      .postApi<ServerResponse<IPersonsProjectResponse>>("../Person/Projects", {
        userId: props.UserId ?? props.match.params.userId,
      })
      .then((res) => {
        if (res.Success) {

          let projects = res.Value.Projects;
          if(res.Value.UsesEntryTypes && res.Value.EntryTypes && res.Value.EntryTypes.length >0){
            res.Value.EntryTypes.forEach(e=>{
              e.NumberOfProjects = projects.filter(f=>f.EntryTypePublicId === e.PublicId).length;
            });
          }

          setInfo(res.Value);
          setProjects(res.Value.Projects);
          if (res.Value && res.Value.Projects && res.Value.Projects.length > 0 && !props.AlwaysShow) {

            selectProject(res.Value.Projects[0]);
          }
          if (
            res.Value &&
            res.Value.Projects.length == 0 &&
            !res.Value.UsesEntryTypes &&
            !res.Value.TeamProjectsAllowed
          ) {
            NewProjectClick();
            return;
          }
          let teamKey = StoreX.GetQueryStringValue("team");
          if(StoreX.GetQueryStringValue("team")){
            let project = res.Value.Projects.find(x=>x.Id === teamKey)
            if(project){
              selectProject(project);
            }            
          }


        } else {
          toast.error(res.Message);
        }
      });
  };

  const selectProject = (p: ProjectSummary) => {
    if(!p) return;
    
    if (info?.AllowMultipleEntries) {
      let url = `/app/project/${props.UserId ?? props.match.params.userId}/${
        p.Id
      }`;
      window.location.pathname = url;
    } else {
      props.history.push(
        `/app/project/${props.match.params.userId}/${p.Id}/${props.location.search}`
      );
    }
  };

  const removeProject = (p: ProjectSummary) => {
    if(!p) return;
    if(!window.confirm(`Are you sure you want to remove this entry? This cannot be undone.`)){
      return;
    }

    
    store.server
      .postApi<ServerResponse<IPersonsProjectResponse>>("../Person/RemoveEntry", {
        personId: props.UserId ?? props.match.params.userId, projectKey:p.Id,
      })
      .then((res) => {
        if (res.Success) {
          getInfo();
        } else {
          toast.error(res.Message);
        }
      });
    
  };

  useEffect(() => {
    if (projects?.length === 1 && !props.AlwaysShow) {
      setChosenProject(projects[0]);
    }
  }, [projects]);

  const entryStatus = (p: ProjectSummary) => {
    switch (p.Status) {
      case 'Approved':
        return <span className="entry-status">{props.Settings?.text.EntryStatusApproved}</span>;

      case 'Dropped':
        return <span className="entry-status">{props.Settings?.text.EntryStatusDropped}</span>;
      case 'DoNotAdvance':
        return (
          <span className="entry-status" title={'Approved, Do not advance'}>
            {props.Settings?.text.EntryStatusDNA}
          </span>
        );
      case 'Pending':
      default:
        return <></>;
    }
  };
  
  const entryStatusClass = (p: ProjectSummary) => {
    switch (p.Status) {
      case 'Approved':
        return 'entry-status-approved';
      case 'Dropped':
        return 'entry-status-dropped';
      case 'DoNotAdvance':
        return 'entry-status-dna';
      case 'Pending':
      default:
        return '';
    }
  };

  // TODO:
  // most clients will only have 1 project enabled
  // if < 1 project, create a project.
  // if === 1 project, just display the project information
  // if > 1 project, display a project selector
  const mappedProjects = projects?.map((p, i) => {
    let hasProjectId = p.ProjectId ? true: false;
    return (
      <div className={`entry-button ${entryStatusClass(p)} ${p.IsNew ? "new" : ""}`} key={i}>
        {entryStatus(p)}
        <h3>
          <Icon type={IconType.project} />{" "}
          {hasProjectId ? (
            <>{p.ProjectId}</>
          ) : p.Title ? (
            <>{p.Title}</>
          ) : (
            <>Untitled Project</>
          )}
        </h3>
        <div className="small">{p.Category}</div>
        <hr />
        {hasProjectId && <><div>{p.Title}</div>
        <hr /></>}
        <div className="flex-between">
          <span>{p.EntryTypeName}</span>
        <div className="">
          {info?.AllowMultipleEntries && info?.EntriesCanBeRemoved && <button
          type="button"
            className="btn btn-default"
            onClick={() => {
              removeProject(p);
            }}
          >
            <Icon type={IconType.trashO} /> Remove
          </button>}
          <button
          type="button"
            className="btn btn-secondary"
            onClick={() => {
              selectProject(p);
            }}
          >
            <Icon type={IconType.projectNew} /> Select
          </button>
        </div>
        </div>
      </div>
    );
  });

  const handleLinkTeamProjectClick = () => {
    let submitObj = {
      personId: props.UserId ?? props.match.params.userId,
      projectId: teamKey,
      addToUser: info?.AllowMultipleEntries,
    };

    if (!submitObj.projectId) {
      toast.warning(
        `The ${StoreX.Text.LabelProjectKey} you provided is not valid.`
      );
      return;
    }

    store.server
      .postApi<ServerResponse<any>>("../Person/LinkProject", submitObj)
      .then((res) => {
        if (res.Success) {
          toast.success("Linked!");
          getInfo();
          if (!info?.AllowMultipleEntries || true) {
            props.history.push(
              `/app/project/${props.UserId ?? props.match.params.userId}/${
                submitObj.projectId
              }/${props.location.search}`
            );
          }
        } else {
          console.log(res);
          toast.error(res.Message, { autoClose: false });
        }
      });
  };

  const NewProjectClick = () => {
    if (info?.AllowMultipleEntries) {
      let url = `/app/project/${
        props.UserId ?? props.match.params.userId
      }/${StoreX.NewGuid()}`;
      window.location.pathname = url;
    } else {
      props.history.push(
        `/app/project/${props.match.params.userId}/${StoreX.NewGuid()}/${
          props.location.search
        }`
      );
    }
  };

  const NewProjectOfTypeClick = (type:IEntryType)=>{
    if(type.LimitOfEntriesPerUser && (type.NumberOfProjects ?? 0) >= type.LimitOfEntriesPerUser){
      toast.warning(`You already have the max number of these entry types.`);
      return;
    }

    let url = `/app/project/${
      props.UserId ?? props.match.params.userId
    }/${StoreX.NewGuid()}/${type.PublicId}`;
    window.location.pathname = url;
  };

  return (
    <div className="bumper-l">
      {(!props.match.params.targetId || props.AlwaysShow) && (
        <>
          {projects && projects.length > 0 && (
            <div>
              <h3>
                <Icon type={IconType.project} /> {info?.TextMyEntriesHeader}
              </h3>

              <div className="flex-evenly wrap">{mappedProjects}</div>

              {chosenProject && <></>}
            </div>
          )}
          <div className="">
            <h3>
              <Icon type={IconType.project} /> {info?.TextNewEntryHeader}
            </h3>
            {info?.TeamProjectsAllowed ? 
            <span>To start or link to an entry click an option below.</span>
            :
            <span>To start an entry click an option below.</span>
            }
            
            <div className="flex-evenly wrap">
              {!info?.UsesEntryTypes && (
                <div
                  className="entry-button"
                  title="click to start a new entry"
                >
                  <h3>
                    <Icon type={IconType.projectNew} /> Start a {info?.TextNewEntryHeader}
                  </h3>
                  <hr />
                  <div className="max-height-150">
                  Choose this option if you are working alone or you are the
                  first member of your team to register.
                  </div>
                  <hr />
                  <div className="text-right">
                    <button
                      className="btn btn-default new-entry-btn"
                      onClick={NewProjectClick}
                    >
                      <Icon type={IconType.projectNew} /> Start
                    </button>
                  </div>
                </div>
              )}
              {info && info.UsesEntryTypes && <>
              {info.EntryTypes.map((x,i)=>{
                return (
                  <div
                    className="entry-button"
                    title={`click to start a new "${x.Name}" entry`}
                    key={`entry-type-panel-${i}`}
                  >
                    <h3>
                      <Icon type={IconType.projectNew} /> {x.Name}
                    </h3>
                    <hr />
                    <div className="max-height-150" dangerouslySetInnerHTML={{__html:x.Description ?? ''}}></div>
                    <hr />
                    <div className="flex-between">
                      <div>
                        {x.LimitOfEntriesPerUser && x.LimitOfEntriesPerUser > 0 && <span>{x.NumberOfProjects} of {x.LimitOfEntriesPerUser}</span>} 
                      </div>
                    <div className="">
                      <button
                        className={`btn btn-default new-entry-btn type-${x.Id}`}
                        onClick={()=>{NewProjectOfTypeClick(x)}}
                      >
                        <Icon type={IconType.projectNew} /> Start
                      </button>
                    </div>

                    </div>
                  </div>);
              })}
              </>}
              {info?.TeamProjectsAllowed && <div
                className="entry-button"
                title="click to link to an existing entry"
              >
                <h3>
                  <Icon type={IconType.link} /> {info?.TextLinkToExistingEntryHeader}
                </h3>
                <hr />
                <div className="max-height-150">
                Choose this option if you are working with a team and they have
                already registered.
                </div>
                <hr />
                <div className="form-inline text-right">
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-addon">Project Key</div>
                      <input
                        type="text"
                        name="team-project-link"
                        id="team-project-link"
                        className="form-control"
                        placeholder={StoreX.Text.LabelProjectKey}
                        value={teamKey}
                        onChange={(e) => setTeamKey(e.target.value)}
                      />
                    </div>
                    <button
                      className="btn btn-default"
                      onClick={handleLinkTeamProjectClick}
                    >
                      <Icon type={IconType.link} /> Link
                    </button>
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </>
      )}
      <div>
        {info?.AllowMultipleEntries}
        {props.match.params.targetId && !props.AlwaysShow && (
          <FormFromApiData
            getInfoRoute={"../Project/MainForm"}
            saveRoute={"../Project/SaveForm"}
            idCalledInApi="projectId"
            isNew={false} //this is the default value...
          />
        )}
      </div>
    </div>
  );
};

export default ReduxMap(withRouter(UserProjectDisplay));

interface IPersonsProjectResponse {
  Projects: ProjectSummary[];
  InformationFrozen: boolean;
  AllowMultipleEntries: boolean;
  EntriesCanBeRemoved:boolean;
  TeamProjectsAllowed: boolean;
  UsesEntryTypes: boolean;
  EntryTypes: IEntryType[];
  TextLinkToExistingEntryHeader:string;
  TextMyEntriesHeader:string;
  TextNewEntryHeader:string;
}
