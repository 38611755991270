import React, { useState, useEffect } from 'react'
import Select from 'react-select';




const JudgingRoundEdit = (props) => {
  const { fullRubricInfo, round } = props
  const [roundName, setRoundName] = useState(props.round ? props.round.Name : '')
  const [roundValue, setRoundValue] = useState(props.round ? props.round.RoundValue : '')
  const [roundIsSubround, setRoundIsSubround] = useState(props.round ? props.round.IsSubround : false)
  const [roundValues, setRoundValues] = useState(props.round ? props.round.Values : {})
  const [useRubic, setUseRubic] = useState(props.round ? props.round.UseRubic : null)
  const [categories, setCategories] = useState([])
  const [divisions, setDivisions] = useState([])
  const [prevRounds, setPrevRounds] = useState([]);
  const [timesToJudge, setTimesToJudge] = useState(round?.Values?.TimesToJudgeProject || 3);
  const [tags, setTags] = useState([])
  const [judgeTags, setJudgeTags] = useState([])
  const [whichMinScore, setWhichMinScore] = useState('')
  const [score, setScore] = useState();
  const [ApprovedForJudging, setApprovedForJudging] = useState(round?.Values.ApprovedForJudging ?? false);
  const [ApplyFiltersToOnlineJudging, setApplyFiltersToOnlineJudging] = useState(round?.Values.ApplyFiltersToOnlineJudging ?? false);
  const handleChange = (input, value) => {
    setRoundValues({ ...roundValues, [input]: value })
  };



  const saveThis = () => {

    let formattedRoundValues = {
      ...roundValues,
      FilterCategoryIds: categories?.map(x => x.value),
      FilterDivisionIds: divisions?.map(x => x.value),
      FilterRoundIds: prevRounds?.map(x => x.value),
      FilterTagIds: tags?.map(x => x.value),
      FilterJudgeTagIds: judgeTags?.map( x => x.value),
      FilterMinAvgScore: null,
      FilterMinAvgZScore: null,
      TimesToJudgeProject: timesToJudge,
      ApprovedForJudging: ApprovedForJudging,
      ApplyFiltersToOnlineJudging: ApplyFiltersToOnlineJudging,
    }

    if (whichMinScore) formattedRoundValues[whichMinScore] = score;
      
    let rd = {
      Name: roundName
      , RoundValue: roundValue
      , IsSubround: roundIsSubround
      , useRubic
      , Id: props.round ? props.round.Id : 0
      , Values: formattedRoundValues
    };
console.log(rd);
    props.roundEditSave(rd, props.index);
    props.setModalOpen(false);
  };

  const deleteThis = () => {
    let rd = {
      Name: roundName
      , RoundValue: roundValue
      , IsSubround: roundIsSubround
      , Id: props.round.Id
      , Values: roundValues
    };

    props.roundEditRemove(rd, props.index);
    props.setModalOpen(false);
  };

  const mappedRubricOptions = fullRubricInfo.rubrics?.map( (r, i) => {
    return (
      <option value={r.Id} key={`${r.Id}${i}`}>{r.Name}</option>
    )
  });

  const mappedCategories = props.roundCategories.map((cat) => {
    return {
      value: cat.id,
      label: cat.name
    }
  });

  const mappedDivisions = props.roundDivisions.map((div) => {
    return {
      value: div.id,
      label: div.name
    }
  });

  const mappedTags = props.roundTags.map( (tag) => {
    return {
      value: tag.id,
      label: tag.name
    }
  });

  const mappedJudgeTags = props.judgeTags.map( (tag) => {
    return {
      value: tag.id,
      label: tag.name
    }
  });

  const mappedRounds = props.allRounds.filter(x => x.Id !== props.round?.Id).map((round) => {
    return {
      value: round.Id,
      label: round.Name
    }
  });

  useEffect(() => { 
    if (props.round?.Values){
      let val = props.round.Values
      // if (val.useRubic) setUseRubic(val.useRubic)
      if (val.FilterCategoryIds?.length > 0) setCategories(mappedCategories.filter( cat => val.FilterCategoryIds.includes(cat.value)));
      if (val.FilterDivisionIds?.length > 0) setDivisions(mappedDivisions.filter( div => val.FilterDivisionIds.includes(div.value)));
      if (val.FilterRoundIds?.length > 0) setPrevRounds(mappedRounds.filter( rd => val.FilterRoundIds.includes(rd.value)));
      if (val.FilterTagIds?.length > 0) setTags(mappedTags.filter( rd => val.FilterTagIds.includes(rd.value)));
      if (val.FilterJudgeTagIds?.length > 0) setJudgeTags(mappedJudgeTags.filter( rd => val.FilterJudgeTagIds.includes(rd.value)));
      if (val.FilterMinAvgScore) {
        setWhichMinScore('FilterMinAvgScore');
        setScore(val.FilterMinAvgScore);
      }
      if (val.FilterMinAvgZScore) {
        setWhichMinScore('FilterMinAvgZScore');
        setScore(val.FilterMinAvgZScore);
      }
    }
  }, [props.round?.Values]);

  const rubricOnChange = (e) => {
    setUseRubic(+e.target.value)
    handleChange("UseRubric", +e.target.value);
  }


  return (
    <div>
      <div className="">
        <div className="form-horizontal">
          <div className="form-group">
            <label
              className="control-label col-sm-4"
              htmlFor="round-edit-name"
            >
              Name
            </label>
            <div className="col-sm-6">
              <input
                className="form-control"
                id="round-edit-name"
                onChange={e => setRoundName(e.target.value)}
                value={roundName}
              />
            </div>
          </div>
          <div className="form-group">
            <label
              className="control-label col-sm-4"
              htmlFor="round-edit-value"
            >
              Value
            </label>
            <div className="col-sm-4">
              <input
                className="form-control"
                type="number"
                step="5"
                max="100"
                min="0"
                id="round-edit-value"
                onChange={e => setRoundValue(e.target.value)}
                value={roundValue}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="col-sm-6 col-sm-offset-4">
              <input
                type="checkbox"
                id="round-edit-is-sub"
                onChange={() => setRoundIsSubround(!roundIsSubround)}
                defaultChecked={roundIsSubround}
              />
              <label
                className="control-label"
                htmlFor="round-edit-is-sub"
              >
                This round is a sub-round.
              </label>
            </div>
          </div>
                    
          <div className="form-group">
            <label
              className="control-label col-sm-4"
              htmlFor="round-edit-default-rubric"
            >
              Default Rubric
            </label>
            <div className="col-sm-6">
              <select
                className="form-control"
                // type="text"
                id="round-edit-default-rubric"
                onChange={e => rubricOnChange(e)}
                value={useRubic}
              >
                <option value={null}> </option>
                {mappedRubricOptions}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label
              className="control-label col-sm-4"
              htmlFor="round-edit-times-to-judge-project"
            >
              Times to Judge Each Project
            </label>
            <div className="col-sm-3">
              <input
                className="form-control"
                type="number"
                step="1"
                max="99"
                min="1"
                id="round-edit-times-to-judge-project"
                onChange={e => setTimesToJudge(e.target.value)}
                value={timesToJudge}
              />
            </div>
          </div>
          <h3>Scheduling</h3>
          <div className="form-group">
            <div className="col-sm-6 col-sm-offset-4">
              <input
                type="checkbox"
                id="round-edit-is-schedules"
                onChange={e => handleChange("SchedulesInterviews", e.target.checked)}
                defaultChecked={roundValues.SchedulesInterviews === true || roundValues.SchedulesInterviews === 'true'}
              />
              <label
                className="control-label"
                htmlFor="round-edit-is-schedules"
              >
                Interviews are scheduled.
              </label>
            </div>
          </div>
          <div className="form-group">
            <label
              className="control-label col-sm-4"
              htmlFor="round-edit-interview-length"
            >
              Interview Minutes
            </label>
            <div className="col-sm-3">
              <input
                className="form-control"
                type="number"
                step="1"
                max="100"
                min="0"
                id="round-edit-interview-length"
                onChange={e => handleChange("InterviewLengthMinutes", e.target.value)}
                value={roundValues.InterviewLengthMinutes}
              />
            </div>
          </div>
          <div className="form-group">
            <label
              className="control-label col-sm-4"
              htmlFor="round-edit-interview-buffer-length"
            >
              Interview Buffer
            </label>
            <div className="col-sm-3">
              <input
                className="form-control"
                type="number"
                step="1"
                max="100"
                min="0"
                id="round-edit-interview-buffer-length"
                onChange={e => handleChange("InterviewBufferMinutes", e.target.value)}
                value={roundValues.InterviewBufferMinutes}
              />
            </div>
          </div>
          <div className="form-group">
            <label
              className="control-label col-sm-4"
              htmlFor="round-edit-interview-lock-after"
            >
              Lock Scores After
            </label>
            <div className="col-sm-6">
            <select                
                className="form-control"
                // type="text"
                id="round-edit-interview-lock-after"
                onChange={e => handleChange("LockAfter",e.target.value)}
                value={roundValues.LockAfter || '0'}
                
              >
                <option value='0'>Don't lock (default)</option>
                <option value='1'>1 hour</option>
                <option value='2'>2 hours</option>
                <option value='3'>3 hours</option>
                <option value='4'>4 hours</option>
                <option value='5'>5 hours</option>
                <option value='6'>6 hours</option>
                <option value='7'>7 hours</option>
                <option value='8'>8 hours</option>
                <option value='9'>9 hours</option>
                <option value='10'>10 hours</option>
                <option value='11'>11 hours</option>
                <option value='12'>12 hours</option>
                <option value='18'>18 hours</option>
                <option value='24'>24 hours</option>
                <option value='36'>36 hours</option>
                <option value='48'>48 hours</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <label
              className="control-label col-sm-4"
              htmlFor="round-edit-judging-start-date"
            >
              Judging Starts/Opens
            </label>
            <div className="col-sm-4">
              <input
                className="form-control"
                type="date"
                placeholder='mm/dd/yyyy'
                id="round-edit-judging-start-date"
                onChange={e => handleChange("StartDate", e.target.value)}
                value={roundValues.StartDate}

              />
            </div>
            <div className="col-sm-4">
              <input
                className="form-control"
                type="time"
                placeholder='--:-- --'
                id="round-edit-judging-start-time"
                onChange={e => handleChange("StartTime", e.target.value)}
                value={roundValues.StartTime}

              />
            </div>
          </div>
          <div className="form-group">
            <label
              className="control-label col-sm-4"
              htmlFor="round-edit-judging-break"
            >
              Judging Break
            </label>
            <div className="col-sm-4">
              <input
                className="form-control"
                type="time"
                placeholder='--:-- --'
                id="round-edit-judging-break-start"
                onChange={e => handleChange("BreakStart1", e.target.value)}
                value={roundValues.BreakStart1}

              />
            </div>
            <div className="col-sm-4">
              <input
                className="form-control"
                type="time"
                placeholder='--:-- --'
                id="round-edit-judging-break-end"
                onChange={e => handleChange("BreakEnd1", e.target.value)}
                value={roundValues.BreakEnd1}

              />
            </div>
          </div>
          <div className="form-group">
            <label
              className="control-label col-sm-4"
              htmlFor="round-edit-judging-end-date"
            >
              Judging Ends/Closes
            </label>
            <div className="col-sm-4">
              <input
                className="form-control"
                type="date"
                placeholder='mm/dd/yyyy'
                id="round-edit-judging-end-date"
                onChange={e => handleChange("EndDate", e.target.value)}
                value={roundValues.EndDate}

              />
            </div>
            <div className="col-sm-4">
              <input
                className="form-control"
                type="time"
                placeholder='--:-- --'
                id="round-edit-judging-end-time"
                onChange={e => handleChange("EndTime", e.target.value)}
                value={roundValues.EndTime}

              />
            </div>
          </div>
          <h3>Filters</h3>
          
          {true && <div className="form-group">
            <div className="col-sm-6 col-sm-offset-4">
              <input
                type="checkbox"
                id="round-edit-is-judging-approved"
                onChange={() => setApplyFiltersToOnlineJudging(!ApplyFiltersToOnlineJudging)}
                defaultChecked={ApplyFiltersToOnlineJudging}
              />
              <label
                className="control-label"
                htmlFor="round-edit-is-judging-approved"
              >
                Apply filters to online judging.
              </label>
            </div>
          </div>}
          <h3>Judge Filters</h3>
          <div className='form-group'>
            <label
              className="control-label col-sm-4"
              htmlFor="round-edit-tags"
            >
              Judge Tags
            </label>
            <div className='col-sm-8'>
              <Select
                inputId="round-edit-tags"
                onChange={setJudgeTags}
                value={judgeTags}
                options={mappedJudgeTags}
                placeholder={'choose applicable tags'}
                className=' '
                isMulti
                isClearable={true}
                menuPlacement={'auto'}
              />

            </div>
          </div>
          <h3>Project Filters</h3>
          <p>these filter the projects that appear in the round</p>
          <div className='form-group'>
            <label
              className="control-label col-sm-4"
              htmlFor="round-edit-categories"
            >
              Categories
            </label>
            <div className='col-sm-8'>
              <Select
                inputId="round-edit-categories"
                onChange={setCategories}
                value={categories}
                options={mappedCategories}
                placeholder={'choose applicable categories'}
                className=' '
                isMulti
                isClearable={true}
                menuPlacement={'auto'}
              />

            </div>
          </div>
          <div className='form-group'>
            <label
              className="control-label col-sm-4"
              htmlFor="round-edit-divisions"
            >
              Divisions
            </label>
            <div className='col-sm-8'>
              <Select
                inputId="round-edit-divisions"
                onChange={setDivisions}
                value={divisions}
                options={mappedDivisions}
                placeholder={'choose applicable divisions'}
                className=' '
                isMulti
                isClearable={true}
                menuPlacement={'auto'}
              />

            </div>
          </div>
          <div className='form-group'>
            <label
              className="control-label col-sm-4"
              htmlFor="round-edit-tags"
            >
              Tags
            </label>
            <div className='col-sm-8'>
              <Select
                inputId="round-edit-tags"
                onChange={setTags}
                value={tags}
                options={mappedTags}
                placeholder={'choose applicable tags'}
                className=' '
                isMulti
                isClearable={true}
                menuPlacement={'auto'}
              />

            </div>
          </div>


          {props.useMarkReadyToJudge && <div className="form-group">
            <div className="col-sm-6 col-sm-offset-4">
              <input
                type="checkbox"
                id="round-edit-is-judging-approved"
                onChange={() => setApprovedForJudging(!ApprovedForJudging)}
                defaultChecked={ApprovedForJudging}
              />
              <label
                className="control-label"
                htmlFor="round-edit-is-judging-approved"
              >
                Only projects approved for judging.
              </label>
            </div>
          </div>}

          <div className='form-group'>
            <label
              className="control-label col-sm-4"
              htmlFor="round-edit-rounds"
            >
              Previous Round(s)
            </label>
            <div className='col-sm-8'>
              <Select
                inputId="round-edit-rounds"
                onChange={setPrevRounds}
                value={prevRounds}
                options={mappedRounds}
                placeholder={'choose applicable rounds'}
                className=' '
                isMulti
                isClearable={true}
                menuPlacement={'auto'}
              />

            </div>
          </div>
          <div className="form-group">
            <label
              className="control-label col-sm-4"
              htmlFor="round-edit-minimum-score"
            >
              Minimum Score
            </label>
            <div className='col-sm-3'>
              <select                
                className="form-control"
                // type="text"
                id="round-edit-minimum-score"
                onChange={e => setWhichMinScore(e.target.value)}
                value={whichMinScore}
              >
                <option value={''}>choose... </option>
                <option value='FilterMinAvgZScore'>Z-Score</option>
                <option value='FilterMinAvgScore'>Average Score</option>
              </select>
            </div>
            {whichMinScore === 'FilterMinAvgZScore'
              &&
              <div className="col-sm-3">
                <input
                  className="form-control"
                  type="number"
                  step="0.1"
                  max="3"
                  min="-3"
                  id="round-edit-minimum-average-z-score"
                  onChange={e => setScore(e.target.value)}
                  value={score}
                />
              </div>
            }
            {whichMinScore ==='FilterMinAvgScore'
              &&
              <div className="col-sm-3">
                <input
                  className="form-control"
                  type="number"
                  step="1"
                  max="100"
                  min="0"
                  id="round-edit-minimum-average-score"
                  onChange={e => setScore(e.target.value)}
                  value={score}
                />
              </div>
            }
            </div>
          </div>
        </div>
      <div className="modal-footer">
        {props.index > -1
          &&
          <button
            type="button"
            className="btn btn-warning delete-round-btn"
            onClick={deleteThis}
          >
            <i className="fa fa-trash-can"></i>
                Delete
              </button>
        }
        <button
          type="button"
          className="btn btn-primary save-round-changes-btn"
          onClick={saveThis}
        >
          <i className="fa fa-floppy-o"></i>
              Save changes
          </button>
      </div>
    </div >


  )
}

export default JudgingRoundEdit