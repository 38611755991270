import axios from "axios";
import redux from "../redux/redux";
import { NewGuid } from "./Tools";


export type ServerResponse<T> = {
  Success: boolean;
  LoginRequired: boolean;
  Message: string;
  GoTo:string;
  Value: T;
};

export class ServerCallOptions {
  loadingScreenShow: boolean = true;
  loadingScreenText: string = "loading";
}

export class Server {
  loadingScreen: boolean;
  isDev: boolean = false;

  static iframeReady: boolean = false;
  static preMessageQueue: any = [];
  static messageQueue: any = {};
  static iframe: any;
  static loaded: boolean = false;
  static messageId: number = 0;
  static queryStringAddParam: any = null;

  constructor(loadingScreen: any) {
    this.loadingScreen = loadingScreen;

    let isDev = (this.isDev = window.location.host.indexOf("localhost:") > -1);
    let params = new URLSearchParams(window.location.search);
    let fairId = params.get("f");
    if (fairId) {
      Server.queryStringAddParam = `f=${fairId}`;
    }

    if (isDev && !Server.loaded) {
      window.addEventListener("message", Server.iframeResponse, false);
      Server.loaded = true;
      if (!Server.iframe) {
        Server.iframe = document.getElementById("fms-shim");
      }
    }
  }

  public async getApi<T>(url: string): Promise<T> {
    if (this.loadingScreen) {
      redux.loading(true);
    }
    if (this.isDev) {
      let result = await Server.callParentShim<T>(false, this.makeUrl(url), null).catch(error => { throw error })
      if (this.loadingScreen) {
        redux.loading(false);
      }
      return result;
    }

    return await axios
      .get(this.makeUrl(url),{headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }})
      .then((x: any) => {
        if (this.loadingScreen) {
          redux.loading(false);
        }
        return x.data;
      })
      .catch((x: any) => {
        if (this.loadingScreen) {
          redux.loading(false);
        }
        //console.log(x);
      });
  }

  
  public async getApiQuiet<T>(url: string): Promise<T> {
    if (this.isDev) {
      let result = await Server.callParentShim<T>(false, url, null).catch(error => { throw error })
      if (this.loadingScreen) {
        redux.loading(false);
      }
      return result;
    }

    return await axios
      .get(this.makeUrl(url),{headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    }})
      .then((x: any) => {
        return x.data;
      })
      .catch((x: any) => {
        //console.log(x);
      });
  };


  public async postApi<T>(url: string, data: any, filesArray: File[] = []): Promise<T> {
    if (this.loadingScreen) {
      redux.loading(true);
    }
    if (this.isDev) {
      let result = await Server.callParentShim<T>(true, this.makeUrl(url), data, filesArray);
      //console.log('post api shim', result);
      if (this.loadingScreen) {
        redux.loading(false);
      }
      return result;
    }
    if (filesArray.length) {
      return await this.postWithFiles<T>(data, filesArray, url);
    } else {
      return await this.postNoFiles<T>(url, data);

    }
  }

  private async postWithFiles<T>(data: any, filesArray: File[], url: string) {
    //TODO:  This needs to be tested.  Not sure if it will work.  Do we need to set content type for each item in the multi part form data?
    const formData = new FormData();
    Object.entries<any>(data).forEach((entry) => {
      formData.append(entry[0], JSON.stringify(entry[1]));
    });
    
  
    filesArray.forEach(f => {
      formData.append('file', f, f.name);
    });
    
    console.log(formData);

    let result = await axios.post<T>(this.makeUrl(url), formData, { //Do we even need to set headers?
      headers:{"Content-Type": "multipart/form-data"},
      onUploadProgress:(event:ProgressEvent)=> {
        redux.uploadProgress(event);
        // let precent = event.loaded / event.total * 100.0;
        // console.log(precent);
        //   console.log(`${event.loaded} of ${event.total}`);
      },
    });
    
    if (this.loadingScreen) {
      redux.loading(false);
    }
    return result.data;
  }

  private async postNoFiles<T>(url: string, data: any) {
    let result = await axios
      .post<T>(this.makeUrl(url), data, {
        headers: { "Content-Type": "application/json; charset=utf-8" },
      })
      .catch((x) => {
        if (this.loadingScreen) {
          redux.loading(false);
        }
        console.log(x);
        throw x;
      });
    if (this.loadingScreen) {
      redux.loading(false);
    }
    return result.data;
  }

  
  public async postApiQuite<T>(url: string, data: any, filesArray: File[] = []): Promise<T> {
    if (this.isDev) {
      let result = await Server.callParentShim<T>(true, this.makeUrl(url), data, filesArray);
      return result;
    }
    if(filesArray && filesArray.length){
      let result = await this.postWithFiles<T>(data, filesArray, url);
      return result;
    }
    let result = await axios
      .post<T>(this.makeUrl(url), data, {
        headers: { "Content-Type": "application/json; charset=utf-8" },
      })
      .catch((x) => {
        console.log(x);
        throw x;
      });
    return result.data;
  }
  /**This overvariation of postApi handles server response errors.  If serverResponse.Success === false, it will throw an error. */
  public async postApiWithServerResponse<T>(url: string, data: any, filesArray: File[] = []): Promise<ServerResponse<T>> {
    let response = await this.postApi<ServerResponse<T>>(url, data, filesArray).catch(error => { throw (error); });
    if (!response.Success) {
      throw response.Message;
    }
    return response;
  }


  clearLoading() {
    redux.loading(false);
  }

  public static GetDevHost(){
    
    let params = new URLSearchParams(window.location.search);
    const host = params.get('host') ?? 'http://localhost:8081';
    return host;
  }

  public makeUrl(url: string): string {
    url = url.trim();
    if(url.toLocaleLowerCase().indexOf('http') === 0) return url;
    
    if (url.indexOf("/") === 0 || url.indexOf("\\") === 0) {
      url = url.substr(1);
    }

    if (this.isDev) {
      //return "https://national.zfairs.com/" + url;
      url = `${Server.GetDevHost()}/${url}`;

      if (Server.queryStringAddParam) {
        if (url.indexOf("?") > -1) {
          url += `&${Server.queryStringAddParam}`;
        } else {
          url += `?${Server.queryStringAddParam}`;
        }
      }

      return url;
    } else {
      url = `${window.location.origin}/${url}`;
      if (Server.queryStringAddParam) {
        if (url.indexOf("?") > -1) {
          url += `&${Server.queryStringAddParam}`;
        } else {
          url += `?${Server.queryStringAddParam}`;
        }
      }
    }

    return url;
  }

  private static async iframeResponse(event: any) {
    if (event.data.type !== "shim") {
      if (event.data.type === "shim-ready") {
        Server.iframeReady = true;
        //send all messages that where waiting...
        Server.preMessageQueue.forEach((call: any) => {
          call();
        });
        Server.preMessageQueue = [];
      }
      else if (event.data.type === "progress"){                
        let data = await event.data;
        let {loaded,total} = data.msg;
        redux.uploadProgress(data.msg);
      }

      return;
    }
    let { msg, id } = await event.data;
    let callback = Server.messageQueue[id];
    if (callback) callback(msg);
  }

  private static callParentShim<T>(post: boolean, url: string, data: any, filesArray: File[] = []): Promise<T> {
    let id = Server.messageId + url;
    Server.messageId++;
    id = NewGuid();

    let promiseExecutor = (resolve: any) => {
      let callback = (response: any) => {
        //console.log('callback response: ', response)
        resolve(response);
      };
      
      Server.messageQueue[id] = callback;
      if (!Server.iframe) {
        Server.iframe = document.getElementById("fms-shim");
      }
      let call = () => {
        try{
        Server.iframe.contentWindow.postMessage(
          {
            type: "shim",
            id,
            action: post ? "post" : "get",
            data,
            url: url,
            filesArray: filesArray
          },
          "*"
        );
        }catch(ex){
          console.log("DEV SHIM: ERROR: ", ex);
        }
      };

      if (Server.iframeReady) {
        call();
      } else {
        this.preMessageQueue.push(call);
      }
    };

    return new window.Promise(promiseExecutor);
  }
}
