import React, { useState, useEffect } from "react";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { store } from "../../../redux/oldStore.ts";
import { toast } from "react-toastify";
import "../../../react-draft-wysiwyg.css";

import FileDownload from 'js-file-download';

export default function LoadJudges(props) {
  const [fileText, setFileText] = useState(null);
  const [headersIncluded, setHeadersIncluded] = useState(false)
  const [loaded, setLoaded] = useState(false);
  const [parsingErrors, setParsingErrors] = useState([]);

  useEffect(() => {

  }, []);

  const judgeCSVFileSelected = (e) => {
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      let lines = [];
      const perrors = [];
      let formattedText = text.replaceAll('\r\n','\n').replaceAll('\r', '\n');
      const rawLines = formattedText.split("\n");
      rawLines.forEach((l, index) => {
        if(l.length > 0){const line = l.split(',');
        const columnsPerLine = 5;
        if (line.length === columnsPerLine) {
          //The line looks good
          lines.push(line);
        } else {
          const errorMessage = `Line ${index + 1} has ${line.length} columns, but should have ${columnsPerLine}`
          console.error(errorMessage);
          perrors.push(errorMessage);
        }}
      });
      if (perrors.length) {
        setParsingErrors(perrors);
      } else {
        setFileText(lines);
      }
    };
    reader.readAsText(file);
  };

  const load = (e) => {
    let realText = headersIncluded ? fileText.slice(1) : fileText;
    // bring in the schoolText somewhere
    store.server.postApi("../AcaDeca/loadJudges", { judgeData: realText }).then((x) => {
      if (x.Success) {
        toast.success("Your judges have been loaded.");
        setLoaded(true);
      } else {
        toast.error(x.Message);
      }
    }).catch(err => {
      console.log(err);
      toast.error(err);
    });
  };

  const downloadTemplateClick = () => {
    const items = [{
      'First Name': ''
      ,'Last Name': ''
      ,'Email': ''
      ,'Username': ''
      ,'Password': ''
    }]

    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    const header = Object.keys(items[0])
    const csv = [
      header.join(','), // header row first
      ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n')

    // downloady stuff here
    FileDownload(csv, 'judge_upload_template.csv');
  }

  return (
    <>
      <div className="loading-container">
        <h1>Loading Information</h1>
        <div>
          You can load information for your Academic Decathlon using a CSV file.
          You can save Excel and Google Doc files as CSV files.
          <ul>
            <li>
              <strong>Google Sheets</strong> Select File, then Download then
              as Comma-separated values (.csv)
            </li>
            <li>
              <strong>Excel</strong> Select File, Save as, then change type to
              (*.csv)
            </li>
          </ul>
        </div>
        <div>

          <h3>Load Judges</h3>

          If you don't want your judges to register, or have the register in another system. No worries you can upload them here. Fill out hte form below to be able to upload them.

          <h4>File Format</h4>
          <button className='btn btn-secondary btn-xs' onClick={downloadTemplateClick}>Download Template</button>
          <div>
            The Judges CSV file should include the following columns in the following
            order.
            <ul>
            <li>First Name</li>
            <li>Last Name</li>
            <li>Email</li>
            <li>Username</li>
            <li>Password</li>
            </ul>

          </div>
          {!loaded && <div className="">
            <div className='bumper-l'>

            <label className=''>Judges CSV File:</label>
              <input
                type="file"
                accept=".csv"

                onChange={judgeCSVFileSelected}
                onClick={(evt) => evt.target.value = null}
              // Set target to null so the onchange event will fire again even if the user picks the same file.
              />
            </div>
            <div className='flex bumper-l'>
              <label htmlFor='headers'>Does the file include headers?</label>
              <input
                type='checkbox'
                name="headers"
                id='headers'
                className='form-input bumper-sides-out-l'
                onChange={e => setHeadersIncluded(e.target.checked)}
                defaultChecked={headersIncluded}
              />
            </div>


            {fileText && fileText.length > 0 && (
              <div className="csv-file-preview">
                <table>
                  {!headersIncluded
                    &&
                    <thead>

                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Username</th>
                        <th>Password</th>
                      </tr>
                    </thead>

                  }
                  <tbody>

                    {fileText.map((r, ri) => {
                      return (
                        <tr key={'r' + ri}>
                          {r.map((v, vi) => {
                            if (headersIncluded && ri === 0) {
                              return <th key={ri + '-' + vi}>{v}</th>;
                            }
                            return <td key={ri + '-' + vi}>{v}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* <h4>If the above file content looks good. Press Load</h4>
                <button type="button" className="button" onClick={load}>
                  Load
                </button> */}
              </div>
            )}
            {parsingErrors.length > 0 &&
              <table>
                {parsingErrors.map((error, index) => (
                  <tr key={index}>
                    <td>{error}</td>
                  </tr>
                ))}
              </table>
            }
          </div>}
          {!loaded && (fileText && fileText.length > 0) 
            && (
              <div className='ready-to-load-area'>
                <h4>If the Judges CSV file contents look good, press Load</h4>
                <button type="button" className="btn btn-success" onClick={load}>
                  <i className="fad fa-cloud-upload"></i>
                    Load
                  </button>
              </div>
            )}
          {loaded && <div>
            <h1>Judges loaded!</h1>
            If you need to load more judges refresh your page.
            </div>}
        </div>






        {/* <div>
          <h3>Load School</h3>
          <h4>File Format</h4>
          <div>
            The School CSV file should include the following columns in the following
            order.
            <ul>
              <li>Team Name</li>
              <li>School</li>
              <li>First Name of Principal/Contact</li>
              <li>Last Name of someone</li>
              <li>name of school district/county?</li>
              <li>Number (6?)</li>
            </ul>
          </div>
          {!loaded && <div className="">
            <h4>School File</h4>
            <input
              type="file"
              accept=".csv"
              onChange={schoolFileSelected}
              onClick={(evt) => evt.target.value = null}
            // Set target to null so the onchange event will fire again even if the user picks the same file.
            />

            {schoolText && schoolText.length > 0 && (
              <div className="csv-file-preview">
                <table>
                  <tr>
                    <th>Team Name</th>
                    <th>School</th>
                    <th>First Name (of Principal?)</th>
                    <th>Last Name (of Principal?)</th>
                    <th>School District/County</th>
                    <th>Number</th>
                  </tr>
                  {schoolText.map((r, ri) => {
                    return (
                      <tr key={'r' + ri}>
                        {r.map((v, vi) => {
                          // if (ri === 0) {
                          //   return <th key={ri + '-' + vi}>{v}</thead>;
                          // }
                          return <td key={ri + '-' + vi}>{v}</td>;
                        })}
                      </tr>
                    );
                  })}
                </table>

              </div>
            )}
            {parsingSchoolErrors.length > 0 &&
              <table>
                {parsingSchoolErrors.map((error, index) => (
                  <tr key={index}>
                    <td>{error}</td>
                  </tr>
                ))}
              </table>
            }
          </div>}
          {loaded 
            && <div className='loaded-area'>
              <h1>School loaded!</h1>
              If you need to load more students refresh your page.
            </div>}
        </div> */}
      </div>
    </>
  );
}
