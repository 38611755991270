import { useEffect, useState } from 'react';
import { IState, ReduxMap } from '../../redux/redux';
import StoreX from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import { IFlexFlow } from './FlexFlowTypes';
import { toast } from 'react-toastify';
import Icon, { IconType } from '../Icon/Icon';
import { FormatDate } from '../../utils/Tools';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Button from '../_Core/Button';

interface IProps extends IState {}

interface IFlexDataRecords {
  flows: IFlexFlow[];
  records: IFlexDataRecord[];
  flowStats: IFlowStats[];
}

interface IFlowStats {
  FlowId: number;
  FlowPublicId: string;
  StatsAtUtc: string;
  NumberOfRecords: number;
  NumberOfPendingRecords: number;
  LastRecordStartedAtUtc: string;
  FirstRecordStartedAtUtc: string;
  LastRecordFinishedAtUtc: string;
  FirstRecordFinishedAtUtc: string;
  TicketsSold: number;
  AdditionalTicketsSold: number;
  PendingTickets: number;
  PendingAdditionalTickets: number;
  TotalTicketsSold: number;
}

interface IFlexDataRecord {
  FirstName: string;
  LastName: string;
  PublicId: string;
  Finished: string;
  CreatedAtUtc: string;
  FlowId: number;
  Values: IFlexDataRecordValues[];
}

interface IFlexDataRecordValues {
  RecordId: string;
  FieldId: number;
  SetAtUtc: string;
  Value: string;
}

const FlexViewer = (props: IProps) => {
  const [data, setData] = useState<IFlexDataRecords>();
  const [flowDic, setFlowDic] = useState<Record<number, IFlexFlow>>({});

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    StoreX.instance.server.getApi<ServerResponse<IFlexDataRecords>>(`../FlexData/FlexRecords`).then((x) => {
      if (x.Success) {
        let dic = {};
        x.Value.flows.forEach((f) => {
          dic[f.Id ?? 0] = f;
        });
        setFlowDic(dic);
        setData(x.Value);
        console.log(x);
      } else {
        toast.error(x.Message);
      }
    });
  };

  const removeRecord = (record:IFlexDataRecord)=>{
    if(!window.confirm(`${props.User?.firstName}, are you sure you want to delete this record?`)){
        return;
    }
    StoreX.instance.server.postApi<ServerResponse<boolean>>(`../FlexData/FlexRecordRemove`, record).then((x) => {
        if (x.Success) {
          init();
          toast.info('Record removed.');
        } else {
          toast.error(x.Message);
        }
      });
  };
  const markRecordAsNotFinished = (record:IFlexDataRecord)=>{
    StoreX.instance.server.postApi<ServerResponse<boolean>>(`../FlexData/FlexRecordNotFinished`, record).then((x) => {
        if (x.Success) {
          init();
          toast.info('Record marked in progress.');
        } else {
          toast.error(x.Message);
        }
      });
  };

  const getIcon = (record: IFlexDataRecord) => {
    if (record.Finished) {
      return <Icon type={IconType.done} addClass="text-success" />;
    }
    if (!record.Finished) {
      return <Icon type={IconType.pending} />;
    }
  };

  return (
    <>
      <div className="flex-between">
        <h3>Flex Records</h3>
        <div>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => {
              init();
            }}>
            <Icon type={IconType.refresh} />
          </button>
        </div>
      </div>
      {data && data.flows && (
        <Tabs>
          <TabList>
            {data.flows.map((x, i) => {
              return <Tab key={i}>{x.Name}</Tab>;
            })}
          </TabList>
          {data.flows.map((fx, fi) => {
            let records = data.records.filter((r) => r.FlowId === fx.Id) ?? [];
            let stats = data.flowStats.find(r=>r.FlowId === fx.Id);
            if(!stats) toast.info(`Flow Id: ${fx.Id}`);
            return (
              <TabPanel key={fi}>
                <div className="flex-flow-item-list">
                  {records.length === 0 && (
                    <div>
                      <div className="alert alert-info">
                        <h3>No Records Found</h3>
                        Currently there are no flow records for "{fx.Name}".
                      </div>
                    </div>
                  )}
                  {stats && <div className='flex-between'>
                    <div>                        
                        <div>Completed Records: {stats.NumberOfRecords}</div>
                        <div>Pending Records: {stats.NumberOfPendingRecords}</div>
                        <div>First Record: {FormatDate(stats.FirstRecordFinishedAtUtc)}</div>
                        <div>Last Record: {FormatDate(stats.LastRecordFinishedAtUtc)}</div>
                    </div>
                    <div className='text-right'>
                      <div>
                        <a className='btn btn-xs btn-secondary' href={StoreX.BuildUrl(`../app/setup/flex/${fx.PublicId}`)} target='_blank'><Icon type={IconType.configure}/> Manage Flow</a>
                      </div>
                        <div>Tickets: {stats.TicketsSold}</div>
                        <div>Additional Tickets: {stats.AdditionalTicketsSold}</div>
                        <div>Total Tickets Sold: {stats.TotalTicketsSold}</div>
                    </div>
                    </div>}
                  {records.map((x, i) => {
                    let flow = flowDic[x.FlowId];
                    return (
                      <div key={i} className="list-item flex-between">
                        <div className="flex">
                          <div className="icon-status">{getIcon(x)}</div>
                          <div>
                            <div>
                              <strong>
                                {x.FirstName} {x.LastName}
                              </strong>
                            </div>
                            <div>Started: {FormatDate(x.CreatedAtUtc, 'date-time')}</div>
                            {x.Finished && <div>Finished: {FormatDate(x.Finished, 'date-time')}</div>}
                          </div>
                        </div>
                        <div className='btn-group'>
                            <Button type='delete' text='Delete' onClick={()=>{removeRecord(x)}}/>
                            {x.Finished && <Button type='refresh' text='Mark In Progress' onClick={()=>{markRecordAsNotFinished(x)}}/>}
                          <a href={StoreX.BuildUrl(`../App/Flex/${flow.PublicId}/${x.PublicId}`)} className="btn btn-success" target="_blank">
                            {' '}
                            <Icon type={IconType.edit} /> Open
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabPanel>
            );
          })}
          <TabPanel></TabPanel>
        </Tabs>
      )}
    </>
  );
};

export default ReduxMap(FlexViewer);
