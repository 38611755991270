import React, { useState, useEffect } from 'react'


const JudgingRoundJudgeAssignment = (props) => {
  const {
    projects,
    assignments,
    judge,
    round,
  } = props
  const [avgScore, setAvgScore] = useState(0)
  const [score, setScore] = useState(0)

  useEffect(() => {
    if (assignments?.length > 0) {
      let scored = assignments.filter(x => x.Score > 0);
      setScore((scored.length / assignments.length * 100).toFixed(2));
      let sum = scored.reduce( (acc, curr) => acc += +curr.Score, 0)
      setAvgScore((sum / scored.length).toFixed(2))
    }
  }, [assignments])




  const mappedAssignments = assignments?.map((a, i) => {
    if (projects[a.ProjectId]) {
      return (
        <span
          className="judge-assignment"
          key={`${a.ProjectId}&${i}`}
        >
          {a.Score
            &&
            <span
              v-if="a.Score"
              className="hover-success"
            >
              <i className="fa fa-check-square"></i>{a.Score.toFixed(2)}
            </span>
          }
          {projects[a.ProjectId].ProjectId}
          {round.Values.SchedulesInterviews
            ?
            <span
              className="interview-time"
            >&nbsp;
              {a.InterviewAt}
            </span>
            :
            a.FaildToSetInterview
            &&
            <span
              className="interview-time"
            >&nbsp;
              <i className="text-danger fad fa-calendar-day"></i>
            </span>
          }
          <span
            className="hover-danger"
            onClick={() => props.editAssignment(a.Id)}
            title="edit assignment"
          >
            <i className="fa fa-edit"></i>
          </span>
          <span
            className="hover-danger"
            onClick={() => props.removeAssignment(a.Id)}
            title='Remove assignment'
          >
            <i className="fa fa-trash-can"></i>
          </span>
        </span>
      )
    } else {
      return <></>
    }
  })

  const openMakeAssignment = () => {
    console.log('openMakeAssignment pressed')
    props.setSelectedProject({})
    props.setSelectedJudge(judge)
    props.setModalOpen(true)
  }

  // needs to open a make assignment
  // needs to be able to remove an assignment

  return (
    <div className="hover-shadow row-padding soft-border">
      <div className="row">
        <div className="col-sm-2"><strong>{judge.FirstName} {judge.LastName}</strong></div>
        <div className="col-sm-2 text-center">
          <span
            className="hover-success"
            onClick={openMakeAssignment} 
            title="Make assignment"
          >
            <i className="fad fa-box-open make-project-asgnm"></i>
          </span>
          {assignments?.length > 0
            &&
            <span className="badge">{assignments.length}</span>
          }
        </div>
        <div className="col-sm-8 text-right">
          {isNaN(avgScore) ? `` : `${avgScore} Avg Raw Score`}<br />
          {isNaN(avgScore) ? `` : `${score}% Complete`}<br />
        </div>
      </div>
      <div className="">
        <div className="judging-assignment-list">
          {mappedAssignments}
        </div>
      </div >
    </div >
  )
}

export default JudgingRoundJudgeAssignment