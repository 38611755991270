import React, { useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "../../react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Icon, { IconType } from '../Icon/Icon';
import { store } from '../../redux/oldStore';
import { toast } from 'react-toastify';

interface EmailAPersonProps {
  personId: string;
  setModalOpen: (open: boolean) => void;
}

const EmailAPerson = (props: EmailAPersonProps) => {
  const [subject, setSubject] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChanged = (state) => {
    setEditorState(state);
  };

  const toHtml = () => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  const sendEmailClick = () => {
    let submitObj = {
      subject,
      message: toHtml(),
      personId: props.personId,
      //projectId?
    }
    store.server.postApi('../Email/SendEmailTo', submitObj).then(res => {
      toast.success('Email sent!');
      props.setModalOpen(false);
    })

  }

  return (
    <div className='form-horizontal'>

      <div className='form-group'>
        <label className='col-md-4 control-label'>Subject:</label>
        <div className='col-md-8'>
          <input
            className='form-control'
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
      </div>
      <div className="head-room">
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "colorPicker",
              "remove",
              "history",
            ],
          }}
          onTab={(event) => {
            event.cancelBubble = true;
          }}
          onEditorStateChange={onEditorStateChanged}
        />
      </div>
      <div>
        $NAME$ will be replaced with recipient's name.
        <br />
        $TITLE$ will be replaced with project's title.
        <br />
      </div>
      <div className='form-group'>
        <button
          className='btn btn-success'
          onClick={sendEmailClick}
        >
          <Icon type={IconType.paperPlane} /> Send
        </button>
        <button
          className='btn btn-default'
          onClick={() => props.setModalOpen(false)}
        >
          <Icon type={IconType.ban} /> Close
        </button>
      </div>
    </div>
  )
}

export default EmailAPerson