
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import StoreX, { store } from '../../../redux/oldStore';
import { ServerResponse } from '../../../utils/Server';
import Icon, { IconType } from '../../Icon/Icon';
import SetupWizard from '../SetupWizard';
import './BranchStyle.css';
import { IConfigBranch, IConfigBranchInfo } from './BranchTypes';
import {default as Redux} from '../../../redux/store';
import { ActionType } from 'redux-promise-middleware';
import { CleanVideoUrl } from '../../../utils/Tools';
import redux, { IState, ReduxMap } from '../../../redux/redux';

interface IBranchPortalProps extends IState{
    type?:string;
    publicId?:string;    
    extraProps?:string;
}

const BranchPortal = (props:IBranchPortalProps) =>{
    const [startInfo, setStartInfo] = useState<IConfigBranchInfo>();
    const [activeInfo, setActiveInfo] = useState<IConfigBranchInfo>();
    const [trail, setTrail] = useState<IConfigBranchInfo[]>([]);
    const [isDev, setIsDev] = useState<boolean>(false);
    const [showDev, setShowDev] = useState<boolean>(false);

    useEffect(()=>{
        if(props.IsReady){
            init();
            setIsDev(StoreX.IsDev());
        }
    }, [props.type, props.IsReady]);

    const branchGoTo = (branch:IConfigBranch, skipSpeicalAction?:boolean) =>{
        if(branch.GoTo){
            if(branch.SpecialAction && !skipSpeicalAction) {
                console.log(`doing special ActionType...`)
                store.server.getApi<ServerResponse<IConfigBranchInfo>>(`../SetupWiz/Branch/${branch.PublicId}?extra=${props.extraProps}`).then(x=>{});
            }
            console.log('Go To...', branch.GoTo);
            let url = StoreX.BuildUrlWithFair(branch.GoTo);
            if(branch.GoToNewTab){
                if(window.parent) window.parent.open(url);
                else window.open(url);
            } else {
                if(window.parent) window.parent.location.href = url;
                else window.location.href = url;
            }
            return true;
        } else {

        }
        //console.log('No go to...');
        return false;
    };

    const init = () =>{
        console.log(`Calling branch: ${props.type}`);
        let url = props.publicId ? `../SetupWiz/Branch/${props.publicId}`: `../SetupWiz/BranchByType/${props.type}`;
        store.server.getApi<ServerResponse<IConfigBranchInfo>>(url+`?extra=${props.extraProps}`)
        .then(x=>{
            if(x.Success){
                if(x.Value.Message) {
                    toast.dark(x.Value.Message, {autoClose:30000});
                }
                if(x.Value.Failed) return;
                
                setStartInfo(x.Value);
                if(x.Value.branch.NextBranchId){
                    store.server.getApiQuiet<ServerResponse<IConfigBranchInfo>>(`../SetupWiz/Branch/${x.Value.branch.NextBranchId}?extra=${props.extraProps}`)
                    .then(xx=>{
                        if(xx.Success){
                            if(xx.Value.Message) toast.dark(xx.Value.Message, {autoClose:30000});
                            if(xx.Value.Failed) return;
                            if(branchGoTo(xx.Value.branch, true)) return;
                            //console.log(`Active: ${activeInfo?.branch.Title} => ${x.Value.branch.Title}`);
                            setTrail([...trail, xx.Value]);
                            setActiveInfo(xx.Value);
                            setActiveInfo(xx.Value);
                        } else {
                            toast.error(xx.Message, {autoClose:false});
                        }
                    })
                } else {
                if(branchGoTo(x.Value.branch)) return;
                setActiveInfo(x.Value);      
                setTrail([...trail, x.Value]);          }
            } else {
                //toast.error(x.Message, {autoClose:false});
                console.log(toast);
            }
        })
        .catch(x=>{
            toast.error('Error getting configuration branch.');
        });
    };

    const branchSelect = (b:IConfigBranch, newTrail?:IConfigBranchInfo[],isGoBack?:boolean) =>{

        if(b.ConfirmBranchSelectText && !isGoBack){
            if(!window.confirm(b.ConfirmBranchSelectText)) return;
        }

        if(branchGoTo(b, isGoBack)) return;

        
        store.server.postApi<ServerResponse<IConfigBranchInfo>>(`../SetupWiz/Branch/${b.PublicId}?extra=${props.extraProps}`, {isGoBack:isGoBack?true:false})
        .then(x=>{
            if(x.Success){
                if(x.Value.Message) toast.dark(x.Value.Message, {autoClose:30000});
                if(x.Value.Failed) return;

                if(b.NextBranchId){
                    store.server.postApi<ServerResponse<IConfigBranchInfo>>(`../SetupWiz/Branch/${b.NextBranchId}?extra=${props.extraProps}`, {isGoBack:isGoBack?true:false})
                    .then(xx=>{
                        if(xx.Success){
                            if(xx.Value.Message) toast.dark(xx.Value.Message, {autoClose:30000});
                            if(xx.Value.Failed) return;
                            if(branchGoTo(x.Value.branch, true)) return;
                            //console.log(`Active: ${activeInfo?.branch.Title} => ${x.Value.branch.Title}`);
                            if(newTrail) setTrail([...newTrail, xx.Value]);
                            else setTrail([...trail, xx.Value]);
                            setActiveInfo(xx.Value);
                        } else {
                            toast.error(xx.Message, {autoClose:false});
                        }
                    })
                }
                else{
                    //console.log(`Active: ${activeInfo?.branch.Title} => ${x.Value.branch.Title}`);
                    if(branchGoTo(x.Value.branch)) return;
                    if(newTrail) setTrail([...newTrail, x.Value]);
                    else setTrail([...trail, x.Value]);
                    setActiveInfo(x.Value);                        
                }

            } else {
                toast.error(x.Message, {autoClose:false});
            }
        })
        .catch(x=>{
            toast.error('Error getting configuration branch.');
        });
    };

    const goBack = ()=>{
        let newTrail = [...trail];
        
        newTrail.pop();
        let lastBranch = newTrail.pop();

        if(lastBranch?.branch.NextBranchId) lastBranch = newTrail.pop();
        
        setTrail(newTrail);
        if(lastBranch) branchSelect(lastBranch.branch, newTrail, true);

        // let p = trail.find(x=>x.branch.Id === branch.ParentBranch);
        // let pn = trail.find(x=>x.branch.NextBranch === branch.ParentBranch);
        // if(p) setActiveInfo(p);
        // else if (pn) setActiveInfo(pn);
        // else if(startInfo?.branch.Id === branch.ParentBranch) setActiveInfo(startInfo);
        // else toast.warning(`Can't go back right not. Going back to the begining.`);
    }

    const onWizardSave = ()=>{
        //reload branch...
        store.server.getApiQuiet<ServerResponse<IConfigBranchInfo>>(`../SetupWiz/Branch/${activeInfo?.branch.PublicId}?extra=${props.extraProps}`)
        .then(x=>{
            if(x.Success){
                setActiveInfo(x.Value);
                redux.Reload();
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        })
    };

    const displaySingleBranch = (b:IConfigBranchInfo)=>{
        let branch = b.branch;
        let childrenHash:Record<string,boolean> = {};
        b.children?.forEach(x=>{
            childrenHash[x.PublicId ?? ''] = true;
        });
        let stepChildren = b.stepChildren?.filter(x=>!childrenHash[x.PublicId??'']) ?? [];

        return (<>
        {branch.VideoPath && branch.VideoPath.toLowerCase().indexOf("youtu.be") > -1 && <div className='branch-video-viewer'>
        <iframe 
                  src={CleanVideoUrl(branch.VideoPath)} 
                  
                //   title='project video'
                  // type='application/pdf' 
                //   scrollbar='1'
                //   onError={(e)=>{e.target.style.display = 'none'}}
                ></iframe>
            </div>} 
        {branch.VideoPath && branch.VideoPath.toLowerCase().indexOf("youtu.be") === -1 && <div className='branch-video-viewer'>
        <video width="100%" height="100%" controls>
                  <source src={`${branch.VideoPath}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
            </div>} 
        <div dangerouslySetInnerHTML={{__html:branch.ContentHtml ?? `<h3>${branch.Title}</h3>`}}></div>
            {branch.ConfigGroup && <div className='group'><SetupWizard wizardKey={branch.ConfigGroup} autoSave={true} onSave={onWizardSave} /></div>}
            {branch.ContentHtmlBottom && <div dangerouslySetInnerHTML={{__html:branch.ContentHtmlBottom}}></div>}
            <div className={`branch-options buttons-${(b.children?.length + stepChildren?.length) < 5 ? '5': 'na'}`}>
            {trail.length > 1 && <><button type="button" className="btn btn-default back-to-parent-btn click" onClick={goBack}><Icon type={IconType.back}/> back</button></>}
                {b.children?.sort((a,b)=>{return (a.SortOrder ?? 0) < (b.SortOrder ?? 0) ? -1:1}).map((x,i)=>{
                    return <button type='button' key={`bc-${i}`} className={`btn branch-limb-btn click ${x.ButtonType ?? ""}`} onClick={()=>{branchSelect(x)}}>
                        {x.Title} 
                    </button>
                })}
                {stepChildren?.map((x,i)=>{
                    return <button type='button' key={`bc-step-${i}`} className={`btn branch-limb-btn click ${x.ButtonType ?? ""}`} onClick={()=>{branchSelect(x)}}>
                        {x.Title} 
                    </button>
                })}
            </div>
        </>);
    }

    const displayBranch = (b:IConfigBranchInfo) => {
        let branch = b.branch;
        //console.log(`Displaying Branch: ${b.branch.Title}`)
        return <>
            {displaySingleBranch(b)}            
        </>;
    };

    const MetaPath = () =>{
        
        return <div className={`branch-dev-view ${!showDev?'hide-dev':''}`}>
            <button type='button' className='show-dev btn btn-danger' onClick={()=>{setShowDev(!showDev)}}>{showDev ? "X":"Show Dev"}</button>
            <div>
            <strong>Name:</strong> {activeInfo?.branch?.MetaName}<br/>
            <strong>Path:</strong> {activeInfo?.branch?.MetaPath}
            </div>
            <div className='steps'>
                {trail && trail.map((x,i)=>{
                    return <div className='trail-step' key={`trail-${i}`}>
                        <div className='flex-between'>
                            <span>{x.branch.Title}</span>
                            <button type='button' className='btn btn-secondary btn-xs' onClick={()=>{branchSelect(x.branch)}}>go</button>
                        </div>
                            
                            <div className='small'>Meta: {x.branch.MetaName}</div>
                        </div>
                })}
            </div>
        </div>
    }

    return (<>
        <div className="branch-contaier">
            {activeInfo && displayBranch(activeInfo)}
        </div>
        {isDev && MetaPath()}
    </>        
    );
};

export default ReduxMap(BranchPortal);