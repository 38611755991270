import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { store } from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";
import Icon, { IconType } from "../Icon/Icon";
import Modal from "../Modal/Modal";
import ConfigFormLanding from "../Setup/ConfigForm/ConfigFormLanding";

type TagType = "person" | "project";

interface ITag {
  Id: string;
  Type: string;
  Value: string;
}

interface ITagSaveResponse {
  tag: ITag;
}

interface ITagInfoResponse {
  personTags: ITag[];
  projectTags: ITag[];
}

interface ITagBulkForm{
  tag?:number,
  ids?:string,
  remove?:boolean
}

const TagsContainer = () => {
  const [everything, setEverything] = useState<ITagInfoResponse>();
  const [personTags, setPersonTags] = useState<ITag[]>([]);
  const [projectTags, setProjectTags] = useState<ITag[]>([]);
  const [tags, setTags] = useState<ITag[]>([]);
  const [selectedTag, setSelectedTag] = useState<ITag>();
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [showBulkTagModal, setShowBulkTagModal] = useState(false);
  const [bulkTagForm, setBulkTagForm] = useState<ITagBulkForm>({});

  useEffect(() => {
    store.server
      .getApi<ServerResponse<ITagInfoResponse>>("../Tag/Info")
      .then((x: any) => {
        if (x.Success) {
          setEverything(x.Value);

          let ts = x.Value.personTags ?? [];
          x.Value.projectTags?.forEach((t) => {
            ts.push(t);
          });

          ts = ts.sort((a, b) => {return a.Value.toUpperCase() > b.Value.toUpperCase() ? 1:-1});
          setTags(ts);
          setProjectTags(x.Value.projectTags);
          setPersonTags(x.Value.personTags);

        } else {
          toast.error(`Error getting tags; ${x.Message}`);
        }
      })
      .catch((err) => toast.error(err));
  }, []);

  const bulkTagIt = (remove:boolean)=>{
    let request = bulkTagForm;
    let errors:string[] = [];

    if(!request.tag) errors.push('Tag');

    if(!remove && !request.ids) errors.push('IDs to Tag');

    if(errors.length > 0){
      toast.warning(<>Missing Information: <ul>
          {errors.map((x,i)=>{
            <li key={`tag-${i}`}>{x}</li>
          })}
        </ul></>)
      return;
    }

    store.server
      .postApi<ServerResponse<ITagSaveResponse>>("../Tag/BulkTag", {...request, remove})
      .then((res: any) => {
        if (res.Success) {
          toast.success("We did it!");
          setBulkTagForm({});
        } else {
          toast.error(`Error tagging; ${res.Message}`);
        }
      });
  };

  return (
    <div className="bumper-l bumper-out-l">
      <ConfigFormLanding type='tags' />

      <div className="col-sm-12 headroom-xl">
      <button type="button" className="btn btn-secondary" onClick={()=>{
        setShowBulkTagModal(true);
      }}>
                <Icon type={IconType.tags}/> bulk tag
              </button>
      </div>

      {showBulkTagModal && 
      <Modal setModalOpen={setShowBulkTagModal} title={"Bulk Tag"}>
        Often time people want to bulk tag, users, judges, or even entries. Use this form to bulk apply / remove tags.

        <div className="form-horizontal">
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="bulk-tag"> 
              Tag
            </label>
            <div className="col-sm-8">
              <select className="form-control" id="bulk-tag"
                value={bulkTagForm.tag+''} onChange={(e)=>{setBulkTagForm({...bulkTagForm,tag:+e.target.value});}}
              >
                <option value={0}>Select...</option>
                {personTags.length > 0 && <>
                  <optgroup label="Person Tags">
                  {personTags.map((t,i)=>{
                    return (<option key={`ut-${i}`} value={t.Id}>{t.Value}</option>);
                  })}
                  </optgroup>
                </>}
                {projectTags.length > 0 && <>
                  <optgroup label="Project / Entry Tags">
                  {projectTags.map((t,i)=>{
                    return (<option key={`ut-${i}`} value={t.Id}>{t.Value}</option>);
                  })}
                  </optgroup>
                </>}
                
              </select>
            </div>
          </div>
          <div className="form-group">
            
          <label className="control-label col-sm-4" htmlFor="bulk-ids"> 
              IDs to Tag
            </label>
            <div className="col-sm-8">
              <textarea value={bulkTagForm.ids} onChange={(e)=>{setBulkTagForm({...bulkTagForm,ids:e.target.value});}} className="form-control" maxLength={3000} rows={5} id="bulk-ids" placeholder="ids can be seperated by spaces, commas, or new lines">
              </textarea>
              <span className="label label-info">if you leave IDs to Tag blank it will remove this tag from everything</span>
            </div>
          </div>
          <div className="form-group">
            <div className="col-sm-offset-4 col-sm-8">
              <button type="button" className="btn btn-secondary" onClick={()=>{bulkTagIt(false)}}>
                <Icon type={IconType.save}/> Add Tags
              </button>
              <button type="button" className="btn btn-default" onClick={()=>{bulkTagIt(true)}}>
                <Icon type={IconType.trashO}/> Remove Tags
              </button>
            </div>
          </div>
        </div>
        </Modal>}
    </div>
  );
};

export default TagsContainer;
