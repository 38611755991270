import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IState, ReduxMap } from '../../redux/redux';
import FlexFlowManager from '../../Components/FlexFlow/Setup/FlexFlowManager';
import FlexFlow from '../../Components/FlexFlow/FlexFlow';
import FlexFlowFee from '../../Components/FlexFlow/FlexFlowFee';

interface IProps extends RouteComponentProps<IParams>, IState {}
interface IParams {
  flowId: string;
  recordId: string;
}

const FlexFlowPageFee = (props: IProps) => {
  //console.log('Page...',props.match.params);

  return (
    <>
      {props.IsReady && 
        <FlexFlowFee state={props} flowId={props.match.params.flowId} recordId={props.match.params.recordId} />}
    </>
  );
};

export default withRouter(ReduxMap(FlexFlowPageFee));
