import React, { FunctionComponent, useState, useEffect } from "react";
import ParticipantPaperworkReivewProjectList from "./ParticipantPaperworkReviewProjectList";
import { ServerResponse } from "../../utils/Server";
import { store } from '../../redux/oldStore'
import { project, paperworkProjectsResponse, updateFileHookDef, uploadFileHookDef, fileRemovedHookDef, paperworkProjectsWrapper } from "./paperworkTypes";
import './paperworkStyles.css';
import { toast } from "react-toastify";
import { IState, ReduxMap } from "../../redux/redux";




type participantPaperworkReviewState = paperworkProjectsResponse & {
  loading: boolean; //todo:  Probably should get rid of loading.  We already have loading handled in the api call thing.
}


//todo:  Might make sence to move this somewhere else.  This is probably common code that could be reused.
const convertDotNetDateJsonToDate = (dotNetJsonDate?: string) => {
  return dotNetJsonDate ? new Date(parseInt(dotNetJsonDate.replace("/Date(", "").replace(")/", ""), 10)) : dotNetJsonDate
}

/**
 * Changes all the dates in the projects list to have javascript dates instead of .net date json strings.  This method has side effects.
 * @param projects 
 */
const parseUploadedFileDates = (projects: project[]) => {
  projects.flatMap(a => a.UploadedFiles).forEach(uf => {
    uf.lastReviewedDate = convertDotNetDateJsonToDate(uf.lastReviewedDate as string);
    uf.lastUpdatedDate = convertDotNetDateJsonToDate(uf.lastUpdatedDate as string);
  });
}

const sendNagEmails = async () => {
  //todo:  add the send nag emails code.
  console.debug("Send Nag Emails");
  const success = (data?: ServerResponse<boolean>) => {
    if (data) {
      console.debug(data);
      //todo:  let the user know that the emails were sent.
      toast.info('Reminders were sent out.',{autoClose:false})
    }
  }

  const error = (errorMessage: string) => {
    //todo: stop loading animation
    alert(errorMessage);
  }

  try {
    const response = await store.server?.postApiWithServerResponse<boolean>('../PaperworkReview/SendNagEmails', {});
    success(response);
  } catch (result) {
    error(result);
  }
}

interface IProps extends IState{

}

const ParticipantPaperworkReivew = (props:IProps) => {
  
  const init = async () => {
    const success = (data?: ServerResponse<paperworkProjectsWrapper>) => {
      if (data) {
        parseUploadedFileDates(data.Value.Value.projects);
        setData(data.Value);
        setMyState({
          loading: false,
          projects: data.Value.Value.projects,
          fileTypes: data.Value.Value.fileTypes,
          baseUrl: data.Value.Value.baseUrl,
          schools: data.Value.Value.schools,
          canUpload: data.Value.CanUpload,
          isStaff: data.Value.IsStaff,
        });
      }
    }
    try {
      let response = await store.server?.postApiWithServerResponse<paperworkProjectsWrapper>('../PaperworkReview/Projects', {});
      success(response)

    } catch (error) {
      console.error(error);
      setMyState({ ...myState, loading: false });
    }
  }

  const updateFileHook: updateFileHookDef = (uploadedFile) => {
    let projects = [...myState.projects];
    updateFile(uploadedFile, projects);
    setMyState({...myState, projects:projects});
  }

  
const updateFile = (uploadedFile: any, projects: project[]) => {
  try {
    const fileToChange = projects.flatMap(a => a.UploadedFiles).filter(f => f.id === uploadedFile.id)[0];
    fileToChange.fileStatus = uploadedFile.fileStatus;
    fileToChange.notes = uploadedFile.notes;
    fileToChange.lastReviewedDate = convertDotNetDateJsonToDate(uploadedFile.lastReviewedDate);
    fileToChange.lastUpdatedDate = convertDotNetDateJsonToDate(uploadedFile.lastUpdatedDate);
  } catch (error) {
    console.error(error)
  }
}

  const fileUploadHook: uploadFileHookDef = () => {
    init();
  }

  const fileRemovedHook: fileRemovedHookDef = ()=>{
    init();
  }

  const requirementOverloadUpdateHook = () => {
    init();
  }

  const initialState: participantPaperworkReviewState = {
    fileTypes: [],
    loading: true,
    projects: [],
    baseUrl: "",
    schools: [],
    isStaff: false,
    canUpload: false,
  };
  const [myState, setMyState] = useState(initialState);
  const [data, setData] = useState<paperworkProjectsWrapper>();


  //initialize this component the the first time.
  useEffect(() => { init() }, []);


  return (
    <div className="paperwork-container">
      {myState.loading && <>
        <div><h3>Loading...</h3></div>
      </>}
      {!myState.loading && myState.projects.length > 0 &&  <>      
        <><ParticipantPaperworkReivewProjectList 
          isStaff={myState.isStaff} 
          canUpload={myState.canUpload} 
          projects={myState.projects} 
          fileTypes={myState.fileTypes} 
          baseUrl={myState.baseUrl} 
          state={props}
          data={data}          
          refresh={()=>{init()}}
          fileUploadHook={fileUploadHook} 
          fileRemoveHook={fileRemovedHook} 
          fileUpdateHook={updateFileHook} 
          requirementUpdateHook={requirementOverloadUpdateHook}/>
      <div className="clearfix"></div>
      <br />
      {myState && myState.isStaff && (data?.AllowSendNagReminder ?? false) && <div className="col-sm6 col-smoffset-3 head-room">
        <p className="alert alert-info">
          Send a reminder to all students who are missing one or more required files.  This can only be done once a day.
      </p>
        <button type="button" className="btn btn-default" onClick={sendNagEmails}>Send Reminder</button>
        <br />
        <br />
      </div>}
    </>
      </>}
      {!myState.loading && myState.projects.length === 0 && <>
        <div>
          <h3>No Projects Found!</h3>
        </div>
      </>}
    </div>
  );
}

export default ReduxMap(ParticipantPaperworkReivew);
