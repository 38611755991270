import React, { useEffect, useState } from 'react';
import { store } from "../../../redux/oldStore";
import { toast } from 'react-toastify';

interface ISettings {
  autoPlop: boolean;
  clearPromptCacheUrl: string;
}


const AcaDecaTools = (props: any) => {
  const [everything, setEverything] = useState();
  const [settings, setSettings] = useState<ISettings>();

  useEffect(() => {
    store.server.getApi('../AcaDeca/Info')
    .then((res: any) => {
      console.log('acadeca/info', res);
      setEverything(res.Value);
      setSettings(res.Value.setings)

    })
    .catch(err => {
      console.error(err);
      toast.warning('could not get categories')
    })
  }, [])


  const handlePromptCacheClick = () => {
    if (settings?.clearPromptCacheUrl){
      store.server.getApi(settings?.clearPromptCacheUrl)
        .then(res => {
          toast.success('cache cleared!')
        })
        .catch(err => {
          toast.warning('something went wrong')
        })

    }
  }

  return (
    <div className='bumper-l bumper-out-l'>
      <div>
        <h3>Tools</h3>
        <div>
          {settings?.clearPromptCacheUrl 
            && <button 
                className='btn btn-primary' 
                onClick={handlePromptCacheClick}
              >
                Clear Prompt Cache
              </button>       
          }
        </div>

      </div>
    </div>
  )
}

export default AcaDecaTools