import React, { useState, useEffect } from "react";
import "../Exam/ExamStyle.css";
import Icon, { IconType } from "../Icon/Icon";

import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ITester } from "./ExamTypes";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

interface IExamDashboardProps  extends RouteComponentProps<any>{}

const ExamDashboard = (props: IExamDashboardProps) => {
  const goTo = (to: "dashboard" | "schedule" | "builder" | "home") => {
    switch (to) {
      case "home":
        props.history.push(`/app/exam/${props.location.search}`);
        break;
      case "dashboard":
        props.history.push(`/app/exam/dashboard/${props.location.search}`);
        break;
      case "builder":
        toast.info("Exam builder, not enabled for demo!");
        break;
      case "schedule":
        toast.info("Schedule & Invite, not enabled for demo!");
        break;
      default:
        toast.warning("Unknown action!");
        break;
    }
  };

  const testerRow = (tester: ITester) => {
    return (
      <tr>
        <td>{tester.Name}</td>
        <td>{tester.Status}</td>
        <td>{tester.Score && <>{tester.Score}% </>}</td>
        <td>{testerActions(tester)}</td>
      </tr>
    );
  };

  const testerActions = (tester: ITester) => {
    return (
      <div>
        <span className="click">
          <Icon type={IconType.configure} />
        </span>
        <span className="click">
          <Icon type={IconType.lock} />
        </span>
        <span className="click">
          <Icon type={IconType.comments} />
        </span>
        {tester.Score && (
          <span className="click">
            <Icon type={IconType.eye} />
          </span>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="bumper">
        <div>
          <h3>zFairs Dashboard</h3>
        </div>
        <hr />
        <div>
          <div className="flex-between">
            <h4>Test of Ages</h4>
            <button
              type="buton"
              className="btn btn-secondary"
              onClick={() => {
                goTo("home");
              }}
            >
              <Icon type={IconType.close} /> Close
            </button>
          </div>
          <hr />
          <Tabs>
            <TabList>
              <Tab>Overview</Tab>
              <Tab>Testers</Tab>
            </TabList>
            <TabPanel>

            <div className="exam-landing-tile-list">
          <div className="exam-landing-tile">
              <div className="flex-between">
            <h4>
              <Icon type={IconType.dashboard} /> Exam Status 
            </h4>
            <h4>
              <Icon type={IconType.projectNew} /> Open 
            </h4>
            </div>

            <div>
              <hr />
            </div>
            <div className="grow">
              
              <div><Icon type={IconType.poll} /> 45% Finished</div>
              <div><Icon type={IconType.poll} /> 68% Started</div>
              <div><Icon type={IconType.poll} /> 32% Not Opened</div>

            </div>

            <div>
              <hr />
            </div>
            <div className="text-right">
              <button
                type="buton"
                className="btn btn-default"
                onClick={() => {
                  goTo("dashboard");
                }}
              >
                <Icon type={IconType.lock} /> Lock Exam
              </button>
            </div>
          </div>
          <div className="exam-landing-tile">
            <h4>
              <Icon type={IconType.calendar} /> Schedule & Invite
            </h4>

            <div>
              <hr />
            </div>
            <div className="grow">
              Schedule and/or invite others to take this exam. See stats of exam invites.
            </div>

            <div>
              <hr />
            </div>
            <div className="text-right">
              <button
                type="buton"
                className="btn btn-secondary"
                onClick={() => {
                  goTo("schedule");
                }}
              >
                Open <Icon type={IconType.next} />
              </button>
            </div>
          </div>
          <div className="exam-landing-tile">
            <h4>
              <Icon type={IconType.build} /> Exam Builder
            </h4>
            <div>
              <hr />
            </div>
            <div className="grow">
              Edit, build, and manage this exam.
            </div>
            <div>
              <hr />
            </div>
            <div className="text-right">
              <button
                type="buton"
                className="btn btn-secondary"
                onClick={() => {
                  goTo("builder");
                }}
              >
                Open <Icon type={IconType.next} />
              </button>
            </div>
          </div>
        </div>

            </TabPanel>
            <TabPanel>
              <div className="exam-dashboard-tester-list">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Score</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testerRow({
                      Name: "Aaden Peck",
                      Score: 91.06,
                      Status: "Finished",
                    })}
                    {testerRow({
                      Name: "Abbigail Pearson",
                      Score: 71.47,
                      Status: "Finished",
                    })}
                    {testerRow({
                      Name: "Amara Hudson",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Amira Mills",
                      Score: "",
                      Status: "In Progress",
                    })}
                    {testerRow({
                      Name: "Angelica Beck",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Annika Morton",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Averi Kelley",
                      Score: 9.44,
                      Status: "Finished",
                    })}
                    {testerRow({
                      Name: "Cory Hines",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Curtis Franklin",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Dakota Mays",
                      Score: "",
                      Status: "In Progress",
                    })}
                    {testerRow({
                      Name: "Darian Krause",
                      Score: 80.39,
                      Status: "Finished",
                    })}
                    {testerRow({
                      Name: "Deandre Holland",
                      Score: "",
                      Status: "In Progress",
                    })}
                    {testerRow({
                      Name: "Devon Gonzales",
                      Score: 44.43,
                      Status: "Finished",
                    })}
                    {testerRow({
                      Name: "Elise Guerra",
                      Score: 59.41,
                      Status: "Finished",
                    })}
                    {testerRow({
                      Name: "Eve Velazquez",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Gia Hampton",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Guadalupe Elliott",
                      Score: 44.12,
                      Status: "Finished",
                    })}
                    {testerRow({
                      Name: "Hailee Joseph",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "India Ingram",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Judah Roy",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Kayden Medina",
                      Score: 89.92,
                      Status: "Finished",
                    })}
                    {testerRow({
                      Name: "Kole Colon",
                      Score: 87.12,
                      Status: "Finished",
                    })}
                    {testerRow({
                      Name: "Krystal Melendez",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Lailah Tran",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Lauren Reeves",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Leslie Allison",
                      Score: "",
                      Status: "In Progress",
                    })}
                    {testerRow({
                      Name: "Lexie Strong",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Lindsay Woodard",
                      Score: 10.68,
                      Status: "Finished",
                    })}
                    {testerRow({
                      Name: "Lisa Williams",
                      Score: "",
                      Status: "In Progress",
                    })}
                    {testerRow({
                      Name: "Lorenzo Griffith",
                      Score: 73.23,
                      Status: "Finished",
                    })}
                    {testerRow({
                      Name: "Luciana Wyatt",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Madeline Fitzpatrick",
                      Score: 72.38,
                      Status: "Finished",
                    })}
                    {testerRow({
                      Name: "Malia Horne",
                      Score: 47.4,
                      Status: "Finished",
                    })}
                    {testerRow({
                      Name: "Mylee Morgan",
                      Score: "",
                      Status: "In Progress",
                    })}
                    {testerRow({
                      Name: "Nyla Orozco",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Parker Henson",
                      Score: "",
                      Status: "In Progress",
                    })}
                    {testerRow({
                      Name: "Ricardo Noble",
                      Score: 38.32,
                      Status: "Finished",
                    })}
                    {testerRow({
                      Name: "Roland Bauer",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Serena Sparks",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Sydnee Lang",
                      Score: "",
                      Status: "Not Opened",
                    })}
                    {testerRow({
                      Name: "Titus Diaz",
                      Score: "",
                      Status: "In Progress",
                    })}
                    {testerRow({
                      Name: "Xavier Escobar",
                      Score: "",
                      Status: "In Progress",
                    })}
                    {testerRow({
                      Name: "Ximena Wiggins",
                      Score: 47.65,
                      Status: "Finished",
                    })}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default withRouter(ExamDashboard);
