import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import StoreX, { store } from "../../redux/oldStore";
import {
  ParticipantRequirement,
  RequirementInputType,
  UiFormData,
} from "../../utils/CommonTypes/UserTypes";
import { Server, ServerResponse } from "../../utils/Server";
import { IState, ReduxMap } from "../../redux/redux";
import Icon, { IconType } from "../../Components/Icon/Icon";
import NhdMedicalForm from "../../Components/User/NhdMedicalForm";

interface IProps extends RouteComponentProps<MatchParams>, IState {

}

interface MatchParams {
  userId: string;
}

const MedicalPage = (props: IProps) => {
  const [formData, setFormData] = useState<UiFormData>();
  const [requirements, setRequirements] = useState<ParticipantRequirement[]>(
    []
  );
  const [formValues, setFormValues] = useState<Record<string, string>>({});
  const [requiredMissing, setRequiredMissing] = useState(false);

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    if(formData?.Requirements && formData.Requirements.length > 0){
      return;
    }
    if (formData && formData.NewRecord && formData.NextRecordPath) {
      props.history.push(`${formData?.NextRecordPath}/${props.location.search}`);
    }
  }, [requirements, formData]);

 

  return (
    <div className="user-requirements-container">
        {props.match.params.userId && <NhdMedicalForm
         canSave={true}
         isFristTimeLogin={true}
         isNewUser={true}
         recordExists={false}
         personId={props.match.params.userId}
         onContinue={()=>{
            //go to next page in this mess...
            window.location.href = StoreX.BuildUrl(`../app/store/${props.match.params.userId}`);
         }}
        />}
    </div>
  );
};

export default ReduxMap(withRouter(MedicalPage));
