import React, { useState, useEffect } from "react";
import Icon, { IconType } from "../Icon/Icon";

interface IPdfBasicToolbar {
  pages: number;
  page: number;
  zoom: number;
  changePage: Function;
  changeZoom: Function;
  viewOnly: boolean;
  save?: Function;
  isSigning?:boolean;
  downloadFormUrl?:string;
}

const PdfBasicToolbar = (props: IPdfBasicToolbar) => {
  const { page, pages, zoom, changePage, changeZoom } = props;

  const nextPage = () => {
    page < pages && changePage(page + 1);
  };

  const prevPage = () => {
    page > 1 && changePage(page - 1);
  };

  return (
    <div className="zpdf-tool-bar top">
      <>
        <div>
          {props.save && !props.viewOnly && !props.isSigning && (
            <>
              <button
                type="button"
                className="btn btn-secondary btn-xs"
                onClick={() => {
                  props.save(true, false);
                }}
              >
                <Icon type={IconType.save} /> Save
              </button>
              <button
                type="button"
                className="btn btn-default btn-xs save-close-btn"
                onClick={() => {
                  props.save(true, true);
                }}
              >
                <Icon type={IconType.save} /> Save & Close
              </button>
              <button
                type="button"
                className="btn btn-default btn-xs"
                onClick={() => {
                  props.save(false, true);
                }}
              >
                <Icon type={IconType.close} /> Close
              </button>
            </>
          )}
          {props.save && props.isSigning && (
            <>
              <button
                type="button"
                className="btn btn-secondary btn-xs"
                onClick={() => {
                  props.save(true, false);
                }}
              >
                <Icon type={IconType.sign} /> Submit
              </button>
              {/* <button
                type="button"
                className="btn btn-default btn-xs"
                onClick={() => {
                  props.save(true, true);
                }}
              >
                <Icon type={IconType.sign} /> Submit & Close
              </button> */}
              <button
                type="button"
                className="btn btn-default btn-xs"
                onClick={() => {
                  props.save(false, true);
                }}
              >
                <Icon type={IconType.close} /> Close
              </button>
            </>
          )}
        </div>
        {props.downloadFormUrl && 
                <div>
                  <a
                    href={props.downloadFormUrl}
                    title="download form"
                    target="_blank"
                  >
                    <Icon type={IconType.download} /> download
                  </a>
                </div>}

        {pages > 1 && (
          <>
            <div className="flex-between">
              <div className="zpdf-page-button">
                {page > 1 && (
                  <span className="click" onClick={prevPage}>
                    <Icon type={IconType.up} />
                  </span>
                )}
              </div>
              <span>
                {page} of {pages}
              </span>
              <div className="zpdf-page-button">
                {page < pages && (
                  <span className="click" onClick={nextPage}>
                    <Icon type={IconType.down} />
                  </span>
                )}
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default PdfBasicToolbar;
