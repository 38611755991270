import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { toast } from 'react-toastify';
import StoreX, { store } from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import MyCaptcha from '../FormFromApiData/ReCaptcha';
import Modal from '../Modal/Modal';
import BranchPortal from '../Setup/Branches/BranchPortal';
import UserLogin from '../UserLogin/UserLogin';

import PageContentEditor from './PageContentEditor';
import redux, { IState, ReduxMap } from '../../redux/redux';
import { IBranchTask } from '../../redux/types';
import Button from '../_Core/Button';
import PhotoGallery from '../PhotoGallery';

interface IBasePageContentProps extends IState, RouteComponentProps<MatchParams> {
  pageId?: string;
  branchInfo?: IBranchTask;
}

interface MatchParams {
  pageId?: string;
}

interface IPageData {
  IsLoggedIn: boolean;
  IsStaff: boolean;
  Tabs: IPageContent[];
  LoginWithEnabled: boolean;
  ShowRegistraitonOnHomePage: boolean;
  ConfigKey?: string;
}

interface IPageContent {
  ContentKey: any;
  Name: string;
  Content: string;
  Active: boolean;
  Type?: string;
}

const BasePageContent = (props: IBasePageContentProps) => {
  const [data, setData] = useState<IPageData>();
  const [baseUrl, setBaseUrl] = useState<string>();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [loginForm, setLoginForm] = useState<any>({});
  const [registerForm, setRegisterForm] = useState<any>({});
  const [branchTask, setBranchTask] = useState<IBranchTask>();
  const [showBranch, setShowBranch] = useState<boolean>(false);
  const [showSubBranch, setShowSubBranch] = useState<boolean>(false);
  const [hasEditableContent, setHasEditableContent] = useState<boolean>(false);
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<IPageContent | undefined>(undefined);
  const [showModalContentEditor, setShowModalContentEditor] = useState<boolean>(false);

  useEffect(() => {
    setBaseUrl(StoreX.BuildUrl(''));
    init();
  }, [props.pageId, props.match.params.pageId]);

  useEffect(() => {
    if (props.ReloadBasePage) {
      init();
    }
  }, [props.ReloadBasePage]);

  useEffect(()=>{
    if(props.Settings){
      let left:string[] = [];
      let right:string[] = [];

      if(props.Settings.MainPageShowAccountWidget) right.push('Account');
      if(props.Settings.MainPageShowLinksWidget) right.push('Links');
      if(props.Settings.MainPageShowLocationWidget) left.push('Location');
      if(props.Settings.MainPageShowScheduleWidget) left.push('Schedule');

      redux.widgetBars(left, right);
    }
  },[props.Settings]);

  useEffect(() => {
    if (data) {
      //Redux.dispatch(setEditButtonVisiable(data.IsStaff && data.Tabs.find(x=>x.ContentKey)));
    }
  }, [data]);

  useEffect(() => {
    let branch = props.branchInfo;
    setBranchTask(branch);

    if (branch?.AutoFocus) setShowBranch(true);
  }, [props.branchInfo]);

  const openEditContent = (e: MouseEvent | any, page: IPageContent) => {
    if (props.ShowEdit && data?.IsStaff) {
      e.preventDefault();
      e.stopPropagation();
      setActivePage(page);
      console.log('clicked');
      setShowEditor(true);
    }
  };

  const onEditorSave = (content: string) => {
    let pages = [...(data?.Tabs ?? [])];
    let page = pages.find((x) => x.ContentKey == activePage?.ContentKey);
    if (page) {
      page.Content = content;

      if (data) setData({ ...data, Tabs: pages });
    }
  };

  const onEditorClose = () => {
    setShowEditor(false);
  };

  const init = () => {
    redux.ReloadedBasePage();
    store.server
      .postApi<ServerResponse<IPageData>>(`../Page/Data`, {
        pageId: props.pageId ?? props.match.params.pageId ?? 'Unknown',
      })
      .then((x) => {
        if (x.Success) {
          let index = 0;
          for (let i = 0; i < x.Value.Tabs.length; i++) {
            if (x.Value.Tabs[i].Active) {
              index = i;
              break;
            }
          }
          setActiveTabIndex(index);
          setData(x.Value);
        } else {
          toast.error(x.Message);
        }
      });
  };

  const mapContent = () => {
    if (!data) return <></>;
    if (data.Tabs.length === 0) {
      return (
        <>
          <h2>404 Page "{props.pageId ?? props.match.params.pageId}" Not Found</h2>
        </>
      );
    } else if (data.Tabs.length === 1) {
      return buildPage(data.Tabs[0]);
    } else {
      return (
        <Tabs className="grow" defaultIndex={activeTabIndex}>
          <TabList>
            {data.Tabs.map((x, i) => {
              return (
                <Tab key={`page-tab-${i}`}>
                  <span dangerouslySetInnerHTML={{ __html: x.Name }}></span>
                </Tab>
              );
            })}
          </TabList>
          {data.Tabs.map((x, i) => {
            return <TabPanel key={`page-tab-${i}`}>{buildPage(x)}</TabPanel>;
          })}
        </Tabs>
      );
    }
  };

  const buildPage = (page: IPageContent) => {
    if (page.Type === 'PhotoGallery') {
      return <PhotoGallery state={props} />;
    }

    if (!page.Content) {
      return (
        <div
          className={`main-body-page grow ${props.ShowEdit && page.ContentKey ? 'edit' : ''}`}
          onDoubleClick={(e) => {
            openEditContent(e, page);
          }}>
          {props.ShowEdit && <strong>You Can't Edit Me</strong>}
          <h2>{page.Name}</h2>This page is blank.
        </div>
      );
    }

    if (page.Type === 'Poster') {
      return (
        <div className={`main-body-page poster`}>
          <img alt="Main Poster" src={page.Content} />
        </div>
      );
    }

    return (
      <div
        className={`main-body-page grow ${props.ShowEdit && page.ContentKey ? 'edit' : ''}`}
        onDoubleClick={(e) => {
          openEditContent(e, page);
        }}
        dangerouslySetInnerHTML={{ __html: page.Content }}></div>
    );
  };

  const register = () => {
    let errors: string[] = [];
    if (!registerForm.Email) errors.push('Email is required to register.');
    if (!registerForm.FirstName) errors.push('First name is required to register.');
    if (!registerForm.LastName) errors.push('Last name is required to register.');
    if (!registerForm.Token) {
      const w: any = window;
      registerForm.Token = w.x_reCaptcha;
    }
    if (!registerForm.Token && StoreX.Settings?.ReCaptchaRequiredToRegister) errors.push('Are you a robot? 01011010');

    if (errors.length > 0) {
      if (errors.length === 1) toast.warning(errors[0]);
      else
        toast.warning(
          <ul>
            {errors.map((x) => {
              return <li>{x}</li>;
            })}
          </ul>
        );

      return;
    }

    let url = `${StoreX.BuildUrlWithFair('/register.aspx')}&email=${registerForm.Email}&first=${registerForm.FirstName}&last=${registerForm.LastName}&token=${registerForm.Token}`;

    window.location.href = url;
  };

  const showContentEditor = () => {
    //show stuff...
    setShowModalContentEditor(true);
  };

  return (
    <>
      {props.Settings && props.Settings.UseOldStyling && branchTask && branchTask.Type && !showBranch && (
        <div className="flex-between">
          <div
            className={`branch-entry-bar click grow ${branchTask.ClassName ?? ''}`}
            onClick={() => {
              setShowBranch(true);
            }}>
            <div className="entry-bar-text click">{branchTask.EntryBarText ?? 'Can I Help?'}</div>
            <div>Click here to access guide</div>
          </div>
          {branchTask.SubBranch && (
            <div
              className={`branch-entry-bar click ${branchTask.SubBranch.ClassName ?? ''}`}
              onClick={() => {
                setShowSubBranch(true);
              }}>
              <div className="entry-bar-text click">
                <div dangerouslySetInnerHTML={{ __html: branchTask.SubBranch.Title }}></div>
              </div>
              <div>{branchTask.SubBranch.EntryBarText ?? 'Click Here'}</div>
            </div>
          )}
        </div>
      )}
      {data && props.Settings && (
        <>
            <div className="main-body" id="main-body">
              {props.User && props.User.Admin && data && data.ConfigKey && !props.Settings?.UseOldStyling && (
                <div className="edit-content-btn">
                  <Button type={'edit-dots'} iconOnly={true} extraSmall={true} onClick={showContentEditor} />
                </div>
              )}
              {mapContent()}
              {props.Settings.UseOldStyling && data.ShowRegistraitonOnHomePage && (
                <div className="main-body-side-bar">
                  <div className="soft-border">
                    <UserLogin inModal={false}></UserLogin>
                  </div>

                  <div className="soft-border headroom-xl">
                    <h3>{StoreX.Settings?.text.RegisterTitle ?? 'Sign-Up Now'}</h3>
                    <div className="form-horizontal">
                      <div className="row">
                        <div className="form-group">
                          <label className="col-md-5 control-label" htmlFor="FirstName">
                            First Name
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              value={registerForm.FirstName}
                              onChange={(e) => {
                                setRegisterForm({
                                  ...registerForm,
                                  FirstName: e.target.value,
                                });
                              }}
                              maxLength={50}
                              id="FirstName"
                              name="FirstName"
                              className="form-control"
                              placeholder="First Name"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-md-5 control-label" htmlFor="LastName">
                            Last Name
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              value={registerForm.LastName}
                              onChange={(e) => {
                                setRegisterForm({
                                  ...registerForm,
                                  LastName: e.target.value,
                                });
                              }}
                              maxLength={50}
                              id="LastName"
                              name="LastName"
                              className="form-control"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-md-5 control-label" htmlFor="Email">
                            Email
                          </label>
                          <div className="col-md-7">
                            <input
                              type="email"
                              value={registerForm.Email}
                              onChange={(e) => {
                                setRegisterForm({
                                  ...registerForm,
                                  Email: e.target.value,
                                });
                              }}
                              maxLength={50}
                              onKeyPress={(e) => {
                                console.log(e);
                                if (e.key == 'Enter') {
                                  e.preventDefault();
                                  register();
                                }
                              }}
                              id="Email"
                              name="Email"
                              className="form-control"
                              placeholder="Email Address"
                            />
                          </div>
                        </div>
                        {StoreX.Settings?.ReCaptchaRequiredToRegister && (
                          <div className="form-group">
                            <div className="col-md-12">
                              <MyCaptcha
                                fieldChanged={(k, v) => {
                                  console.log('captcha', v);
                                  setRegisterForm({ ...registerForm, Token: v[0] });
                                }}></MyCaptcha>
                            </div>
                          </div>
                        )}
                        <div className="form-group">
                          <div className="">&nbsp;</div>
                          <div className="col-md-9 col-md-offset-3 text-right">
                            <button type="button" className="btn btn-secondary btn-sm" onClick={register}>
                              <i className="fa fa-user-plus"></i> {props.Settings?.text.RegisterTitle ?? 'Register'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
        </>
      )}
      {data?.IsStaff && activePage && showEditor && (
        <>
          <PageContentEditor contentKey={activePage.ContentKey} show={showEditor} close={onEditorClose} onSave={onEditorSave} />
        </>
      )}

      {branchTask && branchTask.Type && (
        <>
          {showBranch && (
            <Modal
              setModalOpen={setShowBranch}
              noClose={branchTask.Required}
              title={branchTask.Title ?? 'Stickman Stu is here to help'}
              size="l"
              noCloseOnBackgroundClick={branchTask.Required || branchTask.AutoFocus}>
              <BranchPortal type={branchTask.Type} />
            </Modal>
          )}
          {showSubBranch && branchTask.SubBranch && (
            <Modal
              setModalOpen={setShowSubBranch}
              noClose={branchTask.SubBranch.Required}
              title={branchTask.SubBranch.EntryBarText ?? branchTask.SubBranch.Title ?? 'Stickman Stu is here to help'}
              size="l"
              noCloseOnBackgroundClick={branchTask.SubBranch.Required || branchTask.SubBranch.AutoFocus}>
              <BranchPortal type={branchTask.SubBranch.Type ?? ''} />
            </Modal>
          )}
          {data && data.ConfigKey && showModalContentEditor && (
            <Modal setModalOpen={setShowModalContentEditor} title="Page Editor" size="xl">
              {/* <SetupWizard wizardKey={data.ContentKey ?? ''} /> */}
              <BranchPortal type={'721f239c-98db-4b8c-a582-0bd6b6c75497'} />
            </Modal>
          )}
        </>
      )}
    </>
  );
};

//export default withRouter(BasePageContent);

const mapStateToProps = (reduxState) => {
  return reduxState.main;
};

export default withRouter(ReduxMap(BasePageContent));
