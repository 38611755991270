import React, { useEffect, useState } from 'react';

interface IconProps {
  type: IconType|string;
  addClass?: string;
  title?:string;
  flipHorizontal?:boolean;
  flipVertical?:boolean;
  rotate?:'270'|'90'|'180';
}








export enum IconType {
  status = "fal fa-clipboard-list-check",
  print = "fa fa-print",
  filter = "fa fa-filter",
  agree = "fas fa-comment-check",
  invoice = "fas fa-file",
  sign = "fal fa-signature",
  download = "fad fa-cloud-download",
  upload = "fad fa-cloud-upload",
  up = "fa fa-arrow-up",
  down = "fa fa-arrow-down",
  copy = "fad fa-copy",
  append = "fad fa-copy",
  info = "fad fa-square-info",
  on = "fad fa-toggle-on",
  off = 'fad fa-toggle-off',
  comments = 'fad fa-comments',
  back = "fa fa-arrow-left",
  next = "fa fa-arrow-right",
  school = 'fad fa-school',
  checkMoney = 'fad fa-money-check',
  stars = 'fad fa-stars',
  creditCard = 'fad fa-credit-card-front',
  skip = 'fal fa-forward',
  save = 'fad fa-save',
  edit = 'fa fa-edit',
  dots ='fa fa-ellipsis',
  new = 'fad fa-stars',
  firstTime = 'fad fa-traffic-light-go',
  missing = 'fad fa-location-pin-slash',
  power = 'fal fa-bolt',
  project = 'fad fa-box-alt',
  projectNew = 'fad fa-box-open',
  poll = 'fad fa-poll',
  entry = 'fad fa-box-alt',
  delete = 'fal fa-trash-alt',
  thumbsUp = 'far fa-thumbs-up',
  thumbsDown = 'far fa-thumbs-down',
  stopWatch = 'fad fa-stopwatch',
  alarmClock = 'fal fa-alarm-clock',
  checkCircle = 'fad fa-check-circle',
  questionCircle = 'far fa-question-circle',
  userProfileImage = 'fal fa-user-circle',
  user = 'fa fa-user',
  userPlus = 'fa fa-user-plus',
  users = 'fa fa-users',
  team = 'fa fa-users',
  transporter = 'fa fa-transporter-1',
  tally = 'fa fa-tally',
  mapMarkerSlash = 'fad fa-map-marker-slash',
  lock = 'fad fa-lock-alt',
  personBooth = 'fad fa-person-booth',
  phone = 'fas fa-phone',
  phoneRotary = 'fas fa-phone-rotary',
  exclamation = 'fas fa-exclamation',
  warning = 'fad fa-exclamation-triangle',
  refund = 'fa fa-undo',
  refresh = 'fa fa-undo',
  undo = 'fa fa-undo',
  microphoneSlash = 'fa fa-microphone-slash',
  videoSlash = 'fa fa-video-slash',
  video = 'fa fa-video',
  ban = 'fa fa-ban',
  cancel = 'fa fa-ban',
  sirenOn = 'fa fa-siren-on',
  userClock = 'far fa-user-clock',
  userClockSolid = 'fa fa-user-clock',
  money = 'fa fa-money-bill',
  mapO = 'fa fa-map',
  arrowCircleOUp = 'fa fa-circle-up',
  thumbsOUp = 'fa fa-thumbs-up',
  usd = "fa fa-usd",
  heartbeat = 'fa fa-heart-pulse',
  checkSquareO = 'fa fa-square-check',
  squareO = 'fat fa-square',
  child = 'fa fa-child',
  gavel = 'fa fa-gavel',
  chalkboardTeacher = 'fa fa-chalkboard-teacher',
  chalkboardTeacherLite = 'fal fa-chalkboard-teacher',
  userCrown = 'fa fa-user-crown',
  star = 'fal fa-star',
  start = 'fad fa-play',
  configure = 'fad fa-cogs',
  balanceScaleRight = 'fal fa-balance-scale-right',
  language = 'fal fa-language',
  award = 'fal fa-award',
  globeAmericas = 'fal fa-globe-americas',
  tag = 'fal fa-tag',
  tags = 'fad fa-tags',
  unlock = 'fa fa-unlock-alt',
  idBadge = 'fa fa-id-badge',
  envelopeO = 'fa fa-envelope',
  mail = 'fa fa-envelope',
  letter = 'fa fa-envelope',
  textMsg = 'fa fa-comments-alt',
  link = 'fa fa-link',
  signOut = 'fad fa-sign-out',
  signIn = 'fad fa-sign-in',
  chainBroken = 'fa fa-chain-broken',
  tachometer = 'fa fa-tachometer',
  calendar = 'fal fa-calendar',
  flag = 'fal fa-flag',
  childDuo = 'fad fa-child',
  userDuo = 'fad fa-user',
  userClockDuo = 'fad fa-user-clock',
  chalkboardTeacherDuo = 'fad fa-chalkboard-teacher',
  hardHat = 'fad fa-hard-hat',
  handsHelping = 'fad fa-hands-helping',
  gavelDuo = 'fad fa-gavel',
  paperPlane = 'far fa-paper-plane',
  send = 'far fa-paper-plane',
  trashO = 'fa fa-trash-can',
  remove = 'fa fa-trash-can',
  close = 'fal fa-times-square',
  exit = 'fal fa-times-square',
  plus = 'fa fa-plus',
  minus = 'fa fa-minus',
  shoppingCart = 'fa fa-shopping-cart',
  fileInvoice = 'fad fa-file-invoice',
  filePdf = 'fad fa-file-pdf',
  film = 'fad fa-film',
  payNow = 'fad fa-credit-card',
  redo = 'fa fa-redo',
  magic = 'fad fa-wand-magic',
  eye = 'fad fa-eye',
  eyeNo = 'fad fa-eye-slash',
  build = 'fad fa-construction',
  dashboard = 'fad fa-tachometer-alt-fast',
  checklist = "fad fa-clipboard-list-check",
  find = "fas fa-search",
  search = "fas fa-search",
  expand = "fas fa-expand",
  compress = "fas fa-compress",
  UserCheck = "fas fa-user-check",
  expandDown = 'fa fa-angle-down',
  expandUp = 'fa fa-angle-up',
  image = 'fal fa-image',
  qrCode = 'fa fa-qrcode',
  and = 'fa fa-link',
  or = 'fa fa-link-slash',
  greaterThan = 'fa fa-greater-than',
  lessThan = 'fa fa-less-than',
  equal = 'fa fa-equals',
  notEqual = 'fa fa-not-equal',
  contains = 'fa fa-check-square',
  notContains = 'fa fa-square',
  before = 'fa fa-step-backward',
  after = 'fa fa-step-forward',
  grip = "fa fa-grip-horizontal",
  random = 'fad fa-random',
  circle = 'fal fa-circle',
  done = 'fa-duotone fa-badge-check',
  pending = 'fa-duotone fa-hourglass-half'
} 

const Icon = (props: IconProps) => {
  const [classes, setClasses] = useState<string>('');

  useEffect(()=>{
    let values:string[] = [];

    if(props.addClass) values.push(props.addClass);
    if(props.flipHorizontal) values.push('fa-flip-horizontal');
    if(props.flipVertical) values.push('fa-flip-vertical');
    if(props.rotate) values.push(`fa-rotate-${props.rotate}`);

    setClasses(values.join(' '));
  },[props]);

  return <i className={`${props.type} ${classes}`} title={props.title} />
}

export default Icon;
