import { UploadType } from '../Components/Import/ParticipantsUpload';

import { v4 as guid } from 'uuid';

export function splitCSV(line: string, type: UploadType) {
  let arr;
  switch (type) {
    case 'comma':
      let addedSpaces = line.replace(/,,/g, ', , ');
      arr = addedSpaces.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g);
      break;
    case 'tab':
      arr = line.split('\t');
      break;
  }
  arr = arr || [];
  let fixed:string[] = arr.map((x) => (x.charAt(0) === '"' ? x.slice(1, -1) : x).trim());
  
  return fixed;
}

export function csvLine(lines: string[], lineIndex: number) {
  let index = lineIndex;
  let line = lines[index];
  let inCell = false;
  let inQuote = false;
  let letCharWasQuote = false;
  let indexes = [index];

  for (let i = 0; i < line.length; i++) {
    let startIndex = i;
    if (line[i] === '"') {
      //quoted csv value
      //find end quote
      let endFound = false;
      i++;
      while (!endFound) {
        for (; i < line.length; i++) {
          if (line[i] === '"') {
            if (line[i + 1] === '"') {
              i++;
            } else {
              //this is the end...
              endFound = true;
              break;
            }
          }
        }

        if (endFound) {
          if (indexes.length > 1) console.log('indexes found', indexes);
        } else if (lines.length > index) {
          //add another row to this line
          index++;
          indexes.push(index);
          line += lines[index];
        } else {
          console.log('End found; csv cell value end not found.');
        }
      }
      if (!endFound) {
        //end not found
        console.log('CSV End Not Found', indexes);
        return indexes;
      }
    } else if (line[i] === ',') {
      continue;
    } else {
      //find next cell
      for (; i < line.length; i++) {
        if (line[i] === ',') {
          //this is the end...
          break;
        }
      }
    }
  }
  return indexes;
}

export function formatPin(pin: string) {
  if (!pin) return null;
  if (pin.length <= 4) return pin;
  if (pin.length >= 5 && pin.length < 8) {
    return pin.substring(0, 3) + '-' + pin.substring(3);
  }
  if (pin.length === 8) {
    return pin.substring(0, 4) + '-' + pin.substring(4);
  }
  if (pin.length >= 9) {
    return pin.substring(0, 3) + '-' + pin.substring(3, 6) + '-' + pin.substring(6);
  }
  return pin;
}

export const formatMoney = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const arrayTools = {
  Copy: (array: any[]) => {
    return [...array];
  },
  // MoveElementInArray: (array:any[], sourceIndex:number, destinationIndex:number) => {
  //     if (sourceIndex == destinationIndex) return array;

  //     let moved:any[] = array.splice(sourceIndex, 1)[0];
  //     const newArray = [];

  //     if (sourceIndex < destinationIndex) {
  //         destinationIndex--;
  //     }

  //     for (var i = 0; i < this.data.length; i++) {
  //         newArray.push(this.data[i]);

  //         if (i == destinationIndex) {
  //             newArray.push(moved);
  //         }

  //     }

  //     return newArray;
  // },
  InsertAt: (array: any[], index: number, ele: any) => {
    const newArray: any[] = [];
    let set = false;
    for (let i = 0; i < array.length; i++) {
      if (index == i) {
        newArray.push(ele);
        set = true;
      }
      newArray.push(array[i]);
    }

    if (!set) newArray.push(ele);

    return newArray;
  },
  PopAt: (array, index) => {
    return array.splice(index, 1)[0];
  },
};

export const ToDate = (value: string | number, ignoreTimezone?: boolean) => {
  let currentDate = new Date();
  let hours = ignoreTimezone ? 0 : currentDate.getTimezoneOffset()/60;
  let timeOffsetInMS: number = hours * 60 * 60 * 1000;

  if (typeof value === 'string' && (value.indexOf('/Date(') > -1 || value.indexOf('/DATE(') > -1)) {
    let date2 = new Date(parseInt(value.substring(6))); 
    //MVC - set timezone kind IE: DateTime.SpecifyKind(x.registeredAt, DateTimeKind.Utc);
    //Timezone is auto taken care of...
    //date2.setTime(date2.getTime() - timeOffsetInMS);

    return date2;
  }  else if((value+'').match(/[0-9]{4}[-]{1}[0-9]{1,2}[-]{1}[0-9]{1,2}[T]{1}/g))
  {
    let parts = (value+'').split('T');
    let values = parts[0].split('-');
    let timeValues = parts[1].split(':');
    let date2 = new Date(+values[0], +values[1]-1, +values[2], +timeValues[0], +timeValues[1]);
    //console.log(date2, 'Here');
    if (ignoreTimezone) {
      date2.setTime(date2.getTime() - date2.getTimezoneOffset() * 60000);
    } else {
      date2.setTime(date2.getTime() - timeOffsetInMS);
    }
    return date2;
  }  else if((value+'').match(/[0-9]{4}[-]{1}[0-9]{1,2}[-]{1}[0-9]{1,2}/g))
  {
    let values = (value+'').split('-');
    let date2 = new Date(+values[0], +values[1]-1, +values[2]);
    //console.log(date2, 'Here');
    return date2;
  }  
  else {
    let date2 = new Date(value);
    if (ignoreTimezone) {
      date2.setTime(date2.getTime() - date2.getTimezoneOffset() * 60000);
    } else {
      date2.setTime(date2.getTime() - timeOffsetInMS);
    }
    //console.log(date2);
    return date2;
  }
};

export const FormatTimeToInput = (value: string) => {
  if (!value) return '';

  let isPm = value.toLowerCase().indexOf('pm') > -1;
  value = value.replace(/[^0-9:]/g, '');
  let values = value.split(':');
  if (isPm) {
    values[0] = +values[0] + 12 + '';
  }
  return values.join(':');
};

export const FormatDate = (value: string | Date | undefined, format?: 'input' | 'date' | 'date-time' | 'time', ignoreTimezone?: boolean) => {
  if (!value) return undefined;
  let date = value instanceof Date ? value : ToDate(value, ignoreTimezone);
  if (!date) return undefined;

  let hours = ignoreTimezone ? date.getUTCHours() : date.getHours();
  let min: string | number = ignoreTimezone ? date.getUTCMinutes() : date.getMinutes();
  let pm = false;
  if (hours > 11) pm = true;
  if (hours === 0) hours = 12;
  else if (hours > 12) hours -= 12;
  if (min < 10) min = '0' + min;

  let noTime = date.getSeconds() === 0 && date.getHours() === 0 && date.getMinutes() === 0 && date.getMilliseconds() === 0;

  switch (format ?? 'date-time') {
    case 'input':
      let month = (date.getUTCMonth() + 1 + '').padStart(2, '0');
      let day = (date.getUTCDate() + '').padStart(2, '0');
      const value = `${date.getUTCFullYear()}-${month}-${day}`;
      return value;
      //return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      break;
    case 'date':
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      break;
    case 'time':
      return `${hours}:${min} ${pm ? 'pm' : 'am'}`;
      break;
    case 'date-time':
    default:
      if (noTime) return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${hours}:${min} ${pm ? 'pm' : 'am'}`;
      break;
  }
};

export const ValueTruthy = (value: string) => {
  if (value === undefined || value === null) return false;
  value = ((value ?? '') + '').toUpperCase();
  if (!value) return false;
  if (value === 'N') return false;
  if (value === 'NO') return false;
  if (value === '0') return false;
  if (value === 'FALSE') return false;
  if (value === 'F') return false;
  return true;
};

export const CleanVideoUrl = (url: string) => {
  let alteredUrl: string;

  if (url.includes('.be/')) {
    // shortened youtube link
    alteredUrl = `https://youtube.com/embed/${url.split('.be/')[1]}?modestbranding=1`;
  } else if (url.includes('watch?v=')) {
    //long youtube link
    alteredUrl = url.replace('watch?v=', 'embed/');
  } else if (url.toLowerCase().includes('youtube.com/shorts')) {
    alteredUrl = `https://youtube.com/embed/${url.split('/shorts/')[1]}?modestbranding=1`;
  } else {
    alteredUrl = url;
  }

  let value = alteredUrl;
  let charArray: string[] = [];
  let inqueryString = false;

  for (let i = 0; i < value.length; i++) {
    if (value[i] === '?') {
      if (!inqueryString) {
        inqueryString = true;
      } else {
        charArray.push('&');
        continue;
      }
    }
    charArray.push(value[i]);
  }
  value = charArray.join('');
  alteredUrl = value;

  if (alteredUrl.indexOf('&v=') > -1) {
    alteredUrl = `https://youtube.com/embed/${alteredUrl.split('&v=')[1]}?modestbranding=1`;
  }
  if (alteredUrl.indexOf('?v=') > -1) {
    alteredUrl = `https://youtube.com/embed/${alteredUrl.split('?v=')[1]}?modestbranding=1`;
  }

  if (alteredUrl.toLowerCase().indexOf('google.com') > 0 && alteredUrl.toLowerCase().indexOf('/view')) {
    alteredUrl = alteredUrl.replace('/view', '/preview');
  }

  if (alteredUrl.toLowerCase().indexOf('youtube') > 0 && alteredUrl.indexOf('&') > 0) {
    alteredUrl = alteredUrl.substring(0, alteredUrl.indexOf('&'));
  }

  return alteredUrl;
};

export const NewGuid = () => {
  return guid();
};
