import StoreX from "../../redux/oldStore";
import React, { useState, useEffect } from "react";
import redux, { IState, ReduxMap } from "../../redux/redux";

interface IFirstTimeCompleteProps extends IState{
  Type: "FirstTime" | "ProfileUpdate";
}
const FirstTimeLoginComplete = (props:IFirstTimeCompleteProps) => {
  const [pageText, setPageText] = useState("");

  useEffect(() => {
    if(props.Settings?.text){
      switch (props.Type) {
        case "FirstTime":
          setPageText(props.Settings?.text.FirstTimeLoginCompletePageBody);
          break;
        case "ProfileUpdate":
          setPageText(props.Settings?.text.ProfileUpdateCompletePageBody);
          break;
      }
    }
  }, [props.Settings]);

  useEffect(()=>{
    redux.LoadSettings(true);
  },[]);

  return (
    <>
      <div className="col-sm-12">
        <h3>Thank you</h3>
        <hr />
        {pageText && <div dangerouslySetInnerHTML={{ __html: pageText }} />}
      </div>
    </>
  );
};

export default ReduxMap(FirstTimeLoginComplete);
