import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore.ts";
import { Editor } from "react-draft-wysiwyg";
import { toast } from "react-toastify";

import io from "socket.io-client";

export default function StudentReviewPortal(props) {
  const[inQueue, setInQueue] = useState(false);
  const[socket, setSocket] = useState();
  const[info, setInfo] = useState();
  const[lineOpen, setLineOpen] = useState();

  useEffect(() => { 


      let url =
        window.location.host.indexOf("localhost") > -1
          ? "http://localhost:9191/online-judging"
          // ? "http://10.25.0.1:9191/online-judging"
          : "https://socket.zfairs.com/online-judging";
    let info;
    store.server
      .postApi("../Hinge/Info", {})
      .then((res) => {
        console.log("info response", res);
        if(res.Success){
        info = res.Value;

        let socket = io(url, {
          query: {
            fairId: info.fairId,
            clientId: info.clientId,
            personId: info.user.id,
            projectId: info.projectKey,
            isReviewBoardMember:false,    
            reviewBoard:true,
          },
          transports: ['websocket', 'polling']      
        });
        setSocket(socket);
        setInfo(info);

        socket.emit('review-board-waiting-room-joined', {  
          projectId: info.projectKey, 
        });
      } else {
        toast.error(res.Message, {autoClose:false});
      }
      })
      .catch((err) => console.log(err));

  }, []);

  const enterQueue = () =>{
    socket.emit('review-board-line-enter', {  
      projectId: info.projectKey, 
    })

    toast.info('Entered review line');
    setInQueue(true);
  };
 
  const leaveQueue = () =>{
    socket.emit('review-board-line-leave', {  
      projectId: info.projectKey    
    })

    toast.info('Left review line');
    setInQueue(false);
  };
  return (
    <>
      Student Review...
      {!inQueue && <button type="button" onClick={enterQueue}>Enter Queue</button>}
      {inQueue && <button type="button" onClick={leaveQueue}>Leave Queue</button>}
    </>
  );
}
