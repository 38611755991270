import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import StoreX, { store } from '../../redux/oldStore';
import { IState, ReduxMap } from '../../redux/redux';
import { ServerResponse } from '../../utils/Server';
import Icon, { IconType } from '../Icon/Icon';

interface ISignInRequest {
  SignedIn: boolean;
  Message: string;
  RedirectTo: string;
}

interface IProps extends IState {
  inModal: Boolean;
}

const UserLogin = (props: IProps) => {
  const { inModal } = props;
  const [loginUsername, setLoginUsername] = useState<string>('');
  const [loginPassword, setLoginPassword] = useState<string>('');
  const [quickSignIn, setQuickSignIn] = useState<string>('');

  const handleSignIn = () => {
    let request = { password: loginPassword, username: loginUsername };

    if (request.password == null || request.username == null || request.password.length === 0 || request.username.length === 0) {
      toast.warning('Please enter your username & password!');
      return;
    }

    store.server.postApi<ServerResponse<ISignInRequest>>(`../Person/SignIn`, request).then((r) => {
      if (r.Success) {
        const data = r.Value;
        if (data.RedirectTo) {
          if (data.Message) toast.info(data.Message);
          window.location.href = data.RedirectTo;
          return;
        }
        if (data.SignedIn) {
          window.location.reload();
        } else {
          toast.info(data.Message, { autoClose: 15000 });
          setLoginPassword('');
          setQuickSignIn('');
        }
      } else {
        toast.error(r.Message, { autoClose: false });
      }
    });
  };

  const handleSignInQuick = () =>{
    const request = {key:quickSignIn};

    store.server.postApi<ServerResponse<ISignInRequest>>(`../Person/SignInQuick`, request).then((r) => {
      if (r.Success) {
        const data = r.Value;
        if (data.RedirectTo) {
          if (data.Message) toast.info(data.Message);
          window.location.href = data.RedirectTo;
          return;
        }
        if (data.SignedIn) {
          window.location.reload();
        } else {
          toast.info(data.Message, { autoClose: 15000 });
          setLoginPassword('');
          setQuickSignIn('');
        }
      } else {
        toast.error(r.Message, { autoClose: false });
      }
    });
  };

  const onLoginEnterPress = (e) => {
    if ((e.key === 'Enter' || e.key === 'NumpadEnter') && e.shiftKey === false) {
      e.preventDefault();
      handleSignIn();
    }
  };

  return (
    <div>
      {props.Settings && (
        <>
          {!inModal && <h2>{props.Settings.text.NavLogin}</h2>}
          <div className="form-horizontal">
            <div className="form-input">
              <label htmlFor={'login-username'}>Username</label>
              <input
                type="text"
                id="login-username"
                maxLength={50}
                autoFocus={true}
                value={loginUsername}
                onChange={(e) => {
                  setLoginUsername(e.target.value);
                }}></input>
            </div>
            <div className="form-input">
              <label htmlFor={'login-password'}>Password</label>
              <input
                type="password"
                id="login-password"
                maxLength={50}
                onKeyDown={onLoginEnterPress}
                value={loginPassword}
                onChange={(e) => {
                  setLoginPassword(e.target.value);
                }}></input>
            </div>
            <div className="form-input">
              <div className="grow  flex-between">
                <button type="button" className="btn btn-secondary login-form-button" onClick={handleSignIn}>
                  <Icon type={IconType.signIn} />
                  {props.Settings.text.NavLogin}
                </button>
                {props.SettingsResponse && (
                  <a href={`${props.SettingsResponse.Url}?f=${props.Settings.fairId}&login=1&forgot=1`} title="recover username and/or password">
                    help
                  </a>
                )}
              </div>
            </div>
            {props.Settings.LoginWithEnabled && (
              <div className="tight head-room row">
                <div className="col-sm-4">
                  <a target="_blank" href={StoreX.BuildUrlWithFair(`/Auth/Login?providerName=Google&oathType=REGISTER`)} className="btn btn-default btn-block btn-sm">
                    <i className="fa-brands fa-google"></i>&nbsp;Google
                  </a>
                </div>
                <div className="col-sm-4">
                  <a target="_blank" href={StoreX.BuildUrlWithFair(`/Auth/Login?providerName=WindowsLive&oathType=REGISTER`)} className="btn btn-default btn-block btn-sm">
                    <i className="fa-brands fa-windows"></i>&nbsp;Windows
                  </a>
                </div>
                <div className="col-sm-4">
                  <a target="_blank" href={StoreX.BuildUrlWithFair(`/Auth/Login?providerName=Facebook&oathType=REGISTER`)} className="btn btn-default btn-block btn-sm">
                    <i className="fa-brands fa-facebook"></i>&nbsp;Facebook
                  </a>
                </div>
              </div>
            )}

            {props.Settings.showQuickSignInOption && (
              <div className="tight head-room row">
                <hr/>
                <h4>Sign-In Use Quick Sign-In Key</h4>
                <div className="form-horizontal">
                  <div className="form-input">
                    <label htmlFor={'quick-sign-in'} className=''>Key</label>
                    <input
                      type="text"
                      id="quick-sign-in"
                      placeholder='xxx-xxx-xxx'
                      maxLength={50}
                      value={quickSignIn}
                      onChange={(e) => {
                        setQuickSignIn(e.target.value);
                      }}></input>
                  </div>
                  <div className="form-input">
                    <div className="grow  flex-between">
                      <button type="button" className="btn btn-default login-form-button" onClick={handleSignInQuick}>
                        <Icon type={IconType.magic} />
                        Sign-In Using Key
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ReduxMap(UserLogin);
