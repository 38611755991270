import React, { useState, useEffect } from 'react';
import RubricCategory from './RubricCategory';
import AddOrEditCategory from './AddOrEditCategory';
import Modal from '../../Modal/Modal';
import { store } from '../../../redux/oldStore';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import QuestionCreator from '../../QuestionCreation/QuestionCreator';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Rubric } from '../../../utils/CommonTypes/CommonTypes';
import QuickComments from './QuickComments/QuickComments';
import queryString from 'query-string';


interface RubricInSetup {
  Categories?: Category[]; //[{…}]
  CategoryIds?: string[]; //[3]
  Id?: string; //15
  Interview?: boolean; //false
  IsDefault?: boolean; //true
  Name?: string; //"Speech"
  Speech?: boolean; //true
  SubCategoryIds?: number[] | null;
  SubCategories?: any[];
}

interface Category {
  Assessments: Assessment[]; //[{…}]
  Id: string; //"9d3e4cab-259c-4721-aa3b-8f3d4ecf6158"
  Name: string; //"Custom Enter Score"
  Sort: number; //40
  TeamOnly: boolean; //false
  Value: number;
}

interface Assessment {
  AllowNa: boolean; //false
  Description: string | null; //null
  Id: string; //"7f099b1e-fda3-4fe5-88a2-75fa1802b4fd"
  IsPenaltyAssessment: boolean; //false
  MaxValue: number; //99
  MinValue: number; //-1
  Question: string; //"Score"
  ScoreType: number; //25
  Sort: number; //0
  ValueOverride: number; //
}

interface JudgeSettings {
  IsAcaDeca: boolean // { get; set; }
  AutoPlopEnabled: boolean // { get; set; }
  UseOnlineJudging: boolean // { get; set; }
}

interface QueryInfo {
  rubricId?: string;
}

interface FullRubricInfo {
  rubrics: any[];
  categories: any[];
  subCategories: any[];
}


const RubricSetup = (props: RouteComponentProps) => {
  // const {
  //   rubric,
  //   setRubric,
  //   fullRubricInfo,
  //   setFullRubricInfo,
  //   exit,
  //   settings
  // } = props

  const [rubric, setRubric] = useState<RubricInSetup | undefined>();
  const [fullRubricInfo, setFullRubricInfo] = useState<FullRubricInfo | undefined>();
  const [settings, setSettings] = useState<any>()

  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [addOrEditCategoryModalShow, setAddOrEditCategoryModalShow] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(rubric?.Name ? false : true);
  const [editName, setEditName] = useState<boolean>(rubric?.Name ? false : true);
  const [nameValue, setNameValue] = useState<string>(rubric?.Name ? rubric?.Name : '');
  const [isDefault, setIsDefault] = useState<boolean>(rubric?.IsDefault ?? false);
  const [isSpeech, setIsSpeech] = useState<boolean>(rubric?.Speech ?? false);
  const [isInterview, setIsInterview] = useState<boolean>(rubric?.Interview ?? false);
  const [nameError, setNameError] = useState<boolean>(false);

  // these are bloat from me trying to figure out how to format the options for the Select component, but then also bringing it back to just Ids for submission. I guarantee there is a better way to handle this.
  const [selectedRubricCategories, setSelectedRubricCategories] = useState<any[]>([]);
  const [selectedRubricSubCategories, setSelectedRubricSubCategories] = useState<any[]>([]);
  const [convertedRubricCategories, setConvertedRubricCategories] = useState<any[]>([]);
  const [convertedRubricSubCategories, setConvertedRubricSubCategories] = useState<any[]>([]);
  const [formattedCategories, setFormattedCategories] = useState<any[]>([]);
  const [formattedSubCategories, setFormattedSubCategories] = useState<any[]>([]);

  const getQueryInfo = () => {
    const info: QueryInfo = queryString.parse(props.location.search);
    return info;
  }
  
  useEffect(() => {
    //TODO: call to get the rubric info.
    store.server.postApi<JudgeSettings>("../JudgingManagement/settings", {}).then((res: any) =>{ 
      setSettings(res.Value)
    });
    store.server.postApi<any>('../Rubric/GetRubric', {RubricId: getQueryInfo().rubricId}).then((res: any) => {
      console.log('!!!!!!!!!!! ', res);
      setRubric(res.Value);
    });
    store.server
      .postApi("../Rubric/Info", {})
      .then((res: any) => {
        console.log("rubric call info result", res);
        setFullRubricInfo(res.Value);
        // toast.success('got rubric info')
      })
      .catch((err) => {
        console.error(err);
        toast.warning("did not get rubric info");
      });
  }, [])

  // useEffect(() => {
  //   if (rubric.Id === 0 && !rubric.Name) {
  //     setRubric({
  //       Id: 0,
  //       Name: 'Click to Name Rubric',
  //       IsDefault: false,
  //       Speech: false,
  //       Interview: false,
  //       Categories: []
  //     })
  //   }
  // }, [rubric, setRubric])

  useEffect(() => {
    if (fullRubricInfo && rubric?.Id) {

    if (!fullRubricInfo.categories) fullRubricInfo.categories = []
    if (!fullRubricInfo.subCategories) fullRubricInfo.subCategories = []

    let blockedFormattedCategories = fullRubricInfo.categories.map(cat => {
      return {
        value: cat.id,
        label: cat.name
      }
    })
    let blockedFormattedSubCategories = fullRubricInfo.subCategories.map(cat => {
      return {
        value: cat.id,
        label: cat.name
      }
    })
    setFormattedCategories(blockedFormattedCategories);
    setFormattedSubCategories(blockedFormattedSubCategories);

    let passedInSelected = rubric.CategoryIds?.map(id => blockedFormattedCategories.filter(category => category.value === id)).flat();

    // console.log('this is the passed in selected', passedInSelected);

    let passedInSubSelected = rubric.SubCategoryIds?.map(id => blockedFormattedSubCategories.filter(category => category.value === id)).flat();

    setSelectedRubricCategories(passedInSelected ?? []);
    setSelectedRubricSubCategories(passedInSubSelected ?? []);

  }

    setShowSettings(rubric?.Name ? false : true);
    setEditName(rubric?.Name ? false : true);
    setNameValue(rubric?.Name ? rubric?.Name : '');
    setIsDefault(rubric?.IsDefault ?? false);
    setIsSpeech(rubric?.Speech ?? false);
    setIsInterview(rubric?.Interview ?? false);


  }, [rubric, fullRubricInfo])

  useEffect(() => {
    setConvertedRubricCategories(selectedRubricCategories?.map(cat => cat.value))
  }, [selectedRubricCategories])

  useEffect(() => {
    setConvertedRubricSubCategories(selectedRubricSubCategories?.map(cat => cat.value))
  }, [selectedRubricSubCategories])


  const editOrAddCategory = (category) => {
    if (!rubric?.Name) {
      setEditName(true);
      setNameError(true);
      toast.warning('please save the rubric name before adding categories');
      return;
    }
    setSelectedCategory(category)
    setAddOrEditCategoryModalShow(true);
  }

  const handleSaveCategory = async (categoryObj) => {
    console.log('hit save. Look for Assessments', categoryObj)
    if (rubric?.Id) {

      if (!rubric.Categories) rubric.Categories = []
      let [foundCategory] = rubric.Categories.filter(x => x.Id === categoryObj.Id)
      let updatedRubric;
      if (!foundCategory) {
        updatedRubric = { ...rubric, Categories: [...rubric.Categories, categoryObj] };
      } else {
        updatedRubric = { ...rubric, Categories: rubric.Categories.map(x => x.Id === categoryObj.Id ? { ...x, ...categoryObj } : x) };
      }
      
      await handleSave(updatedRubric);
      setAddOrEditCategoryModalShow(false);
    }

  }

  const handleSaveSettings = async () => {
    let rubricObj = {
      ...rubric,
      CategoryIds: convertedRubricCategories,
      SubCategoryIds: convertedRubricSubCategories,
      IsDefault: isDefault,
      Speech: isSpeech,
      Interview: isInterview
    }
    await handleSave(rubricObj)
    setShowSettings(false);
  }

  const handleSave = async (rubricObj) => {
    if (!rubricObj.Name) {
      setEditName(true);
      toast.warning('please give the rubric a name');
      return;
    }
    await store.server.postApi('../Rubric/Save', rubricObj)
      .then((res: any) => {
        
          setNameError(false);
          // let [existingRubric] = fullRubricInfo?.rubrics?.filter(r => r.Id === rubricObj.Id) ?? []
          // if (existingRubric) {
          //   setFullRubricInfo({ ...fullRubricInfo, rubrics: fullRubricInfo?.rubrics?.map(rubr => rubr.Id === rubricObj.Id ? res.Value : rubr) });
          // } else {
          //   setFullRubricInfo({ ...fullRubricInfo, rubrics: [...(fullRubricInfo.rubrics ?? []),  res.Value] })
          // }
          setRubric(res.Value);
        
      })
      .catch(err => {
        console.error(err);
        toast.warning('something went wrong with the save');
      })
  }

  const handleDeleteRubric = () => {
    let deletingRubric = rubric
    if (!window.confirm('are you sure you want to delete this rubric?')) return;
    store.server.postApi('../Rubric/Remove', deletingRubric)
      .then(res => {
        // setFullRubricInfo({ ...fullRubricInfo, rubrics: fullRubricInfo?.rubrics?.filter(rubric => rubric.Id !== deletingRubric?.Id) })
        // exit();
        // props.history.goBack();
        props.history.push('/app/judging/setup?tab=rubric')

      })
      .catch(err => {
        console.error(err)
        toast.warning("couldn't delete that one")
      })
  }

  const handleNameSave = async () => {
    let saveObj = {
      ...rubric,
      Name: nameValue
    }
    await handleSave(saveObj)
    setEditName(false);

  }

  const handleDelete = (categoryId) => {
    let modifiedRubric = {
      ...rubric,
      Categories: rubric?.Categories?.filter(category => category.Id !== categoryId)
    };
    setRubric(modifiedRubric);
    store.server.postApi('../Rubric/Save', modifiedRubric)
      .then(res => {
        // console.log(res)
        setAddOrEditCategoryModalShow(false)
        setEditName(false)
      })
      .catch(err => {
        console.error(err)
        toast.warning('something went wrong with the save')
      })
  }

  const handleDeleteAssessment = (categoryObj) => {
    let [foundCategory] = rubric?.Categories?.filter(x => x.Id === categoryObj.Id) ?? []
    let updatedRubric;
    if (!foundCategory) {
      updatedRubric = { ...rubric, Categories: [...(rubric?.Categories ?? []), categoryObj] };
    } else {
      updatedRubric = { ...rubric, Categories: rubric?.Categories?.map(x => x.Id === categoryObj.Id ? categoryObj : x) };
    }

    handleSave(updatedRubric);

  }



  let mappedRubricCategories = rubric?.Categories?.sort((a,b)=>{if(a.Sort == b.Sort)return a.Name > b.Name?1:-1; return a.Sort > b.Sort ? 1:-1}).map((category, i) => {
    return (
      <RubricCategory
        key={`${category.Id}${i}`}
        category={category}
        setCategory={setSelectedCategory}
        editOrAddCategory={editOrAddCategory}
        handleSaveCategory={handleSaveCategory}
        handleDeleteAssessment={handleDeleteAssessment}
      />

    )
  })

  const handleFocus = (e) => {
    e.target.select();
  }

  return (
    <div className='bumper-sides-l'>

      <div className='rubric-header'>

        <div className='wider' >
          <div className='flex middle' >
            {editName
              ? <div className={`form-input bumper-l ${nameError ? 'error dark-text' : ''}`}>
                <input
                  name="Rubric-Name"
                  value={nameValue}
                  onChange={(e) => setNameValue(e.target.value)}
                  onFocus={handleFocus}
                  autoFocus={true}
                  placeholder='Rubric Name'
                />
                <button
                  className='btn btn-success btn-xs bumper-sides-out rubric-name-save'
                  onClick={handleNameSave}
                >
                  <i className="far fa-save"></i> save
                </button>
                <button
                  className='btn btn-secondary btn-xs bumper-sides-out'
                  onClick={() => setEditName(false)}
                >
                  <i className="far fa-times-circle"></i> cancel
                </button>
              </div>
              : <div className='flex middle bumper-l'>
                <h3
                  onClick={() => setEditName(true)}
                  className='dont-margin'
                >
                  Rubric: {nameValue}
                </h3>
                <span
                  onClick={() => setEditName(true)}
                  className='text-info bumper-sides-l rubric-name-edit'
                >
                  <i className="far fa-pencil"></i>
                </span>
              </div>
            }
          </div>
          <div className='bumper-l'>

            {showSettings
              ? <></>
              : <button
                className='btn btn-secondary rubric-settings-btn'
                onClick={() => setShowSettings(!showSettings)}
              >
                <i className="fas fa-cog"></i>
                Settings
              </button>
            }
            {showSettings
              &&
              <div className='settings-area'>
                <div className='form-input'>
                  <label>Rubric is default:</label>
                  <input
                    type='checkbox'
                    defaultChecked={rubric?.IsDefault}
                    onChange={(e) => setIsDefault(e.target.checked)}
                  />
                </div>
                <div className='form-input'>
                  <label>Add Rubric to Categories:</label>
                  <Select
                    onChange={setSelectedRubricCategories}
                    value={selectedRubricCategories}
                    options={formattedCategories}
                    className='grow-1'
                    isMulti
                    isClearable={true}
                    menuPlacement={'auto'}
                  />
                </div>
                {formattedSubCategories?.length > 0 && <div className='form-input'>
                  <label>Add Rubric to Sub-Categories:</label>
                  <Select
                    onChange={setSelectedRubricSubCategories}
                    value={selectedRubricSubCategories}
                    options={formattedSubCategories}
                    className='grow-1'
                    isMulti
                    isClearable={true}
                    menuPlacement={'auto'}
                  />
                </div>}
                {/* {fullRubricInfo?.SubCategories
                  &&
                  <div className='form-input'>
                    <label>Add Rubric to SubCategories:</label>
                    <Select
                      onChange={setSelectedRubricSubCategories}
                      value={selectedRubricSubCategories}
                      options={formattedSubCategories}
                      className='grow-1'
                      isMulti
                  isClearable={true}
                  menuPlacement={'auto'}
                    />
                  </div>

                } */}
                {settings?.IsAcaDeca
                  && <>
                    <div className='form-input'>
                      <label>Is AcaDeca Interview:</label>
                      <input
                        type='checkbox'
                        defaultChecked={rubric?.Interview}
                        onChange={(e) => setIsInterview(e.target.checked)}
                      />
                    </div>
                    <div className='form-input'>
                      <label>Is AcaDeca Speech:</label>
                      <input
                        type='checkbox'
                        defaultChecked={rubric?.Speech}
                        onChange={(e) => setIsSpeech(e.target.checked)}
                      />
                    </div>
                  </>

                }
                <div>
                  <button
                    className='btn btn-success'
                    onClick={handleSaveSettings}
                  >
                    <i className="far fa-save"></i>
                    save settings
                  </button>
                  <button
                    className='btn btn-secondary'
                    onClick={() => setShowSettings(!showSettings)}
                  >

                    <i className="far fa-times-circle"></i>
                    close settings
                  </button>
                  <button
                    className='btn btn-danger delete-rubric-btn'
                    onClick={handleDeleteRubric}
                  >
                    <i className="far fa-trash-alt"></i>
                    delete rubric
                  </button>
                </div>
              </div>

            }
          </div>
        </div>
<div>
        <span
          className="hover-danger"
          // onClick={props.exit}
          onClick={() => props.history.push('/app/judging/setup?tab=rubric')
        }
        >
          <i className="fad fa-2x fa-portal-exit close-rubric-btn"></i>
          Close
        </span>
        </div> </div>

      <Tabs>
        <TabList>
          <Tab>Rubric Questions</Tab>
          <Tab>Custom Questions</Tab>
        </TabList>

        <TabPanel>
          <div className='rubric-categories-area'>
            {mappedRubricCategories}
          </div>
          <div className='bumper-l'>
            <button
              className='btn btn-success add-judging-category-btn'
              onClick={() => editOrAddCategory({})}
            >
              <i className="far fa-plus-circle"></i>
              Add Judging Category
            </button>
          </div>
        </TabPanel>

        <TabPanel>
          <QuestionCreator />
          <QuickComments />
        </TabPanel>
        {addOrEditCategoryModalShow
          && <Modal
            title={selectedCategory.Name ? 'Edit Category' : 'Add Category'}
            setModalOpen={setAddOrEditCategoryModalShow}
          >
            <AddOrEditCategory
              setModalOpen={setAddOrEditCategoryModalShow}
              selectedCategory={selectedCategory}
              handleSaveCategory={handleSaveCategory}
              handleDelete={handleDelete}
            />
          </Modal>
        }
      </Tabs>
    </div>

  )
}

export default withRouter(RubricSetup);