import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { IAward } from '../../redux/judgingResults/types';
import { store } from '../../redux/oldStore'
import Modal from '../Modal/Modal';
import Pdf from "react-to-pdf";


interface IAwards{
    Type:number;
    AwardName:string;
    SubName:string;
    Points:number;
    ShowGrade?: boolean;
}

interface IAwardInfo {
  canView: boolean;
  awards?: IAwards[];
  fair?: IFairInfo;
  project?: IProjectInfo;
}

interface IProjectInfo{
    students:IStudent[];
    projectId:string;
    title:string;
    category:string;
}

interface IStudent{
    name:string;
    grade:string|null;
}

interface IFairInfo{
    name:string;
    clientName:string;
    logo?:string;
}

const MyAwards = () => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [info, setInfo] = useState<IAwardInfo|null>();
  const [selectedAward, setSelectedAward] = useState<IAwards|null>();
  const [showLogo, setShowLogo] = useState<boolean>(false);
  const [nameIndex, setNameIndex] = useState<number>(0);
  const awardRef = React.createRef();
  const pdfOptions = {
    orientation: 'landscape',
    // unit: 'in',
    //format: [825,618]
}

  useEffect(() => {
    store.server.getApi('../Student/MyAwards')
      .then((res: any) => {
        console.log(res);
        setInfo(res.Value);
        setIsReady(true);
        //setShowLogo(res.Value?.fair?.logo)
      })
      .catch(err => toast.error(err))
  }, []);

  const awardSelected = (award:IAwards|null)=>{
    setSelectedAward(award);
  }

  return(
    <div className='bumper-l bumper-out-l'>
      <h3>My Awards</h3>
      <div>Click to view / print your award certificate</div>
        {isReady && info?.canView &&
        
        <>
        <div className="awards-list">
            {(!info.awards || info.awards.length === 0) && <div>
                <h5>Nothing to Show</h5>
                No awards have been found at this time.
            </div> }
            {info.awards?.map((x:IAwards, i:number)=>{
                return <div key={`award-${i}`} className="award" onClick={()=>{awardSelected(x);}}>
                    <i className={`fad ${x.Type === 1 ? 'fa-medal text-info' : x.Type === 2 ? 'fa-award text-success' : 'fa-trophy text-warning'}`}></i>
                    <div>
                        {x.AwardName}
                        <div className="award-sub">{x.SubName}</div>
                    </div>
                </div>
            })}
        </div>
        <div>
        {selectedAward &&
            <div>
                {info?.fair?.logo && <span>
                    <button type="button" className="btn btn-xs btn-default" onClick={()=>{setShowLogo(!showLogo)}}>Toggle Logo</button>
         
                </span>}
                {info.project?.students.map((x:IStudent,i:number)=>{
                    return <button type="button" key={`name-index-${i}`} className={`btn btn-xs {i === nameIndex ? 'btn-primary': 'btn-default'}`} onClick={()=>{setNameIndex(i)}}>{x.name}</button>
                })}
                <Pdf targetRef={awardRef} filename="certificate.pdf" options={pdfOptions} scale="1.29">
                    {({ toPdf }) => <button className="btn btn-xs btn-secondary" onClick={()=>{ window.scroll(0,0); toPdf();}}>Download Certificate</button>}
                </Pdf>
            </div>
        }
        </div>
        </>}
        {isReady && !info?.canView && 
        <>
            <h2>There is nothing to see at this time.</h2>
        </>}
        
        {selectedAward && 
        <div className="award-certificate">
            {info?.project?.students.map((student:IStudent,i:number)=>{
                if(nameIndex !== i) return null;
                return (
                    <div className="page" key={`page-${i}`} ref={awardRef}>          
                           <div className="header">
                                {showLogo && info?.fair?.logo && <img src={info?.fair.logo} className="award-logo"></img>}
                                <div className={`fair-name ${(showLogo && info.fair?.logo) ? 'has-logo':''}`}>{info?.fair?.clientName}</div>
                            </div>
                        {info?.fair?.clientName !== info?.fair?.name &&                 
                                                        
                                <div className="fair-name-sub">{info?.fair?.name}</div>
                        }

                        <div className="award-text">Presents</div>
                        <div className="award-name">{student.name}</div>
                        <div className="award-text">Proud Recipient Of</div>
                        <div className="award-title">{selectedAward.AwardName}</div>
                        <div className="award-title-sub">{selectedAward.SubName}{selectedAward.ShowGrade && <>{student.grade}</>}</div>
                    </div>
                )
            })}

        </div>}

    </div>
  )
}

export default MyAwards