import React, {useState, useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
import { ServerResponse } from '../../utils/Server';
import Icon, { IconType } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import { formatDiagnostic } from 'typescript';

type Tag = {
    Id: number;
    FairId: number;
    Type: "person" | "project";
    Value: string;
  };

const CirclesScheduler = (props:any) => {
    const roundId = props.match.params.roundId;
    const [categories, setCategories] = useState({});
    const [round, setRound] = useState<any>(null);
    const [circles, setCircles] = useState([]);
    const [judges, setJudges] = useState([]);
    const [projects, setProjects] = useState<Array<any>>([]);
    const [judgeGroups, setjudgeGroups] = useState<Array<any>>([]);
    const [judgeGroupDic, setJudgeGroupDic] = useState({});
    const [judgesDic, setJudgesDic] = useState({});
    const [assignDic, setAssignDic] = useState({});
    const [showAssignByTagModal, setShowAssignByTagModal] = useState<boolean>(false);
    const [form, setForm] = useState<any>({});
    const [projectTags, setProjectTags] = useState<Tag[]>([]);
    const [showAssignByUpload, setShowAssignByUpload] = useState<boolean>(false);
    const [fileText, setFileText] = useState<any>(null);

    useEffect(() => {
        init();
        store.server.getApi<ServerResponse<any>>("../Tag/Info")
        .then(x=>{
            let tags = x.Value.projectTags.sort((a, b)=> {return a.Value.toUpperCase() > b.Value.toUpperCase() ? 1:-1})
            setProjectTags(x.Value.projectTags);
        });
      },[])

      const init = ()=>{
        store.server.postApi(`../JudgingCircles/RoundInfo`, {roundId}).then((res:any)=>{
            if (res.Success) {
                const data = res.Value;
                setCategories(data.categories);
                setCircles(data.circles);
                setJudges(data.judges);
                setProjects(data.projects);
                setjudgeGroups(data.judgeGroups);
                
                const jd = {};
                data.judges.forEach(x=>{jd[x.PersonId] = x});
                setJudgesDic(jd);
                
                const jgd = {};
                data.judgeGroups.forEach(x=>{jgd[x.Id] = x});
                setJudgeGroupDic(jgd);

                const ad = {};
                data.assignments.forEach(x=>{ad[`${x.circleId}-${x.judgeGroupId}-${x.SeatNumber}-${x.projectId}`] = x});
                setAssignDic(ad);

                setRound(data.round); //set last
            } else {
                toast.error(res.Message, {autoClose:false});
            }
        });
      };
  
      const assignProjectsToCirclesByCategory = (roundId:any)=>{
        if (!window.confirm(`Are you sure you want to assign projects to these Circles based on category? Note this will remove all current assignments.`)        ) {
            return;
        }
        store.server.postApi(`../JudgingCircles/AssignByCategoryToCircle`, {roundId}).then((data:any)=>{
            if (data.Success) {
                toast.success(`Projects Assigned`);
                init();
            } else {
                toast.error(data.Message, {autoClose:false});
            }
        });
    }

    const assignProjectByTagToCircle = (roundId:any)=>{
        let tag = form.tag;
        let tagDef = projectTags.find(x=>x.Id+'' === tag);
        if(!tag || tag === 'NONE'){
            toast.warn('You need to select a tag.');
            return;
        }
        if (!window.confirm(`Are you sure you want to assign projects to these circle based on the tag "${tagDef?.Value ?? tag}"?`)) {
            return;
        }

        store.server.postApi<ServerResponse<any>>(`../JudgingCircles/AssignProjectByTagToCircle`, {
            roundId, projectTag:tag
        })
        .then(x=>{
            if(x.Success){
                toast.success(`Projects Assigned`);
                init();
            }
            else {
                toast.error(x.Message, {autoClose:false});
            }
        });
    }

    const assignbyUpload = ()=>{
        if(!fileText){
            toast.warn('Please select a CSV file to upload.');
            return;
        }
        if(!window.confirm(`Are you sure you want to upload these assignments and have them scheduled?`)){
            return;
        }

        store.server.postApi<ServerResponse<any>>(`../JudgingCircles/AssignByUpload`, {roundId,data: fileText})
        .then((data:any)=>{
            if (data.Success) {
                toast.success(`Projects and schedule assigned!`);
                setShowAssignByUpload(false);
                if(data.Value.message){
                    toast.info(data.Value.message, {autoClose:15000});
                }
                init();
            } else {
                toast.error(data.Message, {autoClose:false});
            }
        });
    };
    
    const assignTimesToJudge = (roundId:any)=>{
        if (!window.confirm(`Are you sure you want to assign times? Note this will remove all current times.`)        ) {
            return;
        }
        store.server.postApi(`../JudgingCircles/AssignTimesToJudgeGroupOnCircle`, {roundId}).then((data:any)=>{
            if (data.Success) {
                toast.success(`Schedule Created...`);
                const ad = {};
                data.Value.forEach(x=>{ad[`${x.circleId}-${x.judgeGroupId}-${x.SeatNumber}-${x.projectId}`] = x});
                
                setAssignDic(ad);
                setCircles(circles);
            } else {
                toast.error(data.Message, {autoClose:false});
            }
        });
    }

    
    const mapCircles = ()=>{
        if(circles && circles.length > 0){
        return circles.map((x:any,i:number)=>{
            const jg = judgeGroupDic[x.JudgeGroupId];
            return <div className="oj-circle" key={`circle-${i}`}>
                <div className="panel panel-default">
                    <div className="panel-heading flex-between">
                        <strong>{x.Name}</strong>
                        { jg && <div><i className="fa fa-users"></i> {jg.JudgeGroupName}</div>}
                    </div>
                    <div className="panel-body">
                        {projects.filter(p=>p.Circles && p.Circles.find(cp=>cp.CircleId === x.Id)).map((p:any, pi:number)=>{
                            let pc = p.Circles.find(cp=>cp.CircleId === x.Id);
                            return <div className="oj-circle-project" key={`cirle-pro-${pi}`}>
                                <div className="header">
                                    <strong>{p.project_id}</strong>
                                    <span className="title">{p.title}</span>
                                </div>
                                {jg && 
                                    <div className="assignments-container">

                                        {jg.Judges && <>

                                                    {jg.Judges.map((j:any, ji:number)=>{
                                                        const judge = judgesDic[j.PersonId];
                                                        const assignment = assignDic[`${pc.CircleId}-${jg.Id}-${j.JudgeNumber}-${p.id}`];
                                                        if(judge)
                                                        return (
                                                            <div className="judge" key={`cirle-pro-j${ji}`}>
                                                                {j.JudgeNumber} 
                                                                {assignment && <span>{assignment.displayTime}</span>}
                                                            </div>
                                                        )
                                                    })}
                                            </>
                                        }
                                    </div>
                                }
                            </div>
                        })}
                    </div>
                </div>                        
            </div>
        })
        } else {
            return <div className='alert alert-danger'>
                <h3><Icon type={IconType.warning} /> No Circles</h3>
                We don't have any circles for this round. Before you can assign projects or schedule times you will need to define the circles that belong to this round.
            </div>
        }
    }

    
  const csvFileSelected = (e:any) => {
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const text = e.target.result;
      let lines: any[] = [];
      const perrors: string[] = [];
      let formattedText = text.replaceAll('\r\n','\n').replaceAll('\r', '\n');
      const rawLines = formattedText.split("\n");
      let hasTimes = false;

      rawLines.forEach((l, index) => {
        if (l.length > 0) {
          const line = l.split(',');
          const columnsPerLine = 2;
          if (line.length >= columnsPerLine) {
            lines.push(line);
          } else {
            const errorMessage = `Line ${index + 1} has ${line.length} columns, but should have ${columnsPerLine}`
            perrors.push(errorMessage);
          }
        }
      });

      if (perrors.length) {
        toast.error(<div>
            Errors where found in the file.
        <ul>
            {perrors.map((x,i)=>{
                return <li key={`error-file-${i}`}>x</li>
            })}
        </ul>
        </div>, {autoClose:15000});
        setFileText(null);
      } else {
        setFileText(lines);
      }
    };
    reader.readAsText(file);
  };

    return (
      <div className="circle-scheduler">
          {round && <>
            <div className='flex'>
                <h3><Icon type={IconType.calendar} /> Schedule Tools</h3>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;For: <strong>{round?.RoundName}</strong></h4>
            </div>

            <div className='flex-between'>
                
            <div className="circle-scheduler-step-tile">
                <div className='step-tile'>
                    <h4>1. Assign Projects to Circles</h4>
                    Push a button below to assign projects.

                    <div>
                        <button type="button" className="btn btn-default btn-xs btn-block" onClick={()=>{assignProjectsToCirclesByCategory(roundId)}}><i className="fa fa-hat-wizard"></i> Assign Projects to Circles by Category</button>
                    </div>
                    <div>
                        <button type="button" className="btn btn-default btn-xs btn-block" onClick={()=>{setShowAssignByTagModal(true)}}><i className="fa fa-hat-wizard"></i> Assign Projects to Circles by Tag <Icon type={IconType.tag}/></button>
                    </div>
                </div>

                <div className='step-tile'>
                    <h4>2. Schedule Times</h4>
                    Schedule you round with the click of a button.

                    <div>
                    <button type="button" className="btn btn-default btn-xs btn-block" onClick={()=>{assignTimesToJudge(roundId)}}><i className="fa fa-hat-wizard"></i> Assign Schedule while pairing judges</button>
                    </div>
                </div>
            
            
            </div>
                
                <div className="circle-scheduler-step-tile">
                    <div className='step-tile'>
                        <h4>Assign by Upload</h4>
                        Assign projects, & judge group to times, and schdule them. Schdule rules come form the round definition.
    
                        <div>
                            <button type="button" className="btn btn-default btn-xs btn-block" onClick={()=>{setShowAssignByUpload(true)}}><Icon type={IconType.upload}/> Assign by Upload </button>
                        </div>
                    </div>                
                
                </div>
            
            </div>

            <div className="oj-circle-container">
                {mapCircles()}
            </div>
          </>}
          {showAssignByTagModal && <Modal 
          setModalOpen={setShowAssignByTagModal} 
          title={'Assign Projects by Tag'}
          size='m'>
                <div className='form-horizontal'>
                    <div className='form-group'>
                        <label htmlFor='tagName' className='control-label col-sm-4'>Tag</label>
                        <div className='col-sm-5'>
                            <select className='form-control' id='tagName' value={form.tag} onChange={(e)=>{setForm({...form,tag:e.target.value});}}>
                                <option value='NONE'>select...</option>
                                {projectTags.map((x,i)=>{
                                    return (<option key={`tag-${i}`} value={x.Id+''}>{x.Value}</option>);
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className='col-sm-offset-4 col-sm-5'>
                        <button type="button" className="btn btn-secondary" onClick={()=>{assignProjectByTagToCircle(roundId)}}><Icon type={IconType.tag}/> Assign Projects by Tag </button>
                        </div>
                    </div>
                </div>
              </Modal>}

              
          {showAssignByUpload && <Modal 
          setModalOpen={setShowAssignByUpload} 
          title={'Assign By Upload'}
          size='m'>
                <div className='form-horizontal'>
                    <div className='form-group'>
                        <label htmlFor='csvFileInput' className='control-label col-sm-3'>CSV File</label>
                        <div className='col-sm-9'>
                        <input
                        id='csvFileInput'
                        className='form-control'
              type="file"
              accept=".csv"

              onChange={csvFileSelected}
              onClick={(evt: any) => evt.target.value = null}
            // Set target to null so the onchange event will fire again even if the user picks the same file.
            />
                            <div className='alert alert-info'>
                                This csv file should have two columns, first column the project identifier, the second column the judge group name. For this work each judge group name must be unique. 
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className='col-sm-offset-3 col-sm-9'>
                        <button type="button" className="btn btn-secondary" onClick={()=>{assignbyUpload()}}><Icon type={IconType.upload}/> Upload & Assign </button>
                        </div>
                    </div>
                </div>
              </Modal>}
      </div>
    )
  
}

export default withRouter(CirclesScheduler)