import React, { useState, useEffect } from 'react'
import { store } from "../../redux/oldStore.ts";
import { toast } from 'react-toastify'


const JudgingProjectAssignmentModal = (props) => {
  const {
    judges
    , judgesByKey
    , assignments
    , project
    , round
    , setAssignments
  } = props;
  const [selectedJudgeId, setSelectedJudgeId] = useState('0');
  const [judgesToAssign, setJudgesToAssign] = useState([]);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');


  useEffect(() => {
    let j = [];
    let a = {};
    assignments.forEach(x => {
      a[x.ProjectId] = true;
    });
    judges.forEach((x, i) => {
      if (!a[x.ProjectIntId]) {
        j.push(x);
      }
    });
    setJudgesToAssign(j);
  }, [assignments, judges])

  const mappedOptions = judgesToAssign.map(Judge => {
    return (
      <option
        key={Judge.Id}
        value={Judge.Id}
      >
        {Judge.FirstName} {Judge.LastName} {Judge.Veteran && 'Veteran'}
      </option>
    )
  })


  const handleSave = () => {
    // the values in request keys are the same, but come from different places now
    let request = {
      projectId: project.ProjectIntId,
      projectKey: project.ProjectKey,
      judgeId: selectedJudgeId,
      roundId: round.Id,
      date,
      time
    };
    let callback = (data) => {
      toast.success(`Assignment made.`);
      setAssignments([...props.fullAssignments, data.assignment])
      props.setModalOpen(false)

    };
    store.server.postApi('../JudgingManagement/Assign', request)
      .then( res => {
        console.log(res)
        callback(res.Value)
      })
      .catch(err => console.error(err));
  }

  return (
    <>
      {props.project
        &&
        <div>
          <div className="">
            <div className="form-horizontal">
              <strong></strong>
              <div className="form-group">
                <label className="control-label col-sm-2" htmlFor="round-edit-name">Judge</label>
                <div className="col-sm-6">
                  <select
                    className="form-control select-judge-dropdown"
                    onChange={(e) => setSelectedJudgeId(e.target.value)}

                  >
                    <option value="0">select...</option>
                    {mappedOptions}
                  </select>
                </div>
              </div>
              {round.Values?.SchedulesInterviews
                &&
                <div className="form-group">
                  <label className="control-label col-sm-2" htmlFor="round-edit-name">At</label>
                  <div className="col-sm-5">
                    <input
                      type="date"
                      placeholder='mm/dd/yyyy'
                      className="form-control wider-input-d"
                      onChange={e => setDate(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="time"
                      placeholder='--:-- --'
                      className="form-control wider-input-t"                
                      onChange={e => setTime(e.target.value)}
                    />
                  </div>
                </div>

              }
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary assign-judge-btn"
              onClick={handleSave}>
              <i className="fad fa-box-full"></i>Assign Judge
              </button>
          </div>
        </div>
      }
    </>
  )
}

export default JudgingProjectAssignmentModal