import React, { useState, useEffect } from 'react'
import { store } from '../../../redux/oldStore'
import { toast } from 'react-toastify'

const Prompts = (props) => {
  const [promptsArr, setPromptsArr] = useState([])
  const [id, setId] = useState(0)
  const [prompt1Val, setPrompt1Val] = useState('')
  const [prompt2Val, setPrompt2Val] = useState('')
  const [prompt3Val, setPrompt3Val] = useState('')
  const [formError, setFormError] = useState(false)
  const [showForm, setShowForm] = useState(false)

  useEffect(() => {
    store.server.postApi('../AcaDeca/Prompts', {})
      .then(res => {
        console.log(res);
        setPromptsArr(res.Value.prompts)
      })
      .catch(err => {
        console.log(err);
        toast.danger('failed to get prompts')
      })
  }, [])

  const loadPrompts = (fullPrompt) => {
    setFormError(false);
    setId(fullPrompt.id);
    setPrompt1Val(fullPrompt.prompt1)
    setPrompt2Val(fullPrompt.prompt2)
    setPrompt3Val(fullPrompt.prompt3)
    setShowForm(true);
  }

  const nullPrompt = { id: 0, prompt1: '', prompt2: '', prompt3: '' }
  const clearedValuesPrompt = { id, prompt1: '', prompt2: '', prompt3: '' }

  const handleSavePrompts = () => {
    if (!prompt1Val || !prompt2Val || !prompt3Val) {
      setFormError(true);
      toast.warning('must fill out all the prompts')
      return
    }
    let saveObj = {
      Id: id,  // ????
      Prompt1: prompt1Val,
      Prompt2: prompt2Val,
      Prompt3: prompt3Val,
    };

    store.server.postApi('../AcaDeca/Prompt', saveObj)
      .then(res => {
        console.log(res);
        //Update promptsArr with new info
        saveObj.Id === 0
          ? setPromptsArr([...promptsArr, res.Value.prompt])
          : setPromptsArr(promptsArr.map(p => p.id === saveObj.Id ? res.Value.prompt : p));
        // loadPrompts(nullPrompt);
        loadPrompts(res.Value.prompt)
        toast.info('prompt set saved!')
      })
      .catch(err => {
        console.error(err);
        toast.warning('saving prompt failed')
      });
  };

  const handleRemovePrompts = () => {
    if (!window.confirm('are you sure you want to delete these prompts')) return;
    let deleteObj = {
      Id: id,  // ????
      Prompt1: prompt1Val,
      Prompt2: prompt2Val,
      Prompt3: prompt3Val,
    };

    store.server.postApi('../AcaDeca/RemovePrompt', deleteObj)
      .then(res => {
        console.log(res);
        //Update promptsArr with new info
        setPromptsArr(promptsArr.filter(prompt => prompt.id !== deleteObj.Id));
        loadPrompts(nullPrompt);
        setShowForm(false);
      })
      .catch(err => {
        console.error(err);
        toast.warning('saving prompt failed')
      });
  };

  const mappedPrompts = promptsArr.map((prompt, i) => {
    return (
      <div
        key={`${prompt.id}${i}`}
        className='prompt-unit soft-border hover-shadow'
      >
        <div className="flex-between">
          <div className='title'>Prompt Set:</div>
          <div><span title="click to edit" onClick={() => loadPrompts(prompt)} className="text-button text-info"><i className="fad fa-edit"></i></span></div>
        </div>
        <div className='limited-length text-muted'>{prompt.prompt1}</div>
        <div className='limited-length text-muted'>{prompt.prompt2}</div>
        <div className='limited-length text-muted'>{prompt.prompt3}</div>
      </div>
    )
  });

  const closeForm = ()=>{
    setShowForm(false);
  };

  return (
    <div>
      <div
        className='header bumper-l flex-between'
      >
        <h3>Impromptu Speech Prompts</h3>
        {!showForm
         ?
          <button
            className='btn btn-success'
            onClick={() => loadPrompts(nullPrompt)}
          >
            <i className="far fa-plus-circle"></i>
            new prompts
          </button>
          :
          <button
          className='btn btn-default'
          onClick={closeForm}
        >
          <i className="far fa-ban"></i>
          close
        </button>
        }
      </div>
      <div
        className='bumper-l'
      >
        {showForm && 
          <div>
          <div className={`form-input ${(formError && !prompt1Val) ? 'error' : ''}`}>
            <label>Prompt 1:</label>
            <textarea
              className="grow-1"
              rows={5}
              onChange={(e) => setPrompt1Val(e.target.value)}
              value={prompt1Val}
            />
          </div>
          <div className={`form-input ${(formError && !prompt2Val) ? 'error' : ''}`}>
            <label>Prompt 2:</label>
            <textarea
              className="grow-1"
              rows={5}
              onChange={(e) => setPrompt2Val(e.target.value)}
              value={prompt2Val}
            />
          </div>
          <div className={`form-input ${(formError && !prompt3Val) ? 'error' : ''}`}>
            <label>Prompt 3:</label>
            <textarea
              className="grow-1"
              rows={5}
              onChange={(e) => setPrompt3Val(e.target.value)}
              value={prompt3Val}
            />
          </div>
            <div className='button-area flex-center'>

          { (prompt1Val && prompt2Val && prompt3Val) && 
            <button
              className='btn btn-success'
              onClick={handleSavePrompts}
            >
              <i className="far fa-save"></i>
            save prompts
          </button>}
            {false && (prompt1Val || prompt2Val || prompt3Val)
              &&
              <button
                className='btn btn-secondary'
                onClick={() => loadPrompts(clearedValuesPrompt)}
              >
                <i className="far fa-times-circle"></i>
                clear prompts
              </button>
            }
            {id !==0 
              &&
              <button
                className='btn btn-danger'
                onClick={handleRemovePrompts}
              >
                <i className="far fa-trash-alt"></i>
                remove prompts set
              </button>
            
            }
          </div>
        </div>}


        {!showForm && <div
          className='flex wrap'
        >

          {mappedPrompts}
        </div>}

      </div>
    </div>
  )
}

export default Prompts