import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IState, ReduxMap } from '../../redux/redux';
import FlexFlowManager from '../../Components/FlexFlow/Setup/FlexFlowManager';
import FlexFlow from '../../Components/FlexFlow/FlexFlow';
import FlexFlowDone from '../../Components/FlexFlow/FlexFlowDone';

interface IProps extends RouteComponentProps<IParams>, IState {}
interface IParams {
  flowId: string;
  recordId: string;
}

const FlexFlowPageDone = (props: IProps) => {
  //console.log('Page...',props.match.params);

  return (
    <>
      {props.IsReady && 
        <FlexFlowDone state={props} flowId={props.match.params.flowId} recordId={props.match.params.recordId} />}
    </>
  );
};

export default withRouter(ReduxMap(FlexFlowPageDone));
