import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import StoreX, { store } from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import Modal from '../Modal/Modal';
import { buildButtons, getFileType } from '../Paperwork/paperworkReviewUtilities';
import { button, fileType, fileUploadRequest, paperworkProjectsResponse, uploadedFile } from '../Paperwork/paperworkTypes';
import FileListItem from './FileListItem';
import { toast } from 'react-toastify';
import { convertSingleImageToPdfAsFile } from '../../utils/PdfConverter';
import Icon, { IconType } from '../Icon/Icon';
import { IProjectComment } from '../../ObjectTypes/projectTypes';
import PdfViewer from '../PdfViewer/PdfViewer';
import { NewGuid } from '../../utils/Tools';
import PdfBasicViewer from '../PdfViewer/PdfBasicViewer';

type projectDetails =
  | undefined
  | {
      isefProjectInfo?: isefProjectInfo;
      projectData: paperworkProjectsResponse;
      UploadAllowed: boolean;
      InformationIsFrozen: boolean;
      html: string;
    };

type isefProjectInfo = {
  Form1B_2a_ChairApprovalAt?: Date;
  Form1B_2b_ChairApprovalAt?: Date;
  Form1B_3c_RegionalChairApprovalAt?: Date;
  Form1B_3c_StateChairApprovalAt?: Date;
  RequiredForm_1A: boolean;
  RequiredForm_1B: boolean;
  RequiredForm_1C: boolean;
  RequiredForm_2: boolean;
  RequiredForm_3: boolean;
  RequiredForm_4: boolean;
  RequiredForm_4_InformedCosent: boolean;
  RequiredForm_5A: boolean;
  RequiredForm_5B: boolean;
  RequiredForm_6A: boolean;
  RequiredForm_6B: boolean;
  RequiredForm_7: boolean;
};

type ParticipantFileManagerProps = {
  participantId?: string;
  projectId?: string;
  isNew: boolean;
};

export type isefButton = button & {
  isIsefRequired: boolean;
};

const ParticipantFileManager: FunctionComponent<ParticipantFileManagerProps> = (props: ParticipantFileManagerProps) => {
  const [projectDetails, setProjectDetails] = useState<projectDetails>(undefined);
  const [fileUploadsAllowed, setFileUploadsAllowed] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [blankFile, setBlankFile] = useState<string>('');
  const [currentButton, setCurrentButton] = useState<isefButton>();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedImageFile, setSelectedImageFile] = useState<File>();
  const [imageFilePreview, setImageFilePreview] = useState<any>();
  const [canUploadImageAsPdfLooksGood, setCanUploadImageAsPdfLooksGood] = useState<boolean>(false);
  const [selectedButton, setSelectedButton] = useState<isefButton>();
  const [prevUploadedFile, setPrevUploadedFile] = useState<any>();
  const [showCommentModal, setShowCommentModal] = useState<boolean>();
  const [comments, setComments] = useState<IProjectComment[]>([]);
  const [selectedFileDataUrl, setSelectedFileDataUrl] = useState<any>();

  const handleFileClick = (button: isefButton) => {
    console.log(button);
    setBlankFile(button.blankFilePath ?? '');
    setCurrentButton(button);
    if (projectDetails && projectDetails.UploadAllowed) setShowEditForm(true);
  };

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setSelectedFileDataUrl(objectUrl);

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    } else {
      setSelectedFileDataUrl(undefined);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (!selectedImageFile) {
      setImageFilePreview(undefined);
      setCanUploadImageAsPdfLooksGood(false);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImageFile);
    setImageFilePreview(objectUrl);
    setCanUploadImageAsPdfLooksGood(false);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImageFile]);

  //initialize this component the the first time.
  useEffect(() => {
    init();
  }, []);

  const fileButtons = useMemo(() => {
    function fileTypeRequiredSwitch(matchingFileType: fileType | undefined, projectDetails: projectDetails) {
      switch (matchingFileType?.ISEFFileID) {
        case 10:
          return true;
        case 11:
          return projectDetails?.isefProjectInfo?.RequiredForm_1A ?? matchingFileType.required;
        case 12:
          return projectDetails?.isefProjectInfo?.RequiredForm_1B ?? matchingFileType.required;
        case 13:
        case 14:
          return true;
        case 19:
          return projectDetails?.isefProjectInfo?.RequiredForm_1C ?? matchingFileType.required;
        case 20:
          return projectDetails?.isefProjectInfo?.RequiredForm_2 ?? matchingFileType.required;
        case 30:
          return projectDetails?.isefProjectInfo?.RequiredForm_3 ?? matchingFileType.required;
        case 40:
          return projectDetails?.isefProjectInfo?.RequiredForm_4 ?? matchingFileType.required;
        case 41:
          return projectDetails?.isefProjectInfo?.RequiredForm_4_InformedCosent ?? matchingFileType.required;
        case 50:
          return (projectDetails?.isefProjectInfo?.RequiredForm_5A ?? matchingFileType.required) || projectDetails?.isefProjectInfo?.RequiredForm_5B;
        case 60:
          return projectDetails?.isefProjectInfo?.RequiredForm_6A ?? matchingFileType.required;
        case 61:
          return projectDetails?.isefProjectInfo?.RequiredForm_6B ?? matchingFileType.required;
        case 70:
          return projectDetails?.isefProjectInfo?.RequiredForm_7 ?? matchingFileType.required;
        default:
          return false;
      }
    }

    const isFileTypeRequired = (fileType: number) => {
      const fileTypes = projectDetails?.projectData.fileTypes;
      if (!fileTypes) {
        return false;
      }
      const matchingFileType = getFileType(fileType, fileTypes);

      return fileTypeRequiredSwitch(matchingFileType, projectDetails) ?? false;
    };

    if (projectDetails && projectDetails.projectData.fileTypes && projectDetails.projectData.projects?.length > 0 && projectDetails.projectData.projects[0].FileTypeRequirementOverrides) {
      const fileTypes = projectDetails.projectData.fileTypes.filter((x) => projectDetails.projectData.projects[0].Files.find((y) => y == x.id));
      const buttons = buildButtons(projectDetails.projectData.projects[0].UploadedFiles, fileTypes, projectDetails.projectData.projects[0].FileTypeRequirementOverrides, true, undefined, undefined);
      const requirementUpdatedButtons = buttons.map((button) => {
        const newButton: isefButton = { ...button, isIsefRequired: isFileTypeRequired(button.fileType) };
        return newButton;
      });
      return requirementUpdatedButtons;
    }
    return [];
  }, [projectDetails]);

  const getSelectedFile = () => {
    return projectDetails?.projectData.projects[0].UploadedFiles.filter((file) => file.fileType === currentButton?.fileType)[0];
  };

  const init = async () => {
    //  console.debug(await(store.getSettings()));
    const settings = StoreX.Settings;
    setFileUploadsAllowed(settings?.AllowStudentsToUploadFiles ?? false);
    const success = (data?: ServerResponse<projectDetails>) => {
      if (data) {
        setProjectDetails(data.Value);
        store.server.getApi<ServerResponse<IProjectComment[]>>(`../Project/Comments/${data.Value?.projectData.projects[0].ProjectKey}`).then((x) => {
          if (x.Success) {
            setComments(x.Value);
          }
        });
      }
    };
    try {
      console.log(props);
      let response = await store.server.postApiWithServerResponse<projectDetails>('../ParticipantFiles/ProjectData', { projectId: props.projectId, isNew: props.isNew });
      success(response);
      setFileUploadsAllowed(response.Value?.UploadAllowed || (settings?.AllowStudentsToUploadFiles ?? false));
    } catch (error) {
      console.error(error);
    }
  };

  const refresh = () => {
    //TODO: make an api call to reload this component.
    setProjectDetails(undefined);
    setFileUploadsAllowed(false);
    setShowEditForm(false);
    setBlankFile('');
    setCurrentButton(undefined);
    setSelectedFile(undefined);
    init(); //todo:  Is call init the right thing to do?
  };

  const isExperimentationApproved = () => {
    // console.debug(projectDetails?.isefProjectInfo?.Form1B_2a_ChairApprovalAt, projectDetails?.isefProjectInfo?.Form1B_2b_ChairApprovalAt);

    const result = projectDetails?.isefProjectInfo?.Form1B_2a_ChairApprovalAt || projectDetails?.isefProjectInfo?.Form1B_2b_ChairApprovalAt ? true : false;
    return result;
  };

  const closeModalHook = () => {
    setShowEditForm(false);
    // setSelectedFile(undefined);
  };

  const fileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget || !e.currentTarget.files) {
      return;
    }
    const currentFile = e.currentTarget.files[0];
    if (currentFile.type !== 'application/pdf') {
      //for now assume this is a png or jpg since those are the only other two allowed types.
      setSelectedImageFile(currentFile);
      try {
        let file = await convertSingleImageToPdfAsFile(currentFile);
        setSelectedFile(file);
      } catch {
        toast.error('We are unable to convert your image to a pdf.');
      }
    } else {
      setSelectedFile(currentFile);
      setSelectedImageFile(undefined);
    }
  };

  const viewUploadedFile = () => {
    window.open(getSelectedFile()?.url);
  };

  const uploadFile = async (e: any, shouldAppend?: boolean) => {
    if (!selectedFile) {
      toast.error('You need to choose a file to upload.');
      return;
    }

    const success = (data: uploadedFile) => {
      //what do we do on success?
      closeModalHook();
      refresh();
    };
    const fail = (response: any) => {
      closeModalHook();
      console.error(response);
      toast.error(response);
    };
    const fileMetaData = {
      fileTypeId: currentButton?.fileType,
      shouldAppend: shouldAppend === true,
      projectKey: props.projectId,
      isNew: props.isNew,
    };
    try {
      // let formData = new FormData()
      console.log(`"${props.projectId}"`);
      const result = await store.server.postApiWithServerResponse<uploadedFile>('../ParticipantFiles/FileUpload', fileMetaData, [selectedFile]);
      if (result?.Value) {
        success(result?.Value);
      } else {
        fail(result);
      }
    } catch (error) {
      fail(error);
    }
  };

  const handleAppendFile = async () => {
    console.log('oh, hi there');
    if (!window.confirm('This will join this file with the already uploaded one, modifying the original. Do you want to do this?')) return;
    let oldFile = getSelectedFile();
    console.log('hopefully oldFile', oldFile);
    console.log('should be new file', selectedFile);

    uploadFile(null, true);
  };

  return (
    <>
      <div className="col-sm-12">
        <div className="">
          <h4>Paperwork Upload Wizard</h4>
        </div>
        {projectDetails?.html && <div dangerouslySetInnerHTML={{ __html: projectDetails.html }}></div>}
        <div className="panel-body-">
          {isExperimentationApproved() && (
            <p className="alert alert-success">
              <strong>
                <i className="fa fa-exclamation-triangle"></i>&nbsp;Begin Experimentation!
              </strong>
              This project has been approved. Experimentation may now begin on this project.
            </p>
          )}
          {projectDetails?.isefProjectInfo?.Form1B_3c_RegionalChairApprovalAt && (
            <p className="alert alert-success">
              <strong>
                <i className="fa fa-exclamation-triangle"></i>&nbsp;Project Approved!
              </strong>
              This project has been approved to compete at a <strong>Regional Fair</strong>.
            </p>
          )}
          {projectDetails?.isefProjectInfo?.Form1B_3c_StateChairApprovalAt && (
            <p className="alert alert-success">
              <strong>
                <i className="fa fa-exclamation-triangle"></i>&nbsp;Project Approved!
              </strong>
              This project has been approved to compete at a <strong>State Fair</strong>.
            </p>
          )}
          {projectDetails?.projectData?.projects[0]?.ReadyForJudging && (
            <div className="alert alert-success">
              <strong>
                <i className="fa fa-exclamation-triangle"></i>&nbsp;Approved for Judging! &nbsp;
              </strong>
              Congratulations you have been approved for judging.
            </div>
          )}
          <div className="alert alert-info">
            Upload the required paperwork for your project. Click on the file you wish to upload to get started.
            <br />
            <strong>Files with an * at the beginning are required.</strong>
          </div>
          {projectDetails?.InformationIsFrozen && (
            <div className="alert alert-warning" id="alertUploadNotPermittedNow">
              <span className="glyphicon glyphicon-warning-sign"></span> &nbsp; Information freeze is in effect.
            </div>
          )}
          {!fileUploadsAllowed && (
            <div className="alert alert-warning" id="alertUploadNotPermittedNow">
              <span className="glyphicon glyphicon-warning-sign"></span> &nbsp; File upload not permitted at this time! If you need to update a file please contact your teacher or fair administrator.
            </div>
          )}
          <div className="file-type-div" style={{ fontWeight: 'bold' }}>
            Files: {fileButtons.length}
          </div>
          <div className="file-list">
            {fileButtons
              .filter((x) => x.internalFileName !== x.fileName || true) //filter out the ones that don't have a known file type.? //TODO:  should we filter these out or let them display to the users?
              .map((button, index) => (
                <FileListItem
                  key={index}
                  button={button}
                  uploadClick={() => handleFileClick(button)}
                  commentClick={() => {
                    setSelectedButton(button);
                    setShowCommentModal(true);
                  }}
                  deleteClick={() => {
                    refresh();
                  }}
                  canUpload={projectDetails?.UploadAllowed ?? false}
                  fileType={projectDetails?.projectData.fileTypes.find((x) => x.id === button.fileType)}
                  url={projectDetails?.projectData.projects[0].UploadedFiles.filter((file) => file.fileType === button.fileType)[0] || ''}
                />
              ))}
          </div>
          <div className="file-type-div" style={{ fontWeight: 'bold', border: 'none' }}></div>
        </div>
      </div>
      <button id="RefreshButton" className="hide" onClick={refresh}></button>
      {showEditForm && (
        <Modal title={`Student File Upload -${currentButton?.fileName}`} setModalOpen={closeModalHook} size="l">
          <>
            <div className="panel-body">
              {currentButton?.isIsefRequired && !currentButton.internalFileName && (
                <div id="IsFileRequiredSpan" className="alert alert-danger">
                  This file is required and must be uploaded.
                </div>
              )}
              {currentButton?.comments && (
                <>
                  <h4>Judge Comments</h4>
                  <p className="">{currentButton?.comments}</p>
                  <hr />
                </>
              )}
              <div id="ParticipantFileUploadTemplateDiv">
                {blankFile && (
                  <strong>
                    <a href={blankFile} target="_blank">
                      Blank File / Template
                    </a>
                  </strong>
                )}
                <br />
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <span>
                  Upload: <strong>{currentButton?.fileName}</strong>
                </span>

                <div className="flex-between">
                  <div>
                    <div>
                      <label className="btn btn-primary select-file-btn-label">
                        <i className="fal fa-cloud-upload" /> Select File to Upload
                        <input
                          type="file"
                          className="select-file-to-upload"
                          accept=".pdf,.jpg,.png"
                          onChange={fileSelected}
                          onClick={(evt) => ((evt.target as HTMLInputElement).value = '')}
                          hidden
                        />{' '}
                        {/*Set target to null so the onchange event will fire again even if the user picks the same file.*/}
                      </label>
                      <div>{selectedFile?.name}</div>
                      {(selectedFile?.size ?? 0) > 0 && <div>{(selectedFile.size / 1024 / 1024).toFixed(2)} MB</div>}
                    </div>
                  </div>
                  {/* {selectedImageFile && <div>
                      <img style={{maxHeight:'50px', maxWidth:'50px'}} src={imageFilePreview} />
                      </div>} */}
                  <div>
                    {selectedFile && selectedFileDataUrl && (
                      <div className="">
                        <div className="text-center">
                          <strong>Preview</strong>
                        </div>
                        <PdfBasicViewer dataUrl={selectedFileDataUrl} scale={0.15} />
                        {/* <img src={selectedFile.stream}/> */}
                      </div>
                    )}
                  </div>
                </div>
                {selectedImageFile && (
                  <div className="alert alert-danger">
                      <input
                        type="checkbox"
                        id={'sfu-image-thumbnail-looks-good'}
                        checked={canUploadImageAsPdfLooksGood}
                        onChange={(x) => {
                          setCanUploadImageAsPdfLooksGood(x.target.checked);
                        }}
                      />
                      <label htmlFor="sfu-image-thumbnail-looks-good" className="control-label bold">
                        <Icon type={IconType.warning} /> The "Preview" thumbnail looks good, and is not blank.
                      </label>
                    <br />
                    If it is blank you can try: uploading a smaller image, using a different browser, or using a different device.
                  </div>
                )}

                <hr></hr>

                <div className="flex-between">
                  {((selectedImageFile && canUploadImageAsPdfLooksGood) || !selectedImageFile) && (
                    <>
                      {selectedFile && selectedFile.name && (
                        <button className="btn btn-primary upload-replace-file-btn" onClick={uploadFile}>
                          {getSelectedFile()?.url ? 'Replace File' : 'Upload File'}
                        </button>
                      )}
                      {selectedFile && selectedFile.name && getSelectedFile()?.url && (
                        <button onClick={handleAppendFile} className="btn btn-secondary append-to-file-btn">
                          <Icon type={IconType.append} /> Append to File
                        </button>
                      )}
                    </>
                  )}
                  {getSelectedFile()?.url && (
                    <button onClick={viewUploadedFile} className="btn btn-default">
                      <i className="fal fa-file modal-view-file-btn" /> View File
                    </button>
                  )}
                </div>
                {/* If file already uploaded and another file loaded, have "Replace Existing File" button and "Append to Existing" button */}
                {/* If "append to existing" button, window.confirm('this will join these two files, modifying the original. Do you want to do this?') */}
                {/* then do it */}
              </div>
            </div>
          </>
        </Modal>
      )}
      {showCommentModal && (
        <Modal title={`Comments`} setModalOpen={setShowCommentModal}>
          {/* <strong>Comments: </strong>
<div>
  {selectedButton?.comments}
</div> */}

          <div className="comments-container">
            {comments.length === 0 && (
              <div>
                <h3>No Comments</h3>
                Sorry no comments have been left at this time.
              </div>
            )}
            {comments.map((c, i) => {
              return (
                <div className="comment" key={`comment-id-${c.Id}`}>
                  <div className="comment-header">{c.Header}</div>
                  <div className="comment-by">
                    <span>{c.FromName}</span>
                    <span>{c.At}</span>
                  </div>
                  <div className="comment-message">{c.Comment}</div>
                </div>
              );
            })}
          </div>
        </Modal>
      )}
    </>
  );
};

export default ParticipantFileManager;
