import { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import { managers } from "socket.io-client";
import StoreX, { store } from "../../../redux/oldStore";
import { ServerResponse } from "../../../utils/Server";
import FormModal from "../../FormFromApiData/FormModal";
import Icon, { IconType } from "../../Icon/Icon";
import { ExpandableImg, LinkToView } from "../../Tools/ExpandableDiv";
import SetupWizard from "../SetupWizard";
import ConfigFormEditor from "./ConfigFormEditor";
import { IConfigFormData, IConfigFormEditorData, IConfigItemSummary } from "./ConfigFormTypes";

interface IConfigFromLandingProps {
  type: string;
  expandable?:boolean;
  startClosed?:boolean;
  onUpdate?:Function;
  isChild?:boolean;
}

interface ISearch {
  text?: string;
}

const ConfigFormLanding = (props: IConfigFromLandingProps) => {
  const [type, setType] = useState<string>();
  const [data, setData] = useState<IConfigFormData>();
  const [search, setSearch] = useState<ISearch>({});
  const [filteredItems, setFilteredItems] = useState<IConfigItemSummary[]>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IConfigItemSummary>();
  const [expanded, setExpanded] = useState<boolean>(!(props.startClosed ?? false));
  const [dragSortEnabled, setDragSortEnabled] = useState<Boolean>(false);
  const searchLength = 15;

useEffect(()=>{
  let searchText = StoreX.GetQueryStringValue('s');
  if(searchText && searchText.length > 0) setSearch({text:searchText});
},[])

  useEffect(() => {
    if(props.type && props.type != type){
      setType(props.type)
      init();
    }
  }, [props.type]);

  // useEffect(() => {
  //   let items = [...(data?.Items ?? [])];

  //   items = items.sort((a, b) => {
  //     return (a.SortBy ?? a.Name).toUpperCase() >
  //       (b.SortBy ?? b.Name).toUpperCase()
  //       ? 1
  //       : -1;
  //   });

  //   setFilteredItems(items);
  // }, [data?.Items]);

  useEffect(() => {
    let text = search.text?.toLowerCase();
    if((data?.Items?.length ?? 0) < searchLength){
      text = undefined;
    }
    if (!data || !data.Items || !text || data.Items.length < searchLength)
      setFilteredItems(data?.Items?.sort((a, b) => {
        return (a.SortBy ?? a.Name).toUpperCase() >
          (b.SortBy ?? b.Name).toUpperCase()
          ? 1
          : -1;
      }) ?? []);

    setFilteredItems(
      data?.Items?.filter(
        (x) => x.Name.toLowerCase().indexOf(text ?? "") > -1
      ).sort((a, b) => {
        return (a.SortBy ?? a.Name).toUpperCase() >
          (b.SortBy ?? b.Name).toUpperCase()
          ? 1
          : -1;
      }) ?? []
    );
  }, [search, data?.Items]);

  const init = () => {
    setType(props.type);
    
    getFormData();
  };

  const getFormData = ()=>{
    store.server
    .postApi<ServerResponse<IConfigFormData>>(`../ConfigForm/FormData`, {
      type: props.type,
    })
    .then((x) => {
      if (x.Success) {
        setData(x.Value);
        setDragSortEnabled(x.Value.DragAndDropSort ? true:false);
      } else {
        toast.error(`${x.Message}`, { autoClose: false });
      }
    });
  };
  const save = (fields: Record<string, string[]>) => {};

  const onSave = (item: IConfigItemSummary) => {
    setSelectedItem(item);

    let items = [...(data?.Items ?? [])];
    let found = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].Id === item.Id) {
        found = true;
        items[i] = item;
        break;
      }
    }

    if (!found) items.push(item);
    if(data) setData({ ...data, Items: [...items] });
  };

  const onRemove = (item: IConfigItemSummary) => {
    let items = data?.Items.filter((x) => x.Id !== item.Id) ?? [];
    if(data) setData({ ...data, Items: items });
    setSelectedItem(undefined);
    setShowEditModal(false);
  };

  const addNew = () => {
    setSelectedItem({ Name: "", Id: "-1", Type: props.type });
    setShowEditModal(true);
  };

  const manage = (item: IConfigItemSummary) => {
    setSelectedItem(item);
    setShowEditModal(true);
  };

  const addSearchFilter = () => {
    if (!data || data?.Items.length < searchLength) {
      return <></>;
    }
    return (
      <>
        <div className="form-horizontal">
          <div className="form-group">
            <label className="control-label col-sm-3" htmlFor="x-search">
              Search
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                placeholder="search"
                onChange={(e) => {
                  setSearch({ ...search, text: e.target.value });
                }}
                value={search.text}
                className="form-control"
                autoFocus={true}
              />
            </div>
            <div className="col-sm-2">
              showing {filteredItems.length} of {data.Items.length}
            </div>
          </div>
        </div>
      </>
    );
  };

  const nameOrFile = (x: IConfigItemSummary) => {
    let file = StoreX.FileInfo(x.FilePath);
    if (file) {
      switch (file.type) {
        case "image":
          return <div className="flex-between">
            <div className="thumbnail-1 click"><ExpandableImg imageSrc={[{alt:x.Name, path:file.path}]} name={x.Name} /></div>
            <span className="bumper">{x.Name}</span>
          </div>
        case "pdf":
          return <LinkToView type="pdf" urlSource={file.path}  name={x.Name}/>
        case "video":
          return <LinkToView type="video" urlSource={file.path} name={x.Name} />
        case "text":
          break;
        case "other":
          break;
      }
    }
    return <strong dangerouslySetInnerHTML={{__html:x.Name}}></strong>;
  };

  const mapItems = () => {
    if (filteredItems.length === 0) {
      return (
        <>
          <div className="alert alert-info">
            <h4>No Items</h4>
          </div>
        </>
      );
    }
    return (
      <>
        {data &&
          filteredItems.map((x, i) => {
            let style: any = {};
            if (x.Color) style.borderLeft = `10px solid ${x.Color}`;
            return (
              <div key={`cfi:${i}`} className={`config-form-item ${dragSortEnabled ? 'dragable':''}`} style={style} 
                  onDragStart={(event)=>{event.dataTransfer.setData('indexA', i+'');}}
                  onDragOver={(event)=>{event.preventDefault();}}
                  onDrop={(event)=>{onDrop(+event.dataTransfer.getData('indexA'), i)}}
                  draggable={dragSortEnabled ? "true": "false"}
              >
                <div className="flex-between">
                  <div className="file-picker flex-between">
                  { dragSortEnabled && <span className="click big">
                        <Icon type={IconType.grip} />
                      </span>}
                      {nameOrFile(x)}
                  </div>
                  {data.IsStaff && !x.NoEdit && (
                    <div>
                         <button
                        type="button"
                        className="btn btn-default btn-xs config-form-landing-manage-btn"
                        onClick={() => {
                          manage(x);
                        }}
                      >
                        <Icon type={IconType.edit} /> Manage
                      </button>
                    </div>
                  )}
                </div>
                {x.Description && (
                  <div
                    className="config-form-item-desc"
                    dangerouslySetInnerHTML={{ __html: x.Description }}
                  ></div>
                )}
              </div>
            );
          })}
      </>
    );
  };

  const mapConfigGroups = () => {
    if (data?.ConfigGroups && data?.ConfigGroups?.length > 0) {
      return (
        <>
          {data.ConfigGroups.map((x, i) => {
            return (
              <SetupWizard key={`cgw-${i}`} wizardKey={x} autoSave={false} />
            );
          })}
        </>
      );
    }
    return <></>;
  };

  const wrapInTabOrNot = () => {
    if (!data) return <></>;

    if (data.ConfigGroups && data.ConfigGroups.length > 0) {
      return (
        <>
          <div className="flex-between">
            <h3 className="config-form-landing-title">{data.Title}</h3>
            {props.expandable && <div className="click fa-2x" onClick={()=>{setExpanded(!expanded)}}>
              {expanded ? <><Icon type={IconType.expandDown}/></>:
              <><Icon type={IconType.expandUp}/></>
              }
              </div>}
          </div>
          {expanded && <>
          {data.Description && (
            <div
              dangerouslySetInnerHTML={{ __html: data.Description }}
              className="bumper-bottom"
            ></div>
          )}
          <Tabs>
            <TabList>
              <Tab>Items</Tab>
              <Tab>Additional Configs</Tab>
            </TabList>
            <TabPanel>
              <div className="flex-between">
                <div></div>
                {data.IsStaff && (
                  <div className="bumper-bottom">
                    <button
                      type="button"
                      className="btn btn-secondary config-form-landing-add-btn"
                      onClick={addNew}
                    >
                      <Icon type={IconType.plus} /> Add
                    </button>
                  </div>
                )}
              </div>
              {addSearchFilter()}
              <div className="config-form-landing-container">{mapItems()}</div>
            </TabPanel>
            <TabPanel>
              <div className="config-form-landing-container">
                {mapConfigGroups()}
              </div>
            </TabPanel>
          </Tabs>
        </>}
        </>
      );
    } else {
      return (
        <>
          <div className="flex-between">
            <h3 className="config-form-landing-title">{data.Title}</h3>
            {data.IsStaff && (
              <div className="flex-between">
                {!data.NoAdd && <button
                  type="button"
                  className="btn btn-secondary config-form-landing-add-btn"
                  onClick={addNew}
                >
                  <Icon type={IconType.plus} /> Add
                </button>}
                {props.expandable && <div className="click fa-2x" onClick={()=>{setExpanded(!expanded)}}>
              {expanded ? <><Icon type={IconType.expandDown}/></>:
              <><Icon type={IconType.expandUp}/></>
              }
              </div>}
              </div>
            )}
          </div>
            {expanded && <>
          {data.Description && (
            <div dangerouslySetInnerHTML={{ __html: data.Description }}></div>
          )}
          <hr />
          {addSearchFilter()}
          <div className="config-form-landing-container">{mapItems()}</div>
          </>  }
        </>
      );
    }
  };

  const onUpdate = ()=>{
    getFormData();
    if(props.onUpdate) props.onUpdate();
  };

  const onDrop = (a:number, b:number)=>{
    if(a === b) return;
    console.log('item dropped indexes', a, b);

    let ids:number[] = [];
    if(data && data.Items){
    ids.push(+(data.Items[a].Id ?? "0"));
    ids.push(+(data.Items[b].Id ?? "0"));

    let request: IConfigFormEditorData = {
      Form: [],
      Id:ids[0]+'',
      Type:data.Items.find(x=>x.Id == ids[0]+'')?.Type,
      DragAndDropSort:ids,
    };

    store.server.postApi<ServerResponse<IConfigItemSummary>>(`../ConfigForm/SaveForm`,{data:JSON.stringify(request)})
        .then(x=>{
            if(x.Success && !x.Value.Failed){
                onUpdate();
            }  else if (x.Success) {
                toast.warning(x.Value.Message, {autoClose:15000})   ;
            }else {
                toast.error(`${x.Message}`, {autoClose:false});
            }
        });
      }
};

  return (
    <div className="col-sm-12">
      {data && <div className="config-form-landing">{wrapInTabOrNot()}</div>}

      {selectedItem && showEditModal && (
        <ConfigFormEditor
          type={props.type}
          item={selectedItem}
          canSave={data?.IsStaff ?? false}
          noEdit={data?.NoEdit ?? false}
          itemChildren={data?.ItemsChildTypes}
          onClose={() => {
            setShowEditModal(false);
          }}
          onSave={onSave}
          onRemove={onRemove}
          onUpdate={onUpdate}
           /*dontRefreshOnSaveOrRemove={props.isChild ? true:false}*/
          dontRefreshOnSaveOrRemove={true}
        />
      )}
    </div>
  );
};

export default ConfigFormLanding;
