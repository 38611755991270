import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { textSpanIntersectsWith } from "typescript";
import StoreX, { store } from "../../redux/oldStore";
import { Selectable } from "../../utils/CommonTypes/CommonTypes";
import { ServerResponse } from "../../utils/Server";
import Icon, { IconType } from "../Icon/Icon";
import { ICheckInUser, ICheckInUsersResponse } from "./CheckinTypes";
import Select from "react-select";
import Modal from "../Modal/Modal";
interface ICheckInProps {}
interface ISearch {
  text?: string;
  selected?: Selectable[];
}
const Checkin = (props: ICheckInProps) => {
  const [data, setData] = useState<ICheckInUsersResponse>();
  const [filteredUsers, setFilteredUsers] = useState<ICheckInUser[]>([]);
  const [search, setSearch] = useState<ISearch>({});
  const [filters, setFilters] = useState<Selectable[]>([]);
  const [showToken, setShowToken] = useState<boolean>(false);
  const [token, setToken] = useState<ICheckInUser>();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    let users = data?.Users ?? [];
    //do search stuff here...
    if (search.text) {
      let text = search.text.toLowerCase();
      users = users.filter((x) => x.Search.indexOf(text) > -1);
    }
    if (search.selected && search.selected.length > 0) {
      users = users.filter((x) => passesSearch(x));
    }

    setFilteredUsers(users);
  }, [data?.Users, search, search.text, search.selected]);

  const passesSearch = (user: ICheckInUser) => {
    if (search.selected) {
        let pass = false;
      for (let i = 0; i < search.selected?.length; i++) {
          let s = search.selected[i];
          if(s.value === "participant" && !user.Adult) pass=true;
          if(s.value === "teacher" && (user.Adult && user.MaxRole === "Teacher")) pass = true;
          if(s.value === "judge" && (user.Adult && user.MaxRole === "Judge")) pass = true;
          if(s.value === "volunteer" && (user.Adult && user.MaxRole === "Volunteer")) pass = true;
          if(s.value === "attendee" && (user.Adult && user.MaxRole === "Attendee")) pass = true;
      }

      return pass;
    }
    return true;
  };

  const init = () => {
    store.server
      .getApi<ServerResponse<ICheckInUsersResponse>>(`../checkin/users`)
      .then((x) => {
        if (x.Success) {
          setData(x.Value);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });

    let f: Selectable[] = [];
    f.push({ label: "Participant", value: "participant" });
    f.push({ label: "Teacher", value: "teacher" });
    f.push({ label: "Judge", value: "judge" });
    f.push({ label: "Volunteer", value: "volunteer" });
    f.push({ label: "Attendee", value: "attendee" });
    setFilters(f);
  };

  const clearAll = () => {
    if (window.confirm("Are you sure you want to CLEAR all check-in data?")) {
      store.server
        .getApi<ServerResponse<object>>("../checkin/ResetUserCheckIn")
        .then((x) => {
          if (x.Success) {
            init();
          } else {
            toast.error(x.Message, { autoClose: false });
          }
        });
    }
  };

  const toggleCheckIn = (user: ICheckInUser) => {
    store.server
      .postApi<ServerResponse<object>>(`../checkin/ToggleUserPresent`, {
        personId: user.PersonId,
        checkIn: user.CheckIn ? false : true,
      })
      .then((x) => {
        if (x.Success) {
          let users = [...(data?.Users ?? [])];

          for (let i = 0; i < users.length; i++) {
            if (users[i].PersonId === user.PersonId) {
              users[i].CheckIn = users[i].CheckIn ? "" : "Self";
            }
          }

          setData({ ...data, Users: users });
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const toggleCheckInPartyMember = (user: ICheckInUser, memberIndex) => {
    let member = user.Party[memberIndex];
    store.server
      .postApi<ServerResponse<object>>(
        `../checkin/TogglePartyMemberUserPresent`,
        {
          memberId: member.Id,
          personId: user.PersonId,
          checkIn: !member.CheckedIn,
        }
      )
      .then((x) => {
        if (x.Success) {
          let users = [...(data?.Users ?? [])];
          //  Do stuff Headers[Symbol]..
          for (let i = 0; i < users.length; i++) {
            if (users[i].PersonId === user.PersonId) {
              users[i].Party[memberIndex].CheckedIn =
                !users[i].Party[memberIndex].CheckedIn;
            }
          }

          setData({ ...data, Users: users });
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const mapUsers = () => {
    if (filteredUsers.length == 0) {
      return (
        <div className="alert alert-info">
          Sorry, looks like no one is found.
        </div>
      );
    } else {
      return (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Last Update</th>
              <th>Entry Id</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((x, i) => {
              return (
                <tr className="check-in-user" key={`ciu-${i}`}>
                  <td>
                    {x.CheckIn ? (
                      <button
                        type="button"
                        className="btn btn-xs btn-info"
                        onClick={() => {
                          toggleCheckIn(x);
                        }}
                      >
                        <Icon type={IconType.UserCheck} /> Checked-in
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-xs btn-secondary"
                        onClick={() => {
                          toggleCheckIn(x);
                        }}
                      >
                        <Icon type={IconType.user} /> Check-in
                      </button>
                    )}
                  </td>
                  <td>
                    <div className="flex-between">
                      <span>
                        {x.LastName}, {x.FirstName} {x.SignInToken && <span className="click" onClick={()=>{setToken(x);setShowToken(true)}}><Icon type={IconType.qrCode} /></span>}
                      </span>
                      <div>
                          {data?.HasRegistrationFee && !x.Adult && x.Paid && <span className="click text-success" title="Fees Paid"><Icon type={IconType.money}/></span>}
                          {data?.HasRegistrationFee && !x.Adult && !x.Paid && <span className="click text-danger" title="Fees Not Paid"><Icon type={IconType.money}/></span>}
                      <a
                        href={`${StoreX.BuildUrlWithFair(
                          `../app/user/profile/${x.Id}`
                        )}`}
                        className=""
                        target={"_blank"}
                        title="view details"
                      >
                        <Icon type={IconType.search} />
                      </a>
                      </div>
                    </div>
                    {data?.UseParty && x.Party?.length > 0 && (
                      <div className="users-party">
                        {x.Party.map((p, pi) => {
                          return (
                            <div
                              className="users-party-member"
                              key={`ciu-up-${i}-${pi}`}
                            >
                              {p.CheckedIn ? (
                                <button
                                  type="button"
                                  className="btn btn-xs btn-info"
                                  onClick={() => {
                                    toggleCheckInPartyMember(x, pi);
                                  }}
                                >
                                  <Icon type={IconType.UserCheck} /> Checked-in
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-xs btn-default"
                                  onClick={() => {
                                    toggleCheckInPartyMember(x, pi);
                                  }}
                                >
                                  <Icon type={IconType.user} /> Check-in
                                </button>
                              )}
                              {p.IsPaid && (
                                <Icon
                                  type={IconType.money}
                                  addClass={"text-success"}
                                />
                              )}
                              {p.Name}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </td>
                  <td>{x.Email}</td>
                  <td>{getRole(x)}</td>
                  <td>{x.LastUpdate}</td>
                  <td>{x.ProjectId}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
  };

  const getRole = (x: ICheckInUser) => {
    if (!x.Adult) return "Participant";
    else if (x.MaxRole === "Yoda") return "Staff";
    else if (x.MaxRole === "Admin") return "Staff";
    else if (x.MaxRole === "DistrictCoordinator") return "District";
    return x.MaxRole;
  };

  return (
    <>
      <div className="col-sm-12">
        <div className="flex-between">
          <h3>Check-In</h3>
          <div>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => {
                init();
              }}
            >
              <Icon type={IconType.refresh} /> Refresh
            </button>
            {data?.IsAdmin && (
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  clearAll();
                }}
              >
                <Icon type={IconType.warning} /> Clear All Check-In's
              </button>
            )}
          </div>
        </div>
        <div className="">
          keep track of who is at your contest. You can search by name, project
          id, and email address. To check-in some just click "Check-In" you can
          undo this by clicking on "Checked-In"
        </div>
        <hr />
        <div className="check-in">
          <div className="check-in-search">
            <div className="form-horizontal">
              <label className="control-label col-sm-2" htmlFor={"search-text"}>
                Search:
              </label>
              <div className="col-sm-5">
                <input
                  type={"text"}
                  maxLength={250}
                  value={search.text}
                  className={"form-control"}
                  placeholder="search by name, email, title, etc."
                  autoFocus
                  onChange={(e) => {
                    setSearch({ ...search, text: e.target.value });
                  }}
                />
              </div>
              <div className="col-sm-5">
                <Select
                  onChange={(x) => {
                    setSearch({ ...search, selected: x ?? [] });
                  }}
                  value={search.selected ?? []}
                  options={filters}
                  styles={``}
                  placeholder={"User types"}
                  className=""
                  isMulti             
                  isClearable={true}
                  menuPlacement={'auto'}
                />
              </div>
            </div>
          </div>
          <div className="check-in-user-container">{mapUsers()}</div>
        </div>
      </div>
      {showToken && token && <Modal setModalOpen={setShowToken} title={`QR Code Sign In`}>
        <div>
          <h2>{token.FirstName} {token.LastName}</h2>
          <div>Quick Sign-In Key: {token.SignInToken}</div>
          <img className="sign-in-code" src={`https://qrcode.zfairs.com/qrcode?value=${StoreX.BuildUrl(`../?login=1%26quickkey=${token?.SignInToken}`)}`}/>
        </div>
        </Modal>}
    </>
  );
};

export default Checkin;
