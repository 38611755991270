import { fabric } from "fabric";
import { Circle } from "fabric/fabric-impl";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { toast } from "react-toastify";
import StoreX, { store } from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";
import { arrayTools } from "../../utils/Tools";
import "../FloorPlanner/FloorStyle.css";
import Icon, { IconType } from "../Icon/Icon";
import Modal from "../Modal/Modal";
import {
  ICanvasProps,
  IFloorAutoPopRules,
  IFloorPlanInfo,
  IFloorSlotInfo,
  IProjectFloor,
  ISelectedTool,
} from "./FloorPlannerTypes";

import imgProject from "../../../public/images/floorplanner/project.png";
import imgBolt from "../../../public/images/floorplanner/bolt.png";
import imgTable1 from "../../../public/images/floorplanner/table1.png";
import imgTable2 from "../../../public/images/floorplanner/table2.png";
import imgTable3 from "../../../public/images/floorplanner/table3.png";
import imgShadow1 from "../../../public/images/floorplanner/shadow1.png";
import imgShadow2 from "../../../public/images/floorplanner/shadow2.png";
import imgShadow3 from "../../../public/images/floorplanner/shadow3.png";
import ProjectDetailsWraper from "../Showcase/projectDetailsWraper";

interface IFloorPlannerProps {}

const FloorPlanner = (props: IFloorPlannerProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
const imgProjectRef = useRef<HTMLImageElement>(null);
const imgBoltRef = useRef<HTMLImageElement>(null);
const imgTable1Ref = useRef<HTMLImageElement>(null);
const imgTable2Ref = useRef<HTMLImageElement>(null);
const imgTable3Ref = useRef<HTMLImageElement>(null);
const imgShadow1Ref = useRef<HTMLImageElement>(null);
const imgShadow2Ref = useRef<HTMLImageElement>(null);
const imgShadow3Ref = useRef<HTMLImageElement>(null);

  const defaultCanvasInfo = (): ICanvasProps => {
    return {
      //fabric: new fabric.Canvas(canvasRef.current, { selection: false }),
      canvasId: "",
      scale: 1,
      gridSize: 20,
      stepSize: 20,
      colors: [
        "#000", //default
        "#85929E", //table
        "#85929E", //table
        "#3333ff", //project
        "#FFF",
      ],
    };
  };
  const [canvasInfo, setCanvasInfo] = useState<ICanvasProps>(
    defaultCanvasInfo()
  );
  const imageScale= .04; // table is 500 px wide
  const [data, setData] = useState<IFloorPlanInfo>();
  const dataRef = useRef<IFloorPlanInfo|null>(null);
  const [placedProjectHash, setPlacedProjectHash] = useState<any>({});
  const [placedProjects, setPlacedProjects] = useState<IProjectFloor[]>([]);
  const [notPlacedProjects, setNotPlacedProjects] = useState<IProjectFloor[]>([]);
  const [objectSelected, setObjectSelected] = useState<ISelectedTool>();
  const [showProjectModal, setShowProjectModal] = useState<boolean>(false);
  const [categoryDic, setCategoryDic] = useState<any>({});
  const [showEditTableAssignments, setShowEditTableAssignments] = useState<boolean>(false);
  const [form, setForm] = useState<any>({});
  const [currentScale, setCurrentScale] = useState<number>(1);  
  const [autoRules, setAutoRules] = useState<IFloorAutoPopRules>(defaultRules());
  const [showAutoRules, setShowAutoRules] = useState<boolean>(false);
  const [showShowcase, setShowShowcase] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<string>();

  useEffect(() => {    
    let self = getSelf();
    if (!self.fab) {
      const h = containerRef.current?.offsetHeight ?? 600;
      const w = containerRef.current?.offsetWidth ?? 1000;
      self.fab = new fabric.Canvas(canvasRef.current, {
        selection: false,
        height: h,
        width: w,
      });
      Init();
      ManagerInit();
    }
  }, []);

  useEffect(()=>{    
    dataRef.current = data; 
    BuildProjectsList();
  },[data, categoryDic]);

  useEffect(()=>{
    let x = objectSelected;
    if(x){
    setForm({...form
      ,category:x.category
      ,slot1:x.Slots[0]?.id ?? '-1'
      ,slot2:x.Slots[1]?.id ?? '-1'
      ,slot3:x.Slots[2]?.id ?? '-1'
      ,power:x.power ? true : false
    });
  }
  }, [objectSelected]);



  const getSelf = (): any => {
    const w: any = window;
    if (!w.floorplanner) {
      w.floorplanner = {};
    }
    return w.floorplanner;
  };

  const buildRect = (width:number, height:number) =>{
    return new fabric.Rect({
      left: 0,
      top: 0,
      width:width,
      height:height,
      fill: "#333",
      originX: "right",
      originY: "bottom",
      opacity:.5,
    });
  };

  const Init = () => {
    DrawGrid(canvasInfo.gridSize, canvasInfo.scale);
    let canvas = getSelf().fab;

    //const mousecursor = new fabric.Circle({
    //   left: 0,
    //   top: 0,
    //   radius: canvasInfo.gridSize / 4,
    //   fill: "#333",
    //   originX: "right",
    //   originY: "bottom",
    //   opacity:.5,
    // });

    let rec1 = buildRect(20, 20);
    let rec2 = buildRect(20, 40);
    let rec3 = buildRect(20, 60);

    //canvas.add(mousecursor);
    canvas.add(rec1);
    canvas.add(rec2);
    canvas.add(rec3);

    const self: any = getSelf();

    canvas.on("mouse:move", (e) => {
      const pointer = { x: e.pointer.x, y: e.pointer.y };
      const gridSize = canvasInfo.gridSize;

      self.mx = Math.floor(pointer.x / gridSize) * gridSize;
      self.my = Math.floor(pointer.y / gridSize) * gridSize;
      // if (mousecursor.radius) {
      //   mousecursor.top =
      //     Math.round((pointer.y - mousecursor.radius) / gridSize) * gridSize +
      //     gridSize * 0.25;
      //   mousecursor.left =
      //     Math.round((pointer.x - mousecursor.radius) / gridSize) * gridSize +
      //     gridSize * 0.25;
      // }
      switch (self.tool) {
        case 1:
          rec1.top = self.my + 1 + rec1.height;
          rec1.left = self.mx + 1 + rec1.width;
          rec2.top = -100;
          rec2.left = -100;
          rec3.top = -100;
          rec3.left = -100;
          rec1.bringToFront();
          break;
        case 2:
          rec1.top = -100;
          rec1.left = -100;
          rec2.top = self.my + 1 + rec2.height;
          rec2.left = self.mx + 1 + rec2.width;
          rec3.top = -100;
          rec3.left = -100;
          rec2.bringToFront();
          break;
        case 3:
          rec1.top = -100;
          rec1.left = -100;
          rec2.top = -100;
          rec2.left = -100;
          rec3.top = self.my + 1 + rec3.height;
          rec3.left = self.mx + 1 + rec3.width;
          rec3.bringToFront();
          break;
        default:
          rec1.top = -100;
          rec1.left = -100;
          rec2.top = -100;
          rec2.left = -100;
          rec3.top = -100;
          rec3.left = -100;
          break;
      }

      canvas.renderAll();
    });

    // canvas.on("mouse:out", function (obj) {
    //   // put circle off screen
    //   mousecursor.top = -100;
    //   mousecursor.left = -100;
    //   canvas.renderAll();
    // });

    const selected = (obj) => {
      self.tool = null;
      setCanvasInfo({...canvasInfo,tool:0});
      if (obj.target.__tool) {
        self.selectedObj = obj.target;
        ObjectSelected(obj.target);
      }
    };

    canvas.on("selection:created", selected);
    canvas.on("selection:updated", selected);

    canvas.on("selection:cleared", (obj) => {
      self.selectedObj = null;
      setCanvasInfo({...canvasInfo,tool:0});
    });

    //snap to grid :D
    canvas.on("object:moving", (options) => {
      options.target.set({
        left: self.mx + 1 + (options.target.offLeft || 0),
        top: self.my + 1 + (options.target.offTop || 0),
      });
    });

    canvas.on("mouse:up", function (obj) {
      let s = getSelf();
      let fabric = s.fab;
      const x = s.mx;
      const y = s.my;

      if (s.tool && !fabric.getActiveObject()) {
        let obj = { x, y, tool: s.tool };
        DrawTool(obj, false, dataRef.current?.IsStaff);
        if (dataRef.current) {
          let d = { ...dataRef.current };
          d.Items = [...(d.Items ?? []), obj];
          setData(d);
        }
      }
    });
  };

  const DrawGrid = (gridSize: number, scale: number) => {
    const canvas = getSelf().fab;
    const w = canvas?.width ?? 100;
    const h = canvas?.height ?? 100;
    const c = getSelf().fab;
    gridSize || (gridSize = 15);
    for (var i = 0; i < w / gridSize; i++) {
      var x = i * gridSize;
      c.add(
        new fabric.Line([x, 0, x, h], { stroke: "#aaa", selectable: false, hoverCursor: "arrow" })
      );
    }
    for (var i = 0; i < h / gridSize; i++) {
      var y = i * gridSize;
      c.add(
        new fabric.Line([0, y, w, y], { stroke: "#aaa", selectable: false,  hoverCursor: "arrow" })
      );
    }
  };

  const DrawTool = (obj, temp:boolean, staff?:boolean, cDic?:any, mine?:boolean): fabric.Group => {
    let cord: any = {
      left: obj.x + 1,
      top: obj.y + 1,
      angle: obj.angle || 0,
      offLeft: 0,
      offTop: 0,
      snapAngle: 15,
      lockUniScaling: true,
      lockScalingX: true,
      lockScalingY: true,
      __tool: obj.tool,
      __obj: true,
      __abc: null,
    };

    if(!staff && dataRef?.current?.IsStaff) staff = true;

    if(obj.tool > 0 && !staff) {
      cord.selectable = false;
      if(mine){
        console.log('project found!');
      } else {
        cord.opacity = .4;
      }
    }

    let dic = cDic ?? categoryDic;
    
    let color = dic[obj.__category]?.Color;   
    
    let blocks = ToolBlocks(obj, color);
    var g: any = new fabric.Group(blocks, cord);

    if (temp) return g;

    g.__save = true;
    g.__projectIds = obj.__projectIds || [];
    g.__category = obj.__category || null;
    g.__power = obj.__power || false;

    getSelf().fab.add(g);

    return g;
  };

  const ToolBlocks = (obj:any,color:string)=>{
    let subBlocks: any[] = [];
      
    let projectSlots = {};
    let projects = obj.__projectIds || [];
    projects.forEach((x) => {
      projectSlots[x.Slot] = x.Id;
    });

    switch (obj.tool) {
      case 1:
        if(projectSlots[1]){
          subBlocks.push(DrawProjectBlock(projectSlots[1]));
        }
        break;
      case 2:
        if(projectSlots[1]){
          subBlocks.push(DrawProjectBlock(projectSlots[1]));
        }
        if(projectSlots[2]){
          subBlocks.push(DrawProjectBlock(projectSlots[2]));
          subBlocks[1].top -= 20;
        }
        break;
      case 3:
        if(projectSlots[1]){
          subBlocks.push(DrawProjectBlock(projectSlots[1]));
        }
        if(projectSlots[2]){
          subBlocks.push(DrawProjectBlock(projectSlots[2]));
          subBlocks[1].top -= 20;
        }
        if(projectSlots[3]){
          subBlocks.push(DrawProjectBlock(projectSlots[3]));
          subBlocks[2].top -= 40;
        }
        break;
      default:
    }
    if(obj.__power){
      subBlocks.push(DrawPower(obj.tool));
    }
    if(color){
      subBlocks.push(DrawCategory(color, obj.tool));
    }

    var table = DrawTable(obj.tool);
    var blocks = arrayTools.InsertAt(subBlocks, 0, table);
    return blocks;
  };

  const DrawCategory = (color, tool:number)=>{
    let categoryBorder: any = {
      width: 2,
      height: 20 * tool,
      fill: color,
      originX: "right",
      originY: "bottom",
      snapAngle: 15,
      lockUniScaling: true,
      lockScalingX: true,
      lockScalingY: true,
    };
    return new fabric.Rect(categoryBorder);
  };

  const DrawPower = (tool:number): fabric.Image => {        
    return new fabric.Image(imgBoltRef.current, {
      left:-8,
      top:-7 * tool,
      scaleX: imageScale +.02,
      scaleY: imageScale+.02,
      originX: "right",
      originY: "bottom",
    });
  };
  
  const DrawTable = (tool:number): fabric.Image => {    
    let image = imgTable1Ref;
    if(tool === 2) image = imgTable2Ref; 
    if(tool === 3) image = imgTable3Ref;
    
    return new fabric.Image(image.current, {
      left:0,
      top:0,
      scaleX: imageScale,
      scaleY: imageScale,
      originX: "right",
      originY: "bottom",
    });
  }
  const DrawProjectBlock = (hasProject: any): fabric.Image => {
    
    return new fabric.Image(imgProjectRef.current, {
      left:-3,
      top:-4,
      scaleX: imageScale - .015,
      scaleY: imageScale - .015,
      originX: "right",
      originY: "bottom",
    });
  };

  const ObjectSelected = (obj) => {
    //set - update table proprities...
    if(obj.__tool){
      let info = getInfoFromObj(obj);

      setObjectSelected(info);
      
    } else {
      setObjectSelected(undefined);
    }
  };

  const getInfoFromObj = (obj)=>{
    
      //his a tool we care about
      let slots = obj.__tool;
      let ids:IFloorSlotInfo[] = [];
      let hash:any = {};
      for(let i = 0; i < slots; i++){
        let r = obj.__projectIds[i];
        if(r){
          ids.push({id:r.Id, slot:r.Slot});
          hash[r.Id] = true;
        } else {
          ids.push({id:'', slot:i+1});
        }
      }

      let projects = getSelf().data?.Projects.filter(x=>hash[x.IdInt]) ?? [];

      let info:ISelectedTool = {
        Type:obj.__tool,
        Projects:projects,
        Slots:ids,
        category:obj.__category,
        power:obj.__power,
      };

      return info;
  };

  const MoveSelected = (move) => {
    const self = getSelf();
    const obj = self.fab.getActiveObject();

    if (obj) {
      const step = canvasInfo.stepSize;

      if (move == 1) {
        obj.top = obj.top - step;
      } else if (move == 2) {
        obj.left = obj.left + step;
      } else if (move == 3) {
        obj.top = obj.top + step;
      } else if (move == 4) {
        obj.left = obj.left - step;
      } else if (move == 5) {
        obj.angle = obj.angle + (15 % 360);
      } else if (move == 6) {
        obj.angle = obj.angle - (15 % 360);
      }

      self.fab.renderAll();
    }
  };

  const UpdateCategory = (cate) => {
    const self = getSelf();
    const obj = self.fab.getActiveObject();

    if (obj) {
      obj.__category = cate;
      obj._objects[0].set("fill", categoryDic[cate]?.Color ?? "#00ff00");
      self.fab.renderAll();
    }
  };

  const RemoveSelected = () => {
    const self = getSelf();
    const obj = self.fab.getActiveObject();
    if (obj) {
      if (!window.confirm("Are you sure you want to remove this?")) {
        return;
      }
      self.fab.remove(obj);
      self.fab.renderAll();
    }
  };

  const ClearTablesAndProjects = (confirm) => {
    if (confirm && !window.confirm(
        "Are you sure you want to remove all tables and projects?"
      )
    ) {
      return;
    }

    const self = getSelf();
    self.fab.getObjects().forEach((x) => {
      if (x.__save) {
        self.fab.remove(x);
      }
    });

    BuildProjectsList();
  };

  const SetProject = (slot, projectId) => {
    const fabric = getSelf().fab;
    let obj = fabric.getActiveObject();

    if (obj && obj._objects && obj._objects.length >= slot) {
      //remove id if already set
      let objs = fabric.getObjects();
      for (let i = 0; i < objs.length; i++) {
        let x = objs[i];

        if (!x.__save || !x.__projectIds) continue;

        for (let j = 0; j < x.__projectIds.length; j++) {
          let pid = x.__projectIds[j];
          if (pid.Id == projectId) {
            x._objects[pid.Slot].set("fill", "#48C9B0");
            arrayTools.PopAt(x.__projectIds, j);
            break;
          }
        }
      }

      if (!obj.__projectIds) obj.__projectIds = [];
      obj.__projectIds.push({ Id: projectId, Slot: slot });
      obj._objects[slot].set("fill", canvasInfo.colors[4] ?? "#0000ff");
      fabric.renderAll();
    }

    ObjectSelected(obj);
  };

  const ProjectsSet = () => {
    let response: any[] = [];
    let objs = getSelf().fab.getObjects();
    objs.forEach((x) => {
      if (x.__save && x.__projectIds) {
        response.push(x.__projectIds);
      }
    });

    return response;
  };

  const ProjectsSetHashTable = () => {
    var response = {};
    let objs = getSelf().fab.getObjects();
    objs.forEach((x) => {
      if (x.__save && x.__projectIds) {
        x.__projectIds.forEach((y) => {
          response[y.Id] = { Id: y.Id, Slot: y.Slot, FabricObj: x };
        });
      }
    });

    return response;
  };

  const AddTable = (type, col, row, project1?:IProjectFloor, project2?:IProjectFloor, project3?:IProjectFloor, backRow?:boolean) => {
    const pros: any[] = [];
    if (project1) pros.push({ Id: project1.IdInt, Slot: 1 });
    if (project2) pros.push({ Id: project2.IdInt, Slot: 2 });
    if (project3) pros.push({ Id: project3.IdInt, Slot: 3 });

    const d = {
      x: col * canvasInfo.stepSize - 1,
      y: row * canvasInfo.stepSize - 1 + (backRow ? canvasInfo.stepSize * type + 2 : 0),
      angle: backRow ? 180 : 0,
      tool: type,
      __category: project1?.CategoryId ?? -1,
      __projectIds: pros,
      __power: (project1?.NeedsOutlet || project2?.NeedsOutlet || project3?.NeedsOutlet) ? true: false,
    };

    DrawTool(d, false, true);
  };

  const Export = () => {
    const items: any[] = [];
    const self = getSelf();
    self.fab.getObjects().forEach((x) => {
      if (x.__save) {
        items.push({
          Type: x.__tool,
          X: x.left,
          Y: x.top,
          Angle: x.angle,
          Category: x.__category || null,
          ProjectIds: x.__projectIds || [],
          Power:x.__power || false,
        });
      }
    });

    return { Items: items };
  };

  const Import = (data: IFloorPlanInfo, cDic:any) => {
    if (!data.Items) return;

    let myProjects = {};
    if(data?.MyProjects)
    data?.MyProjects.forEach(x=>{
      myProjects[x+'']=true;
    });

    data.Items.forEach((item) => {
      var d = {
        x: item.X - 1,
        y: item.Y - 1,
        angle: item.Angle,
        tool: item.Type,
        __category: item.Category,
        __projectIds: item.ProjectIds || [],
        __power: item.Power || false,
      };

      let mine = false;
      if(!data.IsStaff && item.ProjectIds){
        mine = item.ProjectIds.find(x=> myProjects[x.Id]);
      }

      DrawTool(d, false, data.IsStaff, cDic, mine);
    });
  };

  const ManagerInit = () => {
    var callback = (data: IFloorPlanInfo) => {
      let self = getSelf();
      self.data = data;
      let dic = {};
      data.Categories.forEach(c=>{
        dic[c.Id] = c;
      })

      setCategoryDic(dic);
      Import(data, dic);
      setData(data);
      BuildProjectsList();
    };

    store.server
      .postApi<ServerResponse<IFloorPlanInfo>>("../FloorPlanner/getplan", {})
      .then((res) => {
        if (res.Success) {
          callback(res.Value);
        } else {
          toast.error(`Error getting data. ${res.Message}`, {
            autoClose: false,
          });
        }
      });
  };

  const ToolSelect = (type) => {
    setCanvasInfo({ ...canvasInfo, tool: type });
    getSelf().tool = type;
  };

  const Move = (move) => {
    MoveSelected(move);
  };

  const Remove = () => {
    RemoveSelected();
  };

  const saveTableAssignments = () =>{
    
    const self = getSelf();
    const obj = self.fab.getActiveObject();
    if (obj) {      
      let pros:any[] = [];
      if(obj.__tool >= 1) pros.push({ Id: form.slot1, Slot: 1});
      if(obj.__tool >= 2) pros.push({ Id: form.slot2, Slot: 2});
      if(obj.__tool >= 3) pros.push({ Id: form.slot3, Slot: 3});
      obj.__projectIds = pros;
      obj.__power = form.power;
      obj.__category = form.category;
      obj.tool = obj.__tool;

      let color = categoryDic[form.category]?.Color ?? null;
      let newblocks = ToolBlocks(obj, color);
      self.fab.remove(obj);
      let cord: any = {
        left: obj.left,
        top: obj.top,
        angle: obj.angle || 0,
        offLeft: 0,
        offTop: 0,
        snapAngle: 15,
        lockUniScaling: true,
        lockScalingX: true,
        lockScalingY: true,
        __tool: obj.__tool,
        __obj: true,
        __abc: null,
      };
      var g: any = new fabric.Group(newblocks, cord);
      g.__save = true;
      g.__projectIds = obj.__projectIds || [];
      g.__category = obj.__category || null;
      g.__power = obj.__power || false;
      self.fab.add(g);
      self.fab.setActiveObject(g);
      
      toast.success('Updated. You must still press the save button.');
      setShowEditTableAssignments(false);

      let info = getInfoFromObj(obj);
      setObjectSelected(info);
    }
    
  };

  const Save = () => {
    var requestData = Export();

    store.server
      .postApi<ServerResponse<any>>(`../FloorPlanner/SavePlan`, requestData)
      .then((x) => {
        if (x.Success) {
          toast.success("Data saved.");
        } else {
          toast.error(`Error saving data. ${x.Message}`, { autoClose: false });
        }
      });
  };

  const AutoPopulate = () => {
    if (
      !window.confirm("Are you sure you want to CLEAR and Auto Build Layout?")
    ) {
      return;
    }
    ClearTablesAndProjects(false);

    let projects = data?.Projects ?? [];
    let category = projects[0].CategoryId;
    let col = 1;
    let row = 1;
    let backRow = false;

    let groups:IProjectFloor[][] = [];
    if(autoRules.AllPowerTogether) {
      console.log('All power together');
      let pg = projects.filter(x=>x.NeedsOutlet);
      projects = projects.filter(x=>!x.NeedsOutlet);
      groups.push(pg);
    }

    let cates = {};
    projects.forEach(x=>{
      if(!cates[x.CategoryId]){
        cates[x.CategoryId] = [];
      }
      cates[x.CategoryId].push(x);
    });

    Object.keys(cates).forEach(x=>{
      let pros = cates[x];

      if(autoRules.CategoryPowerTogether){
          let temp = pros.filter(p=>p.NeedsOutlet);
          pros.filter(p=>!p.NeedsOutlet).forEach(p=>{temp.push(p)});
          pros = temp;
          
          console.log(`${pros[0].Category} category power together` );
        };

      groups.push(pros);
    });

    console.log(groups);

    //max tables per row...  
    //7px per foot...
    let maxTables = autoRules.Height / 6;
    let spaceBetweenRoows = 4;

    groups.forEach(g=>{
      for(let i = 0; i < g.length; i+=2){
        if(row > maxTables * 2){
          row = 1;
          if(backRow){
            col += 1;
          } else {
            col += 2;
          }
          backRow = !backRow;
        }

        AddTable(2, col, row, g[i], g.length > i+1 ? g[i+1] : undefined, undefined, backRow);
        row +=2;
      }
    });

    // for (let i = 0; i < projects.length; i++) {
    //   let project = projects[i];

    //   if (project.CategoryId != category) {
    //     row = 1;
    //     col += 2;
    //     category = project.CategoryId;
    //     backRow = false;
    //   } else if (row > 33) {
    //     row = 1;
    //     if(backRow){
    //       col += 1;
    //     } else {
    //       col += 2;
    //     }
    //     backRow = !backRow;
    //   }

    //   let project2:any = null;
    //   if (i + 1 < projects.length && projects[i + 1].CategoryId == category) {
    //     i++;
    //     project2 = projects[i];
    //   }

    //   AddTable(2, col, row, project, project2, undefined, backRow);

    //   row += row % 2 == 0 ? 3 : 2;
    // }

    BuildProjectsList();
    setShowAutoRules(false);
    toast.success(`We have built out floor, you need to save it if you want to keep the changes.`);
  };

  const BuildProjectsList = () =>{
    let projects = data?.Projects;
    if(projects){
      const hash:any = {};
      
    const self = getSelf();
    self.fab.getObjects().forEach((x) => {
      if (x.__projectIds) {
        x.__projectIds.forEach(xx=>{
          hash[xx.Id] = true;
        })
      }
    });
      let p:IProjectFloor[] = [];
      let n:IProjectFloor[] = [];

      projects.forEach(x=>{
        if(hash[x.IdInt]){
          p.push(x);
        } else {
          n.push(x);
        }
      });
      setPlacedProjectHash(hash);
      setPlacedProjects(p);
      setNotPlacedProjects(n);
    }
  };

  const ScaleUp = ()=>{
    Scale(currentScale +.05);};
  const ScaleDown = ()=>{
    Scale(currentScale -.05);
  };
  const Scale = (value:number)=>{
    setCurrentScale(value);
    const fab = getSelf().fab;
    //fab.setDimensions({width: fab.getWidth()*value, height: fab.getHeight() * value});
    fab.setZoom(value);
  };

  return (
    <div className="floor-planner">
      <div className="">
        {data?.IsStaff && <div className="flex-between">
          <h3>Contest Floor Planner</h3>
          <div className="">
            {/* @if (Model.IsStaff)
            { */}
            <button
              type="button"
              className="btn btn-default"
              onClick={() => ScaleUp()}
            >
              <Icon type={IconType.plus}/>
            </button>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => ScaleDown()}
            >
              <Icon type={IconType.minus}/>
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => setShowAutoRules(true)}
            >
              <i className="fad fa-wand-magic"></i> Auto Build
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                Save();
              }}
            >
              <i className="fa fa-floppy-o"></i> Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                ClearTablesAndProjects(true);
              }} 
            >
              <i className="fa fa-trash-can"></i> Reset
            </button>
            {/* } */}
          </div>
        </div>}
        {!data?.IsStaff && <div>
            <h3>Entry Floor Layout</h3>
          </div>}
        <div className="canvas-container" ref={containerRef}>
          <canvas ref={canvasRef} id="main-board"></canvas>
        </div>
        {data?.IsStaff && <>
        <div className="flex-between">
          <div className="">
            <strong>Tool</strong>
            <button
              type="button"
              className={`btn btn-secondary btn-block btn-xs ${
                canvasInfo.tool === 2 ? "btn-primary" : ""
              }`}
              onClick={() => {
                ToolSelect(2);
              }}
            >
              2 Entry Table
            </button>
            <button
              type="button"
              className={`btn btn-secondary btn-block btn-xs ${
                canvasInfo.tool === 1 ? "btn-primary" : ""
              }`}
              onClick={() => {
                ToolSelect(1);
              }}
            >
              1 Entry Table
            </button>
            <button
              type="button"
              className={`btn btn-secondary btn-block btn-xs ${
                canvasInfo.tool === 3 ? "btn-primary" : ""
              }`}
              onClick={() => {
                ToolSelect(3);
              }}
            >
              3 Entry Table
            </button>
            <button
              type="button"
              className={`btn btn-secondary btn-block btn-xs ${
                canvasInfo.tool === 0 ? "btn-primary" : ""
              }`}
              onClick={() => {
                ToolSelect(0);
              }}
            >
              None
            </button>
          </div>
          <div className="">
            { objectSelected && <div className="table-assignments">
              <div className="flex-between">
              <div>Slots {objectSelected.Projects.length} of {objectSelected.Slots.length}</div>
              <div>
                <button type="button" className="btn btn-xs btn-default" title="edit" onClick={setShowEditTableAssignments}>
                  <Icon type={IconType.edit}/> Edit
                </button>
              </div>
              </div>
              {objectSelected.Slots.map((x,i)=>{
                let p = objectSelected.Projects.find(r=>r.IdInt === +x.id);
                return <div key={`slot-${i}`}>Slot {i+1}: 
                  {p?.ProjectId}  {p?.Title}
                  {data.ShowcaseEnabled && p?.Key && <button type="button" className="btn btn-info btn-xs" onClick={()=>{
                    setSelectedProject(p?.Key);
                    setShowShowcase(true);
                  }}><Icon type={IconType.personBooth}/> view details</button>}
                </div>
              })}

<div>Category: {categoryDic[objectSelected.category]?.Name}</div>
              {objectSelected.power && <div><Icon type={IconType.power}/> Power</div>}

            </div>

            }
          </div>
          <div className="">
            <strong>Move</strong>
            <br />
            <table className="">
              <tbody>
                <tr>
                  <td></td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-secondary btn-xs"
                      onClick={() => {
                        Move(1);
                      }}
                    >
                      <i className="fa fa-chevron-square-up"></i>
                    </button>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <button
                      type="button"
                      className="btn btn-secondary btn-xs"
                      onClick={() => {
                        Move(4);
                      }}
                    >
                      <i className="fa fa-chevron-square-left"></i>
                    </button>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-secondary btn-xs"
                      onClick={() => {
                        Move(5);
                      }}
                    >
                      <i className="fa fa-directions"></i>
                    </button>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-secondary btn-xs"
                      onClick={() => {
                        Move(2);
                      }}
                    >
                      <i className="fa fa-chevron-square-right"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-secondary btn-xs"
                      onClick={() => {
                        Move(3);
                      }}
                    >
                      <i className="fa fa-chevron-square-down"></i>
                    </button>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-secondary btn-xs"
                      onClick={() => Remove()}
                    >
                      <i className="fa fa-trash-can text-danger"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="head-room"></div>
        <div id="projects-container"></div>
        </>}
      </div>

      <div className="floor-tools"></div>
      <div id="obj-tools"></div>

      {showEditTableAssignments && 
        <Modal 
        setModalOpen={setShowEditTableAssignments} 
        title={"Table Assignments"} 
        size="l"       >
          <>
          <div className="form-horizontal">
            <div className="form-group">
              <label className="col-sm-4 control-label" htmlFor="floor-s1">Slot 1</label>
              <div className="col-sm-8">
                  <select id="floor-s1" className="form-control" placeholder='entry' value={form.slot1} onChange={(e)=>{setForm({...form, slot1:e.target.value})}}>
                  <option value={-1}>not set</option>
                    {data?.Projects.map((p, i)=>{
                      const assigned = placedProjectHash[p.IdInt];
                      const style = assigned ? 'placed' : ''
                      return <option key={`s1-${i}`} className={style} value={p.IdInt}>{p.ProjectId}  {p.Title}</option>
                    })}  
                  </select>
                </div>              
            </div>
            {objectSelected?.Slots?.length && objectSelected?.Slots?.length > 1 && <div className="form-group">
              <label className="col-sm-4 control-label" htmlFor="floor-s2">Slot 2</label>
              <div className="col-sm-8">
                  <select id="floor-s2" className="form-control" placeholder='entry' value={form.slot2} onChange={(e)=>{setForm({...form, slot2:e.target.value})}}>
                  <option value={-1}>not set</option>
                    {data?.Projects.map((p, i)=>{
                      const assigned = placedProjectHash[p.IdInt];
                      const style = assigned ? 'placed' : ''
                      return <option key={`s2-${i}`} className={style} value={p.IdInt}>{p.ProjectId}  {p.Title}</option>
                    })}  
                  </select>
                </div>              
            </div>}
            {objectSelected?.Slots?.length && objectSelected?.Slots?.length > 2 && <div className="form-group">
              <label className="col-sm-4 control-label" htmlFor="floor-s3">Slot 3</label>
              <div className="col-sm-8">
                  <select id="floor-s3" className="form-control" placeholder='entry' value={form.slot3} onChange={(e)=>{setForm({...form, slot3:e.target.value})}}>
                  <option value={-1}>not set</option>
                    {data?.Projects.map((p, i)=>{
                      const assigned = placedProjectHash[p.IdInt];
                      const style = assigned ? 'placed' : ''
                      return <option key={`s3-${i}`} className={style} value={p.IdInt}>{p.ProjectId}  {p.Title}</option>
                    })}  
                  </select>
                </div>              
            </div>}
            <div className="form-group">
              <label className="col-sm-4 control-label" htmlFor="floor-cate">Category</label>
              <div className="col-sm-8">
                  <select id="floor-cate" className="form-control" placeholder='entry' value={form.category} onChange={(e)=>{setForm({...form, category:e.target.value})}}>
                  <option value={-1}>not set</option>
                    {data?.Categories.map((cate, i)=>{
                      return <option key={`cate-${i}`} value={cate.Id}>{cate.Name}</option>
                    })}  
                  </select>
                </div>              
            </div>
            <div className="form-group">
              <div className="col-sm-8 col-sm-offset-4">
                  <input type="checkbox" id="floor-power" className="" placeholder='entry' checked={form.power ?? false} onChange={(e)=>{setForm({...form, power:e.target.checked})}}/>
              <label className="control-label" htmlFor="floor-power">Table has power</label>
                </div>              
            </div>
            <div className="form-group">
              <div className="col-sm-offset-4 col-sm-8">
                <button type="button" className="btn btn-secondary" title="save" onClick={saveTableAssignments}>
                  <Icon type={IconType.save} /> Save
                </button>
                <button type="button" className="btn btn-default" title="close" onClick={()=>{setShowEditTableAssignments(false)}}>
                  <Icon type={IconType.close} /> Close
                </button>
              </div>
            </div>
          </div>

          </>
        </Modal>
      }

      {showAutoRules && <Modal
          title="Auto Populate Floor Planer"
          setModalOpen={setShowAutoRules}
          size="l"
        ><>
        
          
        <div className="form-horizontal">
            <div className="form-group">
              <label className="col-sm-4 control-label" htmlFor="Width">Room Size</label>
              <div className="col-sm-8">
                  <input type="number" id="Width" className="form-control" placeholder='room length' 
                  value={autoRules.Width} onChange={(e)=>{setAutoRules({...autoRules, Width:+e.target.value})}}
                  />
                </div>              
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label" htmlFor="Height">By</label>
              <div className="col-sm-8">
                  <input type="number" id="Height" className="form-control" placeholder='room width' 
                  value={autoRules.Height} onChange={(e)=>{setAutoRules({...autoRules, Height:+e.target.value})}}
                  />
                </div>              
            </div>
            <div className="form-group">
              <div className="col-sm-8 col-sm-offset-4">
                  <input type="checkbox" id="CategoryPowerTogether" className="" placeholder='entry' checked={autoRules.CategoryPowerTogether ?? false} onChange={(e)=>{setAutoRules({...autoRules, CategoryPowerTogether:e.target.checked})}}/>
              <label className="control-label" htmlFor="CategoryPowerTogether">Keep Category Power Users Together</label>
                </div>              
            </div>
            <div className="form-group">
              <div className="col-sm-8 col-sm-offset-4">
                  <input type="checkbox" id="AllPowerTogether" className="" placeholder='entry' checked={autoRules.AllPowerTogether ?? false} onChange={(e)=>{setAutoRules({...autoRules, AllPowerTogether:e.target.checked})}}/>
              <label className="control-label" htmlFor="AllPowerTogether">Keep Power Users Together</label>
                </div>              
            </div>
            <div className="form-group">
              <div className="col-sm-offset-4 col-sm-8">
                <button type="button" className="btn btn-secondary" title="save" onClick={AutoPopulate}>
                  <Icon type={IconType.flag} /> Run
                </button>
                <button type="button" className="btn btn-default" title="close" onClick={()=>{setShowAutoRules(false)}}>
                  <Icon type={IconType.close} /> Close
                </button>
              </div>
            </div>
          </div>

          </>
        </Modal>}
        {showShowcase && selectedProject && <Modal 
        setModalOpen={setShowShowcase} 
        title={"Entry Details"}
        size="xxl">
          <ProjectDetailsWraper ProjectKey={selectedProject}  />
          </Modal>}



      <div className="hide">
<img src={imgProject} ref={imgProjectRef} />
<img src={imgBolt} ref={imgBoltRef} />
<img src={imgTable1} ref={imgTable1Ref} />
<img src={imgTable2} ref={imgTable2Ref} />
<img src={imgTable3} ref={imgTable3Ref} />
<img src={imgShadow1} ref={imgShadow1Ref} />
<img src={imgShadow2} ref={imgShadow2Ref} />
<img src={imgShadow3} ref={imgShadow3Ref} />

      </div>
    </div>
  );
};


  const defaultRules= ():IFloorAutoPopRules =>{
    return {
      CategoryPowerTogether:false,
      AllPowerTogether:false,
      Width:90,
      Height:49,
       
    }
  }
export default FloorPlanner;
