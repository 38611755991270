import { RouteComponentProps, withRouter } from "react-router-dom";
import {IState, ReduxMap } from "../../../redux/redux";
import FlexFlowManager from "../../../Components/FlexFlow/Setup/FlexFlowManager";

interface IProps extends RouteComponentProps<IParams>, IState{

}
interface IParams {
  flowId: string;
}

const FlexFlowManagerPage = (props:IProps) =>{

    return <>
        {props.IsReady && <FlexFlowManager state={props} flowId={props.match.params.flowId} />}
    </>;
};

export default withRouter(ReduxMap(FlexFlowManagerPage));