import React, { useState } from "react";
import JudgesView from "./JudgesView";
import Prompts from "./Prompts";
import Scores from "./Scores";
import Load from "./Load";
import LoadJudges from "./LoadJudges";
import AcaDecaTools from "./AcaDecaTools";
import Icon, { IconType } from "../../Icon/Icon";
import SetupWizard from "../../Setup/SetupWizard";

// type OnTab = 'landing' | 'judges' | 'prompts' | 'scores' | 'load'

const AcaDecaContainer = (props) => {
  const [onTab, setOnTab] = useState("start");

  return (
    <div>
      <div className="col-sm-12x">
        <ul className="nav nav-tabs" role="tablist">
          <li role="presentation" className="active">
            <a
              href="#start"
              aria-controls="round-details-setup"
              role="tab"
              data-toggle="tab"
              onClick={() => setOnTab("start")}
            >
              <Icon type={IconType.start} />1 - Start
            </a>
          </li>
          <li role="presentation" className="">
            <a
              href="#config"
              aria-controls="round-details-setup"
              role="tab"
              data-toggle="tab"
              onClick={() => setOnTab("config")}
            >
              <Icon type={IconType.configure} />2 - Configure
            </a>
          </li>
          <li role="presentation" className="">
            <a
              href="#load"
              aria-controls="round-details-setup"
              role="tab"
              data-toggle="tab"
              onClick={() => setOnTab("load")}
            >
              <i className="fad fa-cloud-upload"></i>3 - Load Students
            </a>
          </li>
          <li role="presentation" className="">
            <a
              href="#loadJudges"
              aria-controls="round-details-setup"
              role="tab"
              data-toggle="tab"
              onClick={() => setOnTab("load-judges")}
            >
              <i className="fad fa-cloud-upload"></i>4 - Load Judges
            </a>
          </li>
          <li role="presentation" className="">
            <a
              href="#judges"
              aria-controls="round-details-projects"
              role="tab"
              data-toggle="tab"
              onClick={() => setOnTab("judges")}
            >
              <i className="far fa-gavel"></i>
              Judge Management
            </a>
          </li>
          <li role="presentation" className="">
            <a
              href="#scores"
              aria-controls="round-details-setup"
              role="tab"
              data-toggle="tab"
              onClick={() => setOnTab("scores")}
            >
              <i className="far fa-tally"></i>
              Scores
            </a>
          </li>
          <li role="presentation" className="">
            <a
              href="#prompts"
              aria-controls="round-details-prompts"
              role="tab"
              data-toggle="tab"
              onClick={() => setOnTab("prompts")}
            >
              <i className="fas fa-terminal"></i>
              Prompts
            </a>
          </li>
          <li role="presentation" className="">
            <a
              href="#tools"
              aria-controls="round-details-judges"
              role="tab"
              data-toggle="tab"
              onClick={() => setOnTab("tools")}
            >
              <i className="fad fa-tools"></i>
              Tools
            </a>
          </li>
        </ul>
      </div>
      {onTab === "landing" && (
        <div>
          <h2>Academic Decathlon Landing</h2>
          {/* <div
            className="judging-round soft-border hover-shadow"
            onClick={() => setOnTab('judges')}
          >
            Judges View
          </div>
          <div
            className="judging-round soft-border hover-shadow"
            onClick={() => setOnTab('prompts')}
          >
            Impromptu Speech Prompts
          </div>
          <div
            className="judging-round soft-border hover-shadow"
            onClick={() => setOnTab('scores')}
          >
            Scores
          </div> */}
        </div>
      )}

      {onTab === "start" && (
        <>
          <div className="col-sm-12">
            <h2>Getting Started</h2>
            Academic Speach and Interview Portal. Move through the
            steps (tabs) on this page to configure to run the Speach and
            Iterview portion of your Acadeca Contest.            
            <hr />
            <div>
            </div>
          </div>
        </>
      )}
      {onTab === "config" && 
        <>
          <div className="col-sm-12">
            <h2>Configure</h2>
            <hr />            
            <SetupWizard wizardKey={'AcaDecaSetupWizard'} />
          </div>
        </>}
      {onTab === "judges" && <JudgesView />}
      {onTab === "prompts" && <Prompts />}
      {onTab === "scores" && <Scores />}
      {onTab === "load" && <Load />}
      {onTab === "load-judges" && <LoadJudges />}
      {onTab === "tools" && <AcaDecaTools />}
    </div>
  );
};

export default AcaDecaContainer;
