import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import JudgesUpload from './JudgesUpload';
import OtherUserUpload from './OtherUserUpload';
import ParticipantsUpload from './ParticipantsUpload';
import ModeratorInterpreterUpload from './ModeratorInterpreterUpload';
import SchoolUpload from './SchoolUpload';
import { useState } from 'react';
import { useEffect } from 'react';
import { store } from '../../redux/oldStore';


const Import = (props: any) => {
  const [callParticipant, setCallParticipant] = useState('')

  useEffect(() => {
    store.getSettings(false).then(res => setCallParticipant(res?.Settings.text.ParticipantLabel))

  }, [])



  return(
    <div className='bumper-l bumper-out-l'>
      <Tabs>
        <TabList>
          <Tab>{callParticipant}s</Tab>
          <Tab>Schools</Tab>
          <Tab>Judges</Tab>
          <Tab>Other Users</Tab>
          <Tab>Proctors & Interpreters</Tab>
        </TabList>
        <TabPanel>
          <div className='bumper-l bumper-out-l'>
            <ParticipantsUpload />
          </div>
        </TabPanel>
        <TabPanel>
          <div className='bumper-l bumper-out-l'>
            <SchoolUpload />
          </div>
        </TabPanel>
        <TabPanel>
          <div className='bumper-l bumper-out-l'>
            <JudgesUpload />
          </div>
        </TabPanel>
        <TabPanel>
          <div className='bumper-l bumper-out-l'>
            <OtherUserUpload />
          </div>
        </TabPanel>
        <TabPanel>
          <div className='bumper-l bumper-out-l'>
            <ModeratorInterpreterUpload/>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default Import 