import { RouteComponentProps, withRouter } from "react-router-dom";
import {IState, ReduxMap } from "../../redux/redux";
import FlexFlowManager from "../../Components/FlexFlow/Setup/FlexFlowManager";
import FlexFlow from "../../Components/FlexFlow/FlexFlow";

interface IProps extends RouteComponentProps<IParams>, IState{

}
interface IParams {
  flowId: string;
  recordId: string;
}

const FlexFlowPage = (props:IProps) =>{

  //console.log('Page...',props.match.params);

    return <>
        {props.IsReady && <FlexFlow state={props} flowId={props.match.params.flowId} recordId={props.match.params.recordId} />}
    </>;
};

export default withRouter(ReduxMap(FlexFlowPage));