import React, { useState, useEffect } from "react";
import "../Exam/ExamStyle.css";
import Icon, { IconType } from "../Icon/Icon";

import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";

interface IExamLandingProps extends RouteComponentProps<any> {}

const ExamLanding = (props: IExamLandingProps) => {
  const goTo = (to: "dashboard" | "schedule" | "builder") => {
    switch (to) {
      case "dashboard":
        props.history.push(`/app/exam/dashboard/${props.location.search}`);
        break;
      case "builder":
        toast.info("Exam builder, not enabled for demo!");
        break;
      case "schedule":
        toast.info("Schedule & Invite, not enabled for demo!");
        break;
        default:
            toast.warning('Unknown action!');
            break;
    }
  };

  return (
    <>
      <div className="bumper">
        <div>
          <h3>zFairs Exam</h3>
        </div>
        <hr />

        <div className="exam-landing-tile-list">
          <div className="exam-landing-tile">
            <h4>
              <Icon type={IconType.dashboard} /> Exam Dashboard
            </h4>

            <div>
              <hr />
            </div>
            <div className="grow">
              Find and manage an exam, use this to review, manage, and control
              past, current, and future exams.
            </div>

            <div>
              <hr />
            </div>
            <div className="text-right">
              <button
                type="buton"
                className="btn btn-secondary"
                onClick={() => {
                  goTo("dashboard");
                }}
              >
                Open <Icon type={IconType.next} />
              </button>
            </div>
          </div>
          <div className="exam-landing-tile">
            <h4>
              <Icon type={IconType.calendar} /> Schedule & Invite
            </h4>

            <div>
              <hr />
            </div>
            <div className="grow">
              Schedule and/or invite others to take an exam, or an exam packet.
            </div>

            <div>
              <hr />
            </div>
            <div className="text-right">
              <button
                type="buton"
                className="btn btn-secondary"
                onClick={() => {
                  goTo("schedule");
                }}
              >
                Open <Icon type={IconType.next} />
              </button>
            </div>
          </div>
          <div className="exam-landing-tile">
            <h4>
              <Icon type={IconType.build} /> Exam Builder
            </h4>
            <div>
              <hr />
            </div>
            <div className="grow">
              Build and manage your exam or exam packet, including exam content.
            </div>
            <div>
              <hr />
            </div>
            <div className="text-right">
              <button
                type="buton"
                className="btn btn-secondary"
                onClick={() => {
                  goTo("builder");
                }}
              >
                Open <Icon type={IconType.next} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ExamLanding);
