import { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import StoreX, { store } from '../../redux/oldStore';
import { FormatDate } from '../../utils/Tools';
import Icon, { IconType } from '../Icon/Icon';
import { getFileStatusClass, getFileStatusIconClass, getFileType } from '../Paperwork/paperworkReviewUtilities';
import { fileType } from '../Paperwork/paperworkTypes';
import { isefButton } from './ParticipantFileManager';
import './ParticipantManagement.css';

type FileListItemProps = {
  // uploadedFile: uploadedFile,
  // fileTypes: fileType[],
  // project: project
  uploadClick: () => void;
  commentClick: Function;
  deleteClick: Function;
  button: isefButton;
  url: any;
  fileType: fileType;
  canUpload: boolean;
};

const getFileStatusIconClassLocal = (button: isefButton) => {
  let className = getFileStatusIconClass(button.status);
  if ((button.required || button.isIsefRequired) && !button.status && !button.internalFileName) {
    className = 'fal fa-file-upload fa-3x';
  }
  return className;
};

const getCorrectedButtonStatus = (button: isefButton) => {
  if ((button.required || button.isIsefRequired) && !button.status && !button.internalFileName) {
    return 'Required';
  }
  return button.status;
};

const FileListItem: FunctionComponent<FileListItemProps> = ({ button, uploadClick, url, fileType, canUpload, deleteClick, commentClick }: FileListItemProps) => {
  const removeFile = () => {
    if (window.confirm(`Are you sure you want to delete file "${button.fileName}"? \r\n\r\nThis cannot be undone.`)) {
      store.server.postApi<any>(`../PaperworkReview/RemoveFileUpload`, { fileId: button.fileId }).then((x) => {
        if (x.Success) {
          deleteClick();
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
    }
  };

  return (
    <>
      <div className={`file ft-${button.fileType}`}>
        <div className="flex-between">
          <span className={'span-button ' + getFileStatusClass(button.status)} data-toggle="tooltip" data-placement="top" title={getCorrectedButtonStatus(button)}>
            <i className={getFileStatusIconClassLocal(button)}></i>
          </span>
          <div>
            <strong>
              {(button.isIsefRequired || button.required) && <>*</>}
              {button.fileName}
            </strong>
            <div>
              <>
                { fileType.blankFilePath && <>
                  <a href={fileType.blankFilePath} target="_blank">
                    <i className="fal fa-cloud-download"></i> Template File
                  </a>
                  {'    '}
                </>}
                {(button.isIsefRequired || button.required) && (
                  <span className="label label-danger">
                    <i className="fa fa-exclamation-triangle"></i> required
                  </span>
                )}

                {button.internalFileName && button.internalFileName.length > 0 && (
                  <span className="label label-success">
                    <Icon type={IconType.checkSquareO} /> file uploaded
                  </span>
                )}
              </>
            </div>

            {fileType?.description && <div className="file-description" dangerouslySetInnerHTML={{ __html: fileType.description }}></div>}
          </div>
          {button.comments && (
            <span
              className="click"
              onClick={() => {
                commentClick();
              }}
            >
              <i className="fas fa-comment text-info fa-2x" title={button.comments}></i>
            </span>
          )}
        </div>

        <div className="flex space-div">
          {canUpload && (
            <div onClick={uploadClick} className="click select-upload-file text-right">
              <span title="click to upload file">
                <i className="fal fa-cloud-upload"></i> Upload File
              </span>
              {fileType && fileType.FreezeDate && (
                <div>
                  <Icon type={IconType.lock} /> {fileType.FreezeDateString}
                </div>
              )}
            </div>
          )}
          {button.internalFileName && button.internalFileName.length > 0 && (
            <div className="click view-file">
              <a href={`${url.url}`} target="_blank">
                <i className="fal fa-file" /> View File
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="click text-danger" onClick={removeFile}>
                <Icon type={IconType.trashO} /> Delete
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FileListItem;
