import React, { Component } from 'react'
import io from "socket.io-client";

import { toast } from 'react-toastify';
import { store } from "../../redux/oldStore";
import SBVideo from './SBVideo'
import Modal from '../Modal/Modal';

interface MyInfo {
  judge: boolean;
  meetingId: string;
  name: string;
  project: any;
  userId: string;
  givenName: string;

}

interface BeeParticipant {
  gameStatus: string;
  personId: string;
  status: string;
  isJudge: boolean;
  passCount: number;
  failCount: number;
  name: string;
}

interface SpellingBeeContainerState {
  myInfo: MyInfo;
  beeParticipants: BeeParticipant[];
  currentParticipant: BeeParticipant;
  isStarted: boolean,
  inVideoRoom: boolean;
  passDataModalOpen: boolean;
  passDataMessage: string;
  announcementModalOpen: boolean;
  announcementVal: string;
  failCountForElimination: number;
}

class SpellingBeeContainer extends Component<any, SpellingBeeContainerState>{
  socketMgr;
  constructor(props) {
    super(props)
    this.state = {
      myInfo: {
        judge: false
        , meetingId: ''
        , name: ''
        , project: {}
        , userId: ''
        , givenName: ''
      },
      beeParticipants: [],
      currentParticipant: {
        gameStatus: '',
        personId: '',
        isJudge: false,
        status: '',
        passCount: 0,
        failCount: 0,
        name: ''
      },
      isStarted: false,
      inVideoRoom: false,
      passDataModalOpen: false,
      passDataMessage: '',
      announcementModalOpen: false,
      announcementVal: '',
      failCountForElimination: 1,

    }
    this.socketMgr = {
      init: () => {
        let url =
          window.location.host.indexOf("localhost") > -1
            ? "http://localhost:9191/spelling-bee" // localhost || 10.25.0.1
            : "https://socket.zfairs.com/spelling-bee";
        let socket = (this.socketMgr.socket = io(url, {
          query: {
            beeId: this.state.myInfo.meetingId, //possibly poorly scoped. May have to pass in the stuff here
            personId: this.state.myInfo.userId,
            isJudge: this.state.myInfo.judge,
            name: this.state.myInfo.givenName
            // fairId: this.state.fairId,
            // clientId: this.state.clientId,
            // personId: this.state.personId,
            // projectId: this.state.projectId,
            // judge: false,
          },
        }));

        socket.on('bee-status', (x) => {
          // on - bee status - update user list and status
          console.log('bee-status received', x);
          this.setState({
            beeParticipants: x.beeParticipants,
            currentParticipant: x.currentParticipant || { gameStatus: '', personId: '', status: '' },
            isStarted: x.isStarted

          });
        });

        socket.on('pass-data', (x) => {
          // on - pass data - show message, remove user if it is their id, join video meeting if it is their id, boot leave meeting if it is their Id
          console.log('pass-data received', x);
          switch (x.type) {
            case 'announcement':
            default:
                return this.setState({ passDataModalOpen: true, passDataMessage: x.message})
          }
        });

        socket.on('next', (x: BeeParticipant) => {
          console.log('next received', x);
          if (x === null || x === undefined) { return toast.info('no one is ready right now'); }
          this.setState({ currentParticipant: x });
          if (x.personId === this.state.myInfo.userId) {this.setState({ inVideoRoom: true });} else {this.setState({ inVideoRoom: false}) }
        });

        socket.on('boot', (x: BeeParticipant) => {
          console.log('boot received', x);
          if (x === null) { return; }
          this.setState({
            currentParticipant: {
              gameStatus: '',
              personId: '',
              isJudge: false,
              status: '',
              passCount: 0,
              failCount: 0,
              name: '',
            }
          });
          if (x.personId === this.state.myInfo.userId) this.setState({ inVideoRoom: false });
        });



        socket.on('bee-end', () => {
          this.handleNotReady();
          this.setState({ inVideoRoom: false });
          toast.success('The bee has ended. Thank you.');

        })

        socket.on('clear-bee', () => {
          this.handleNotReady();
          this.setState({ inVideoRoom: false });
          toast.error('Bee data cleared.');

        })

        socket.on('disconnect', () => {
          console.log('we got disconnected')

          // this.init();
        })

      }
    }
  };

  // TODO: 
  // 1 - layout ---- need some height responsiveness. Possibly add some flex-wrap 
  // 2 - api call for identity
  // 3 - api calls based off identity for info to display
  // 4 - video component
  // 5 - socket functionality


  handleStartBee = () => {
    // judge button - start bee - emit to server that the meeting bee is starting
    if (window.confirm('Do you want to start this Spelling Bee?')) this.socketMgr.socket.emit('bee-start');
  };

  handleEndBee = () => {
    // judge button - end bee - emit to server that the meeting has ended
    if (window.confirm('Do you want to end this Spelling Bee?')) this.socketMgr.socket.emit('bee-end');
  };

  handleBoot = () => {
    // judge button - boot - emit to the server, include the id of the one to boot (kick out of video meeting)
    this.socketMgr.socket.emit('boot');
  };

  handleNext = () => {
    // judge button - next - emit to the server include the id of the next one (starts video for the next person)
    if (this.state.currentParticipant.personId) return this.socketMgr.socket.emit('boot')
    this.socketMgr.socket.emit('next');
  };

  handleReady = () => {
    this.socketMgr.socket.emit('set-user-status', 'ready');
  };

  handleNotReady = () => {
    this.socketMgr.socket.emit('set-user-status', 'notReady');
    if (this.state.inVideoRoom) this.setState({ inVideoRoom: false })

  };

  handlePass = () => {
    // judge button  // moves person on to next round. Does not end video
    this.socketMgr.socket.emit('pass', this.state.currentParticipant.personId);
  };

  handleFail = () => {
    // judge button // eliminates person?. Does not end video
    this.socketMgr.socket.emit('fail', this.state.currentParticipant.personId);
    // TODO: this will eliminate the person after 1 fail. Will need a way to adjust how many fails are needed
    this.socketMgr.socket.emit('set-user-game-status', this.state.currentParticipant.personId, 'eliminated');
  };

  // pass-undo
  // fail-undo

  handleClearBee = () => {
    if (window.confirm('Do you want to clear this spelling bee data? This cannot be undone.')) {
      this.socketMgr.socket.emit('clear-bee');
      // toast.success('bee data cleared. Refresh your browser to start a new one.');
    }
  }

  handleAnnouncementClick = () => {
    this.setAnnouncementModalOpen(true);

  }

  setAnnouncementModalOpen = (bool: boolean) => {
    this.setState({
      announcementVal: '',
      announcementModalOpen: bool
    })
  };

  setPassDataModalOpen = (bool: boolean, message?: string) => {
    this.setState({
      passDataModalOpen: bool,
      passDataMessage: message || ''
    })
  };

  sendAnnouncementClick = () => {
    this.socketMgr.socket.emit('pass-data', {type: 'announcement', message: this.state.announcementVal});
    this.setAnnouncementModalOpen(false);
  };

  componentDidMount() {
    this.doThisFirst();
  };

  doThisFirst = async () => {
    await store.server.getApi('../Spelling/Info')
      .then((res: any) => {
        console.log(res); // meetingId, judge?, students have project, { projectKey } , name
        this.setState({ myInfo: res.Value });
        // this.socketMgr.init(res.Value);
      })
      .catch(err => {
        toast.error(err)
      })
  }

  componentDidUpdate(prevProps: any, prevState: SpellingBeeContainerState) {
    if (this.state.myInfo.meetingId !== prevState.myInfo.meetingId) {
      this.init();
    }

    if (this.state.beeParticipants?.filter(bp => bp.personId === this.state.myInfo.userId)[0]?.gameStatus !== prevState.beeParticipants?.filter(bp => bp.personId === this.state.myInfo.userId)[0]?.gameStatus) {
      if (this.state.beeParticipants?.filter(bp => bp.personId === this.state.myInfo.userId)[0]?.gameStatus === 'eliminated') {
        toast.error('you have been eliminated')
      }
    }
    if (this.state.beeParticipants?.filter(bp => bp.personId === this.state.myInfo.userId)[0]?.passCount > prevState.beeParticipants?.filter(bp => bp.personId === this.state.myInfo.userId)[0]?.passCount) {
        toast.success('you have passed this round')
    }

  }

  init = async () => {
    console.log('calling the init')
    await this.socketMgr.init();
  };




  render() {

    const mappedUsers = this.state.beeParticipants?.filter( bp => !bp.isJudge).map((user: BeeParticipant, i: number) => {
      // user cards set a class based on status (status-ready, status-not-ready, status-active, status-eliminated)
      return (
        <div
          key={i}
          className={`
            user-card 
            ${user.status} 
            ${user?.personId === this.state.currentParticipant?.personId ? 'current' : ''}
            ${user.gameStatus} 
            flex-column
          `}
        >
          <i className="fas fa-user fa-3x" />
          {user.name}
        </div>
      )
    });

    return (
      <div className='spelling-bee-container'>
        <div className='flex parent-section'>
          <div className='video-section'>
            {this.state.myInfo.judge
              ?
              <SBVideo
                meetingId={this.state.myInfo.meetingId}
                userId={this.state.myInfo.userId}
              />
              : this.state.inVideoRoom
              &&
              <SBVideo
                meetingId={this.state.myInfo.meetingId}
                userId={this.state.myInfo.userId}
              />
            }
          </div>
          <div className='some-info-section'>
            {this.state.myInfo?.judge
              ?
              <div className='inside-info-section'>
                <button
                  className='btn btn-danger bumper-out-l bumper-l'
                  onClick={this.handleClearBee}
                >
                  Clear Bee
                </button>
                <button
                  className='btn btn-primary bumper-out-l bumper-l'
                  onClick={this.handleAnnouncementClick}
                >
                  Announcement
                </button>
                {this.state.isStarted
                  ?<>
                  <button
                    className='btn btn-light bumper-out-l bumper-l'
                    onClick={this.handleEndBee}
                    >
                    End Bee
                  </button>
                    {this.state.currentParticipant.personId
                      ?
                      <button
                        className='btn btn-warning bumper-out-l bumper-l'
                        onClick={() => this.handleBoot()}
                      >
                        Boot
                      </button>
                      :
                      <button
                        className='btn btn-info bumper-out-l bumper-l'
                        onClick={() => this.handleNext()}
                      >
                        Next
                      </button>
    
                    }

                  </>
                  :
                  <button
                    className='btn btn-primary bumper-out-l bumper-l'
                    onClick={this.handleStartBee}
                  >
                    Start Bee
                  </button>
                }
                {this.state.currentParticipant.personId
                  && <>
                    <button
                      className='btn btn-success bumper-out-l bumper-l'
                      onClick={this.handlePass}
                    >
                      Pass
                    <span>  </span>
                    <i className="far fa-thumbs-up"></i>
                    </button>
                    <button
                      className='btn btn-danger bumper-out-l bumper-l'
                      onClick={this.handleFail}
                    >
                      Fail
                      <span>  </span>
                      <i className="far fa-thumbs-down"></i>
                    </button>

                  </>
                }
              </div>
              :
              <div>
                {this.state.beeParticipants.some(bp => bp.status === 'ready' && bp.personId === this.state.myInfo.userId)
                  ?
                  <button
                    className='btn btn-secondary bumper-out-l bumper-l'
                    onClick={this.handleNotReady}
                  >
                    Not Ready
                  </button>
                  :
                  <button
                    className='btn btn-success bumper-out-l bumper-l'
                    onClick={this.handleReady}
                  >
                    Ready
                  </button>

                }
              </div>
            }
          </div>
        </div>
        <div className='participants-section'>
          {mappedUsers}
        </div>
        {this.state.announcementModalOpen 
          && <Modal
              title='room announcement'
              setModalOpen={this.setAnnouncementModalOpen}
              >
                <div>
                  <label></label>
                  <textarea
                    onChange={(e) => this.setState({ announcementVal: e.target.value })}
                    value={this.state.announcementVal}
                    placeholder='What do you want to say to everyone?'
                    autoFocus={true}
                  />
                </div>
                <div>
                  <button
                    onClick={this.sendAnnouncementClick}
                    className='btn btn-secondary bumper-l bumper-out-l'
                  >
                    Send
                  </button>

                  <button
                    onClick={() => this.setAnnouncementModalOpen(false)}
                    className='btn btn-secondary bumper-l bumper-out-l'
                  >
                    Cancel
                  </button>
                </div>
              </Modal>
        }
        {this.state.passDataModalOpen 
          && <Modal
              title='Message to Room'
              setModalOpen={this.setPassDataModalOpen}
              >
                <div>
                  {this.state.passDataMessage}
                </div>
              </Modal>
        }
      </div>
    );
  };
};

export default SpellingBeeContainer