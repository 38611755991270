import React, {useState, useEffect} from 'react'
import '../Exam/ExamStyle.css';

interface IExamTestProps{

}

const ExamTest = (props:IExamTestProps)=>{
    return (<>
        <div className="bumper">
            <h3>zFairs Exam</h3>
        </div>
    </>)
};

export default ExamTest;