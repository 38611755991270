import React from "react";
import io from "socket.io-client";
import { DocTogetherVideo, NoGateVideo } from "../../utils/DocTogetherVideo";
import { store } from "../../redux/oldStore";
import { toast } from "react-toastify";
// import DangerousInnerHtml from "./DangerousInnerHtml";
import audioPlayer from "../../utils/AudioPlayer";
// import ParticipantTimer from "./ParticipantTimer";
// import Modal from "../Modal/Modal";
// import TimerContainer from "./TimerContainer";

type person = {
  personId: string;
  judge: boolean;
};

type roomList = { people: person[], roomId: string, projectId?: string }

type videoContainerState = {

};

type videoContainerProps = any;


class SBVideo extends React.Component<videoContainerProps, videoContainerState> {
  docVideo?: DocTogetherVideo;
  constructor(props) {
    super(props);
    this.state = {
      joined: false
    };
  }

  componentDidMount() {
    // window.document.title = `Spelling Bee`;
    this.init()
      .then(() => this.getMyRoomCall());
  }

  getMyRoomCall = async () => {
    store.server
      .postApi<any>("../Hinge/GetVideoRoomInfo", { id: this.props.meetingId })
      .then((res) => {
        console.log(res.Value);
        this.docVideo?.connect(res.Value);
        this.setState({ joined: true });
      })
      .catch((err) => toast.error("something went wrong getting my room"));
  };

  init = async () => {
    this.docVideo = new NoGateVideo("#docVideo", {
      dev: false,
    });
    await this.docVideo.init();

  };

  render() {
    return (
      <div style={{ height: '100%' }}>
        <div id="docVideo" style={{ height: '100%' }} ></div>
      </div>

    );
  }
}

export default SBVideo;
