import { applyMiddleware, createStore, combineReducers, Store, AnyAction } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connect } from 'react-redux';
import { IBranchTask, IHudInfo, ISettings, ISettingsResponse, IUser } from './types';
import lscache from 'lscache';
import { ReactComponentElement } from 'react';
import { Server, ServerResponse } from '../utils/Server';
import StoreX, { store } from './oldStore';
import judgingSetupReducer from './judgingSetup/reducer';
import judgingResultsReducer from './judgingResults/reducer';
import { update } from 'lodash';

export interface IState {
  User?: IUser;
  ShowLogin?: boolean;
  ShowEdit?: boolean;
  IsLoading?: boolean;
  UploadProgress?: number;
  UploadProgressTotal?: number;
  LoadingText?: string;
  SettingsResponse?: ISettingsResponse;
  Settings?: ISettings;
  StyleText?: string;
  ReloadBasePage?: boolean;
  branchInfo?: IBranchTask;
  HudInfo?: IHudInfo;
  IsReady?: boolean;
  WidgetBarLeft?: string[];
  WidgetBarRight?: string[];
}

export default class redux {
  static state: Store;
  public static EventSessionHandel: number | null = null;
  static State() {
    if (!redux.state) {
      redux.state =
        window.location.origin.indexOf('localhost') > -1 ? createStore(reducer, composeWithDevTools(applyMiddleware(promiseMiddleware))) : createStore(reducer, applyMiddleware(promiseMiddleware));
    }
    return redux.state;
  }

  //======================================================
  //Place dispatch methods below this line; redux.yourMethod
  //======================================================

  static setUser(value: IUser | null) {
    return Update(Actions.SetUser, value);
  }

  static loading = (on: boolean) => {
    return Update(Actions.SetLoading, on);
  };
  static uploadProgress = (progress: any) => {
    return Update(Actions.UploadProgress, progress);
  };

  static showLogin(value: boolean) {
    return Update(Actions.ShowLogin, value);
  }

  /**
   * 
   * @param left provide null value to not change widgets
   * @param right provide null value to not change widgets
   */
  static widgetBars (left: string[]|null, right:string[]|null) {
    if(left !== null) this.widgetLeft(left);
    if(right !== null) this.widgetRight(right);
  }

  static widgetLeft (values:string[]){
    return Update(Actions.WidgetLeft, values);
  }

  static widgetRight (values:string[]){
    return Update(Actions.WidgetRight, values);
  }
  static toggelEdit(value: boolean) {
    return Update(Actions.ShowEdit, value);
  }

  static NotReady() {
    return Update(Actions.NotReady, {});
  }

  static LoadSettings = (forecRefresh: boolean) => {
    const settingCacheKey = 'settings';
    lscache.flushExpired();
    let data = lscache.get(settingCacheKey);
    if (data) data.branchInfo = undefined;

    if (!data || forecRefresh) {
      if (data) Update(Actions.LoadSettings, data);
      store.server
        .postApiQuite<ServerResponse<ISettingsResponse>>('../app2/Settings', {})
        .then((x) => {
          if (x.Success) {
            lscache.set(settingCacheKey, x.Value, 60 * 8 * 50);
            return Update(Actions.LoadSettings, x.Value);
          } else {
          }
        })
        .catch((x) => {
          console.log('Error getting settings', x);
        });
    } else {
      return Update(Actions.LoadSettings, data);
    }
  };

  static LoadStyle = (forecRefresh: boolean) => {
    const styleCacheKey = 'style';
    lscache.flushExpired();
    let data = lscache.get(styleCacheKey);

    if (!data || forecRefresh) {
      if (data) Update(Actions.LoadStyle, data);
      store.server
        .postApiQuite<ServerResponse<string>>('../app2/styleText', {})
        .then((x) => {
          if (x.Success) {
            lscache.set(styleCacheKey, x.Value, 60 * 24 * 50);
            return Update(Actions.LoadStyle, x.Value);
          } else {
          }
        })
        .catch((x) => {
          console.log('Error getting settings', x);
        });
    } else {
      return Update(Actions.LoadStyle, data);
    }
  };

  static Reload = () => {
    this.LoadStyle(true);
    this.LoadSettings(true);
    return Update(Actions.Reload, null);
  };

  static ReloadedBasePage = () => {
    return Update(Actions.SetReloadedBasePage, null);
  };
}

const Update = (action: Actions, value: any) => {
  return redux.state.dispatch({ type: action, value: value });
};

enum Actions {
  Unknown,
  SetLoading,
  SetUser,
  ShowLogin,
  WidgetLeft,
  WidgetRight,
  ShowEdit,
  LoadSettings,
  LoadStyle,
  Reload,
  SetReloadedBasePage,
  NotReady,
  UploadProgress,
}

export const AppReducer = (currentState: IState, action: AnyAction): IState => {
  let state = currentState ?? { ShowEdit: false };
  switch (action.type) {
    case Actions.LoadSettings:
      return {
        ...state,
        IsReady: true,
        SettingsResponse: action.value ?? undefined,
        Settings: action.value?.Settings ?? undefined,
        User: action.value?.user,
        branchInfo: action.value?.Branch ?? undefined,
        HudInfo: action.value?.Hud ?? undefined,
      };
      break;
    case Actions.NotReady:
      return { ...state, IsReady: false };
      break;
    case Actions.LoadStyle:
      return { ...state, StyleText: action.value ?? undefined };
      break;
    case Actions.SetUser:
      return { ...state, User: action.value ?? undefined };
      break;
      break;
    case Actions.ShowLogin:
      return { ...state, ShowLogin: action.value ?? false };
      break;
    case Actions.WidgetLeft:
      return { ...state, WidgetBarLeft: action.value ?? [] };
      break;
    case Actions.WidgetRight:
      return { ...state, WidgetBarRight: action.value ?? [] };
      break;
    case Actions.ShowEdit:
      return { ...state, ShowEdit: action.value ?? false };
      break;
    case Actions.Reload: //this should include the other pages we want reloaded.
      return { ...state, ReloadBasePage: true };
      break;
    case Actions.SetReloadedBasePage:
      return { ...state, ReloadBasePage: false };
      break;
    case Actions.SetLoading:
      return { ...state, IsLoading: action.value ?? false, UploadProgressTotal: undefined };
    case Actions.UploadProgress:
      return { ...state, UploadProgress: action.value.loaded, UploadProgressTotal: action.value.total };
      break;
  }
  return state;
};

export const ReduxMap = (compnent) => {
  return connectMap()(compnent);
};

const connectMap = () => {
  const map = (x: any) => {
    return x.main;
  };
  return connect(map, {});
};

const reducer = combineReducers({
  main: AppReducer,
  judgingSetup: judgingSetupReducer,
  judgingResults: judgingResultsReducer,
});
